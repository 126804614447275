import React, { useEffect, useState } from 'react'
import { useHistory, BrowserRouter as Router, Route } from 'react-router-dom'

import { RiNotification2Fill, RiMenu2Fill, RiBuilding4Fill, RiFunctionLine, RiShieldUserFill, RiShieldUserLine } from 'react-icons/ri'

import { routes } from '../../routes'
import { auth, localStorage, sessionStorage, formatter, validator } from '../../util'
import { authService, loginService, redirectService } from '../../services'
import { Button, Icon, Modal, notification, Spin } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { WMMenu, WMModal } from '../../components/witty-ui'
import { build, redirectHostname } from '../../config'
import ProfileModal from '../../components/witty-ui/Modal/Profile'
import OrgModal from '../../components/witty-ui/Modal/Organisation'

import './styles.css'

function App () {
  const [showMenu, setShowMenu] = useState(false)
  const [showSubMenu, setShowSubmenu] = useState(false)
  const [showProfileModal, setShowProfileModal] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [showModeModal, setShowModeModal] = useState(false)
  const [showSwitchOrgModal, setShowSwitchOrgModal] = useState(false)
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const [showMode, setShowMode] = useState(null)
  const [selectedOrgId, setSelectedOrgId] = useState(null)
  const [platform, setPlatform] = useState([])
  const [currentPlatform, setCurrentPlatform] = useState({})

  const history = useHistory()

  useEffect(() => {
    auth.isOld()
    setMode()
    initMenuVisibility()
    initUserInfo()
  }, [])

  function initUserInfo () {
    let info = auth.getCurrentUser()
    if (info) {
      setUserInfo(info)
    }
  }

  function initMenuVisibility () {
    let showMenu = localStorage.getBoolean('showMenu')
    let showMode = auth.getMode()

    if (showMenu === null || showMenu === undefined) {
      localStorage.setItem('showMenu', true)
      showMenu = true
    }

    setShowMenu(showMenu || false)
  }

  function toggleMenu () {
    const newShowMenu = !showMenu

    if (!newShowMenu) {
      if (showSubMenu) {
        setShowSubmenu(false)
      }
    }

    setShowMenu(newShowMenu)

    localStorage.setItem('showMenu', newShowMenu)
  }

  // orgId must be in string
  function setMode () {
    let showMode = auth.getMode()
    let platform = auth.getPlatform()

    setShowMode(parseInt(showMode))
    if (validator.isNotEmptyArray(platform)) {
      setPlatform(platform)

      const p = platform.find(e => parseInt(e.org_id) === parseInt(showMode))
      if (p) {
        setCurrentPlatform(p)
      }
    }
  }

  function turnOffModals () {
    if (showProfileModal) setShowProfileModal(false)
    if (showNotificationModal) setShowNotificationModal(false)
    if (showModeModal) setShowModeModal(false)
    if (showSwitchOrgModal) setShowSwitchOrgModal(false)
  }

  function turnOffMenuAndModals () {
    setShowSubmenu(false)
    turnOffModals()
  }

  function updateSubMenuVisible (flag) {
    setShowSubmenu(flag)
  }

  function triggerLogoutModal (isLogout) {
    setShowLogoutModal(isLogout)
  }

  function triggerOrgSwitchModal (isOrg, selectedOrgId = null) {
    setShowSwitchOrgModal(isOrg)
    if (isOrg === true) {
      setSelectedOrgId(selectedOrgId)
    } else {
      setSelectedOrgId(null)
    }
  }

  async function logout () {
    setIsUpdate(true)
    try {
      const redirectToken = auth.getCurrentRedirectToken()

      if (redirectToken) {
        const r1 = await redirectService.exit({ redirect_token: redirectToken })

        if (r1 && r1.id) {
          await localStorage.clear()
          await sessionStorage.clear()
          await localStorage.setItem('redirected', 'true')
          window.location.href = redirectHostname
        } else {
          showUnableLogoutMsg()
        }
      } else {
        const r2 = await loginService.logout()
        if (r2 && r2.id) {
          await localStorage.clear()
          await sessionStorage.clear()
          await localStorage.setItem('redirected', 'true')
          window.location.href = '/login'
        } else {
          showUnableLogoutMsg()
        }
      }

      setIsUpdate(false)
    } catch (e) {
      showUnableLogoutMsg()
      setIsUpdate(false)
    }
  }

  async function showUnableLogoutMsg () {
    notification.error({
      message: 'Unable to Logout Correctly.',
      description: 'You will be forced logout and back to login page.'
    })

    await localStorage.clear()
    await sessionStorage.clear()
    await localStorage.setItem('redirected', 'true')
    window.location.href = '/login'
  }

  async function switchMode () {
    if (selectedOrgId !== null && selectedOrgId !== undefined) {
      try {
        setIsUpdate(true)
        const body = { org_id: selectedOrgId }
        const r = await loginService.switchMode(body)

        const { permissions = [], token, user, platform = [], invalid = false, active_id } = r

        if (r && !invalid) {
          notification.success({
            message: 'Switch mode successfully',
            description: 'Mode is switched successfully.'
          })

          if (token) {
            auth.setCurrentToken(token)
          }

          if (active_id !== null && active_id !== undefined) {
            auth.setMode(active_id)
            setShowMode(parseInt(active_id))
          }

          if (permissions && validator.isArray(permissions)) {
            auth.setPermission(permissions)
          }

          if (platform && validator.isArray(platform)) {
            auth.setPlatform(platform)
          }

          if (user && user.id) {
            auth.setCurrentUser(user)
          }

          history.replace('/')
          window.location.replace(`/`)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          notification.error({
            message: 'Switch mode is not successfully',
            description: 'Mode is unable to switch successfully.'
          })
        }

        setIsUpdate(false)
      } catch (e) {
        notification.error({
          message: 'Switch mode is not successfully',
          description: 'Mode is unable to switch successfully.'
        })
        setIsUpdate(false)
      }
    }
  }

  return (
    <Router>
      <div className='App'>
        <Spin size='large' spinning={isUpdate}>
          <header className='App-header'>
            <div className='left'>
              <div className='App-toggle' onClick={() => toggleMenu()}>
                {showMenu
                  ? <RiFunctionLine style={{ fontSize: '18pt' }} />
                  : <RiMenu2Fill style={{ fontSize: '18pt' }} /> }
              </div>
              <img className='App-logo logo' alt='Pumpkin IT' src={process.env.PUBLIC_URL + '/img/pumpkinIT-trans.png'} />
              <span style={{ fontSize: '15pt', color: '#e84e1c', fontWeight: 'bold' }} >Pumpkin Portal<span style={{ fontSize: '11pt', color: '#e84e1c77', fontWeight: 'bold', paddingLeft: '6px' }}>{build === 'prod' ? '' : build || 'unknown'}</span></span>
            </div>
            <div className='right'>
              {/* <div className='menu' onClick={() => { turnOffModals(); setShowNotificationModal(!showNotificationModal) }}>
                <RiNotification2Fill style={{ fontSize: '11pt', color: '#4285f4' }} />
              </div>  */}
              <div className='menu' onClick={() => { turnOffModals(); setShowModeModal(!showModeModal) }}>
                <div className='avatar row-flex-start' style={{ border: '1px solid #e84e1c' }}>
                  { currentPlatform.org_id
                    ? <img alt={currentPlatform.org_name} src={currentPlatform.thumb_url} className='app-org-logo' />
                    : <RiBuilding4Fill color='#e84e1c' style={{ fontSize: '20px', marginRight: '5px' }} />}
                  <div className='name-app'>{currentPlatform.org_name}</div>
                </div>
              </div>
              <div className='menu' onClick={() => { turnOffModals(); setShowProfileModal(!showProfileModal) }}>
                <div className='avatar row-flex-start' style={{ backgroundColor: '#e84e1c' }}>
                  <RiShieldUserLine color='#f4f4f4' style={{ fontSize: '20px', marginRight: '5px' }} />
                  <div className='name-white'>{userInfo.name}</div>
                </div>
              </div>
            </div>
          </header>
          <div className='App-body'>
            <WMMenu key={`menusas${showMode}`} type={showMode} visible={showMenu} submenuVisible={showSubMenu} isSubMenuVisible={(flag) => updateSubMenuVisible(flag)} />

            <div className={`App-content ${!showMenu ? 'App-content-no-menu' : ''}`} onClick={() => turnOffMenuAndModals()}>
              {routes.map((route, idx) => (
                <Route
                  key={idx}
                  component={route.component}
                  path={route.path}
                  exact={route.exact}
                />
              ))}
            </div>

            <ProfileModal visible={showProfileModal} userInfo={userInfo} onLogout={() => triggerLogoutModal(true)} />
            <OrgModal visible={showModeModal} platform={platform} selectedPlatform={showMode} onSelect={(orgId) => triggerOrgSwitchModal(true, orgId)} />

            <Modal
              visible={showLogoutModal}
              // title={'Logout'}
              onOk={isUpdate ? null : logout}
              onCancel={isUpdate ? null : () => triggerLogoutModal(false)}
              footer={[
                <Button key='back' shape='round' onClick={isUpdate ? null : () => triggerLogoutModal(false)}>
                  Cancel
                </Button>,
                <Button key='submit' shape='round' type='primary' loading={isUpdate} onClick={isUpdate ? null : logout}>
                  Confirm
                </Button>
              ]}
            >
              <div className='row-flex-start'>
                <ExclamationCircleOutlined style={{color: 'red', fontSize: '20px'}} />
                <div style={{marginLeft: '10px'}}>Are you sure you want to logout?</div>
              </div>
            </Modal>

            <Modal
              visible={showSwitchOrgModal}
              // title={'Logout'}
              onOk={isUpdate ? null : switchMode}
              onCancel={isUpdate ? null : () => triggerOrgSwitchModal(false)}
              footer={[
                <Button key='back' shape='round' onClick={isUpdate ? null : () => triggerOrgSwitchModal(false)}>
                  Cancel
                </Button>,
                <Button key='submit' shape='round' type='primary' loading={isUpdate} onClick={isUpdate ? null : switchMode}>
                  Confirm
                </Button>
              ]}
            >
              <div className='row-flex-start'>
                <ExclamationCircleOutlined style={{color: 'red', fontSize: '20px'}} />
                <div style={{marginLeft: '10px'}}>Confirm to switch mode?</div>
              </div>
            </Modal>

            {/* <NotificationModal visible={showNotificationModal} />
            <ProfileModal visible={showProfileModal} /> */}
          </div>
        </Spin>
      </div>
    </Router>
  )
}

export default App
