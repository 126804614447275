// Please set this for deployment !!!!!
// Options [local, dev, staging, uat, prod]
const build = process.env.REACT_APP_BUILD
// Options [local, dev, staging, uat, prod]

const apiHostname = process.env.REACT_APP_API_ENDPOINT
const googleMapApi = process.env.REACT_APP_API_GOOGLE
const apiVersion = process.env.REACT_APP_API_SAAS_VER
const redirectHostname = process.env.REACT_APP_HUB_ENDPOINT

export {
  build,
  apiHostname,
  googleMapApi,
  apiVersion,
  redirectHostname
}
