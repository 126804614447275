import jwt from 'jsonwebtoken'
import localStorage from './localStorage'
import sessionStorage from './sessionStorage'
import validator from './validator'

export default {
  getCurrentToken () {
    return localStorage.getItem('token')
  },
  setCurrentToken (token) {
    return localStorage.setItem('token', token)
  },
  getCurrentRedirectToken () {
    return localStorage.getItem('redirect-token')
  },
  setCurrentRedirectToken (token) {
    return localStorage.setItem('redirect-token', token)
  },
  getCurrentUser () {
    try {
      const tokenUser = localStorage.getItem('user-token')
      const user = jwt.verify(tokenUser, this.getCurrentToken())

      return user
    } catch (e) {
      console.log('portal cu err', e)
      return {}
    }
  },
  setCurrentUser (user) {
    const token = this.getCurrentToken()
    const tokenUser = jwt.sign(user, token)
    localStorage.setItem('user-token', tokenUser)
  },
  getMode () {
    try {
      const tokenMode = localStorage.getItem('showMode')
      const mode = jwt.verify(tokenMode, this.getCurrentToken())

      return mode
    } catch (e) {
      console.log('portal cmd err', e)
      return {}
    }
  },
  setMode (mode) {
    const token = this.getCurrentToken()
    const tokenMode = jwt.sign(mode, token)
    localStorage.setItem('showMode', tokenMode)
  },
  setPermission (permission) {
    try {
      const token = this.getCurrentToken()
      const pms = JSON.stringify(permission)
      const tokenPms = jwt.sign(pms, token)
      localStorage.setItem('permissions', tokenPms)
    } catch (e) {
      console.log('portal pms error', e)
    }
  },
  getPlatform () {
    try {
      const pfm = localStorage.getItem('platform', '')
      if (pfm) {
        const platform = jwt.verify(pfm, this.getCurrentToken())
        return platform
      }

      return []
    } catch (e) {
      console.log('pfm err', e)
      return []
    }
  },
  setPlatform (platform) {
    try {
      const token = this.getCurrentToken()
      const pfm = JSON.stringify(platform)
      const tokenPms = jwt.sign(pfm, token)
      localStorage.setItem('platform', tokenPms)
    } catch (e) {
      console.log('portal pfm error', e)
    }
  },
  hasAccess (permission) {
    // return true
    try {
      const pms = localStorage.getItem('permissions', '')
      if (permission && pms) {
        const permissions = jwt.verify(pms, this.getCurrentToken())

        return validator.isNotEmptyArray(permissions) ? permissions.indexOf(permission) > -1 : false
      }

      return false
    } catch (e) {
      // console.log('pms err', e)
      return false
    }
  },
  isSignedIn () {
    const token = this.getCurrentToken()
    return token && token !== 'undefined' && token.length > 0
  },
  async isOld () {
    // check whether old data is exist. if yes, clean and redirect to login page
    try {
      const old = localStorage.getItem('user')
      if (old) {
        await localStorage.clear()
        await sessionStorage.clear()
        await localStorage.setItem('redirected', 'true')
        window.location.href = '/login'
      }
    } catch (e) {
      console.log('portal old err', e)
      return {}
    }
  }
}
