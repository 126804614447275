import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input, notification, Pagination, Row, Col, Space, Skeleton, Switch, Spin, Table } from 'antd'
import { CheckCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Page, Panel, Total } from '../../../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../../../constants'
import { platformPMRateService } from '../../../../../../../services'
import { auth, validator } from '../../../../../../../util'

import 'antd/dist/antd.css'
import './styles.css'

const FormItem = Form.Item
const FormList = Form.List
const TextArea = Input.TextArea

const pageSize = 20

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.SoftwareRatesSoftwareList,
  Breadcrumb.SoftwareRatesListPM,
  Breadcrumb.SoftwareRatesEditRatesPM
]

const formItemLayout = {
  labelCol: { sm: 4, md: 4, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function PMRatesPage ({match}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [item, setItem] = useState({})

  const [form] = Form.useForm()
  const history = useHistory()
  const { params } = match
  const currentId = params ? params.rId : ''
  const platformId = params ? params.id : ''
  const isEdit = params && currentId !== 'add' && params.currentId !== 'new'

  useEffect(() => {
    if (isEdit && hasAccess(SysPermission.SOFTWARE.RATE_SET.READ)) {
      fetchItem()
    }
  }, [])

  async function fetchItem () {
    setIsLoading(true)
    const r = await platformPMRateService.getRate(currentId)
    if (r && r.id) {
      setItem(r)
      setIsLoading(false)
    }
  }

  async function onSubmit () {
    form.validateFields()
      .then(async (values) => {
        setIsUpdate(true)
        let r = null

        values.active = values.active || false

        if (isEdit) {
          let body = {
            name: values.name,
            description: values.description,
            active: values.active
          }
          r = await platformPMRateService.saveRate(currentId, body)
        } else {
          values.platform_id = platformId
          r = await platformPMRateService.addRate(values)
        }

        if (r && r.id) {
          if (isEdit) {
            notification.success({
              message: 'Update Rate successfully',
              description: 'Rate is updated successfully.'
            })
          } else {
            notification.success({
              message: 'Create Rate successfully',
              description: 'Rate is created successfully.'
            })

            setTimeout(() => {
              const link = `/softwares/rates/${platformId}/rates/${r.id}`
              history.replace(link)
              window.location.replace(link)
            }, 1000)
          }
        } else {
          notification.error({
            message: 'Save Rate not successful',
            description: 'Rate is unable to save successfully.'
          })
        }

        setIsUpdate(false)
      })
      .catch(errorInfo => {
        notification.error({
          message: 'Save Rate not successful',
          description: 'Rate is unable to save successfully.'
        })

        setIsUpdate(false)
      })
  }

  async function onSubmitFailed ({ values, errorFields, outOfDate }) {
    notification.error({
      message: 'Save Rate not successful',
      description: 'Rate is unable to save successfully.'
    })

    setIsUpdate(false)
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title={item.id ? `Edit Rate Group` : currentId && isEdit ? 'Edit Rate Group' : `Add Rate Group`}>
        <Space>
          { ((isEdit && hasAccess(SysPermission.SOFTWARE.RATE_SET.UPDATE)) || (!isEdit && hasAccess(SysPermission.SOFTWARE.RATE_SET.CREATE)))
            ? <Button shape='round' type='primary' loading={isUpdate} onClick={onSubmit}>{ isUpdate ? 'Saving' : 'Save'}</Button>
            : null }
          <div onClick={history.goBack}>
            <Button shape='round' ghost type='primary'>Back</Button>
          </div>
        </Space>
      </Page.Header>

      <Page.ContentLoading isLoading={isLoading} isUpdate={isUpdate}>
        <Form
          form={form}
          name='detail'
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Panel title='Details'>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Rate Name'
              name='name'
              initialValue={item.name}
              rules={[
                { min: 2, message: 'Name must be between 2 and 128 characters' },
                { max: 128, message: 'Name must be between 2 and 128 characters' },
                { required: true, message: 'Please enter rate name' },
                { whitespace: true, message: 'Please enter rate name' }
              ]}
            >
              <Input placeholder={'Enter Module Name'} />
            </FormItem>
            <FormItem
              {...formItemLayout}
              label='Active'
              name='active'
              initialValue={item.active}
              valuePropName='checked'
            >
              <Switch
                defaultChecked={item.active}
                checkedChildren={'Enable'}
                unCheckedChildren={'Disable'}
              />
            </FormItem>
          </Panel>
        </Form>
      </Page.ContentLoading>
    </Page.Body>
  )
}

export default PMRatesPage
