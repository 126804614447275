import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input, notification, Pagination, Row, Col, Radio, Space, Switch, Skeleton, Spin, Tabs } from 'antd'
import { CheckCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Page, Panel, Total } from '../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../constants'
import { memberService } from '../../../../../services'
import { auth, formatter, validator } from '../../../../../util'
import { SysStore } from '../../../../../stores'

import MemberLogin from './MemberLogin'

import 'antd/dist/antd.css'
import './styles.css'

const FormItem = Form.Item
const FormList = Form.List
const TextArea = Input.TextArea
const { TabPane } = Tabs

const pageSize = 20

const formItemLayout = {
  labelCol: { sm: 4, md: 4, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 4, md: 4, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const MODE_MEMBER = 'MEMBER'
const MODE_ORG = 'ORGANISATION'
const MODE_ADMIN = 'ADMIN'
const MODE_ADMIN_ORG = 'ADMIN_ORG'

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 2, path: '/login-setting' },
  // { tabId: 2, path: '/admin' },
  // { tabId: 3, path: '/admin-org' },
  // { tabId: 4, path: '/org' },
  { tabId: 5, path: '/logs' }
]

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.MemberBase,
  Breadcrumb.MemberList,
  Breadcrumb.MemberEdit
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function MemberEdit ({ match }) {
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isShowPwInput, setShowPwInput] = useState(false)
  const [currentTab, setCurrentTab] = useState('1')
  const [mode, setMode] = useState('')
  const [item, setItem] = useState({})

  const [form] = Form.useForm()
  const history = useHistory()
  const { params, path } = match
  const isEdit = params && params.id !== 'add' && params.id !== 'new'
  const currentId = params ? params.id : null

  useEffect(() => {
    if (isEdit) {
      fetchItem()
    }

    let mode = ''

    if (path) {
      if (path.includes('/members/listing/:id')) {
        mode = MODE_MEMBER
      } else if (path.includes('/systems/admin-users/:id')) {
        mode = MODE_ADMIN
      } else if (path.includes('/organisations/listing/:id')) {
        mode = MODE_ORG
      } else {
        mode = MODE_MEMBER
      }
    }

    setMode(mode)

    if (params.type) {
      const tab = TabList.find(e => e.path === params.type || e.path === `/${params.type}`)
      const cTab = tab && tab.tabId ? String(tab.tabId) : '1'
      setCurrentTab(cTab)
    } else {
      history.replace(`/members/listing/${currentId}${TabList[0].path}`)
    }
  }, [])

  async function fetchItem () {
    if (!hasAccess(SysPermission.MEMBER.INFO.READ)) return

    setIsLoading(true)
    const r = await memberService.get(currentId)
    if (r && r.id) {
      setItem(r)
      setIsLoading(false)
    }
  }

  async function onSubmit () {
    form.validateFields()
      .then(async (values) => {
        setIsUpdate(true)

        delete values.pw_select
        delete values.confirm_password

        let r = null

        if (isEdit) {
          r = await memberService.save(currentId, values)
        } else {
          r = await memberService.add(values)
        }

        if (r && r.id) {
          if (isEdit) {
            notification.success({
              message: 'Update Member successfully',
              description: 'Member is updated successfully.'
            })
          } else {
            notification.success({
              message: 'Create Member successfully',
              description: 'Member is created successfully.'
            })

            setTimeout(() => {
              const link = `/members/listing/${r.id}`
              history.replace(link)
              window.location.replace(link)
            }, 1000)
          }
        } else {
          notification.error({
            message: 'Save Member not successful',
            description: 'Member is unable to save successfully.'
          })
        }

        setIsUpdate(false)
      })
      .catch(errorInfo => {
        notification.error({
          message: 'Save Member not successful',
          description: 'Member is unable to save successfully.'
        })

        setIsUpdate(false)
      })
  }

  async function onSubmitFailed ({ values, errorFields, outOfDate }) {
    notification.error({
      message: 'Save Member not successful',
      description: 'Member is unable to save successfully.'
    })

    setIsUpdate(false)
  }

  function handleTabChange (index) {
    const tab = TabList.find(e => e.tabId === parseInt(index))
    setCurrentTab(index)
    if (tab && tab.tabId) {
      history.replace(`/members/listing/${currentId}${tab.path}`)
    }
  }

  function onChangePasswordSelect (e) {
    const value = e.target.value
    if (value === 'input') {
      setShowPwInput(true)
    } else {
      form.setFieldsValue({'new_password': undefined, 'confirm_password': undefined})
      setShowPwInput(false)
    }
  }

  async function validatePw (rule, value, callback) {
    if (value) {
      if (validator.checkPassword(value)) {
        throw new Error('Please enter at least 1 number, 1 lowercase letter and 1 uppercase letter')
      } else if (value.length < 8) {
        throw new Error('Please enter at least 8 characters')
      }
    }
  }

  async function validateConfirmPw (rule, value, callback) {
    if (value) {
      const newPw = form.getFieldValue('new_password')
      if (validator.checkPassword(value)) {
        throw new Error('Please enter at least 1 number, 1 lowercase letter and 1 uppercase letter')
      } else if (value.length < 8) {
        throw new Error('Please enter at least 8 characters')
      } else if (value !== newPw) {
        throw new Error('Your confirm password does not match new password')
      }
    }
  }

  // validator function: async function: throw new error, sync function: callback(new Error())
  async function validateEntry (rule, value, callback) {
    if (value) {
      if (rule.field === 'code' || rule.field === 'email') {
        const r = await memberService.checkDuplicateMember({ [rule.field]: value, id: currentId !== 'add' ? currentId : null })
        if (r && r.errors) {
          throw new Error(formatter.toErrorMessage(r.errors))
        }
      }
    }
  }

  function getInfoTab () {
    return (
      <div>
        <Form
          form={form}
          name='detail'
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Panel title='Details'>
            <FormItem
              {...formItemLayout}
              label='Active'
              name='active'
              initialValue={item.active}
              valuePropName='checked'
              extra={'If Active is disabled, the member will be unable to login any software even it is Active in Login Settings.'}
            >
              <Switch
                defaultChecked={item.active}
                checkedChildren={'Enable'}
                unCheckedChildren={'Disable'}
              />
            </FormItem>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Name'
              name='name'
              initialValue={item.name}
              rules={[
                { min: 2, message: 'Name must be between 2 and 128 characters' },
                { max: 128, message: 'Name must be between 2 and 128 characters' },
                { required: true, message: 'Please enter member name' },
                { whitespace: true, message: 'Please enter member name' }
              ]}
            >
              <Input placeholder='Enter Member Name' />
            </FormItem>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Code'
              name='code'
              initialValue={item.code}
              rules={[
                { required: true, message: 'Please enter member code' },
                { validator: validateEntry }
              ]}
            >
              <Input placeholder='Enter Member Name' />
            </FormItem>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Email'
              name='email'
              initialValue={item.email}
              rules={[
                { required: true, message: 'Please enter member email' },
                { validator: validateEntry }
              ]}
            >
              <Input placeholder='Enter Member Email' />
            </FormItem>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Remark'
              name='remark'
              initialValue={item.remark}
            >
              <Input placeholder='Enter Member Remark' />
            </FormItem>

            {/** Password handling */}
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Password Select'
              name='pw_select'
              initialValue={'default'}
            >
              <Radio.Group onChange={(e) => onChangePasswordSelect(e)}>
                <Radio value='default'>{`${isEdit ? 'No Change' : 'Generated by System'}`}</Radio>
                <Radio value='input'>{`${isEdit ? 'Manually Update' : 'Manually Enter'}`}</Radio>
              </Radio.Group>
            </FormItem>
            { isShowPwInput
              ? <div>
                <FormItem
                  {...formItemLayout}
                  hasFeedback
                  label='New Password'
                  name='new_password'
                  initialValue={null}
                  rules={[
                    { required: true, message: 'Please enter new password' },
                    { validator: validatePw }
                  ]}
                >
                  <Input placeholder='Enter User New Password' />
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  hasFeedback
                  label='Confirm Password'
                  name='confirm_password'
                  initialValue={null}
                  rules={[
                    { required: true, message: 'Please enter confirm password' },
                    { validator: validateConfirmPw }
                  ]}
                >
                  <Input placeholder='Enter Confirm Password' />
                </FormItem>
              </div>
              : null }
          </Panel>
        </Form>
      </div>
    )
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title={item.id ? `Member - ${item.name}` : currentId && isEdit ? 'Member' : 'Add Member'}>
        <Space>
          {currentTab === String(TabList[0].tabId) &&
          ((isEdit && hasAccess(SysPermission.MEMBER.INFO.UPDATE)) || (!isEdit && hasAccess(SysPermission.MEMBER.INFO.CREATE)))
            ? <Button shape='round' type='primary' loading={isUpdate} onClick={onSubmit}>{isUpdate ? 'Saving' : 'Save'}</Button>
            : null }
          <div onClick={history.goBack}>
            <Button shape='round' ghost type='primary'>Back</Button>
          </div>
        </Space>
      </Page.Header>

      <Page.ContentLoading isLoading={isLoading} isUpdate={isUpdate}>
        <Tabs
          defaultActiveKey={currentTab}
          onChange={handleTabChange}
        >
          <TabPane tab='Participant Info' key='1'>
            {getInfoTab()}
          </TabPane>
          { isEdit && hasAccess(SysPermission.MEMBER.LOGIN.LIST)
            ? <TabPane tab='Login Settings' key='2'>
              <MemberLogin currentId={currentId} />
            </TabPane>
            : null }
          {/* {isEdit && (mode === MODE_ADMIN || mode === MODE_MEMBER)
            ? <TabPane tab='Portal - System Login' key='2'>
              <Panel><MemberLogin currentId={currentId} mode={MODE_ADMIN} /></Panel>
            </TabPane>
            : null}
          {isEdit && (mode === MODE_ADMIN_ORG || mode === MODE_MEMBER)
            ? <TabPane tab='Portal - Organisation Login' key='3'>
              <Panel><MemberLogin currentId={currentId} mode={MODE_ADMIN_ORG} /></Panel>
            </TabPane>
            : null}
          {isEdit && (mode === MODE_ORG || mode === MODE_MEMBER)
            ? <TabPane tab='Platform Login' key='4'>
              <Panel><MemberLogin currentId={currentId} mode={MODE_ORG} /></Panel>
            </TabPane>
            : null} */}
          {isEdit && hasAccess(SysPermission.MEMBER.INFO.READ)
            ? <TabPane tab='Activity Log' key='5' />
            : null}
        </Tabs>

      </Page.ContentLoading>
    </Page.Body>
  )
}

export default MemberEdit
