import { request } from '../util'

const headers = { 'Content-Type': 'application/json' }
const urlsys = '/private/api/portal/sys/dashboards'
const urlorg = '/private/api/portal/org/dashboards'

export default {
  get () {
    return request(`${urlsys}/detail`)
  },
  orgGet () {
    return request(`${urlorg}/detail`)
  },
}
