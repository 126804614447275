import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Table, Pagination, Row, Col, Space } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Loading, Page, Panel, Total } from '../../../../components'
import { BreadcrumbOrg as Breadcrumb, OrgPermission } from '../../../../constants'
import { orgService } from '../../../../services'
import { auth, localStorage, validator } from '../../../../util'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20

const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.PortalPlatform,
  Breadcrumb.PortalPlatformList
]

const columns = [
  {
    title: 'Software',
    dataIndex: 'platform_name',
    key: 'platform_name',
    width: 6
  },
  {
    title: 'Entity Name',
    dataIndex: 'entity_name',
    key: 'entity_name',
    width: 6
  },
  {
    title: 'Trade Name',
    dataIndex: 'trade_name',
    key: 'trade_name',
    width: 6
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    width: 1,
    render: (active) => active
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#ccc', fontSize: '14pt'}} />
  },
  {
    width: 1,
    render: (item) => <Space size='middle'>
      { hasAccess(OrgPermission.SOFTWARE.SETTINGS.UPDATE)
        ? <Link to={`/software/detail/${item.platform_id}/info`}>
          <FormOutlined />
        </Link>
        : null }
    </Space>
  },
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function PortalOrgPlatformList (props) {
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState({})
  const [isListLoading, setIsListLoading] = useState(false)
  const [sort, setSort] = useState({})

  useEffect(() => {
    fetchList({ currentPage: 1 })
  }, [])

  async function fetchList ({ currentPage = page }) {
    if (!hasAccess(OrgPermission.SOFTWARE.INFO.LIST)) return

    let showMode = auth.getMode()

    setIsLoading(true)
    const r = await orgService.orgListPlatformByPage(showMode, currentPage, pageSize, filter, sort)

    if (r && r.list) {
      setIsLoading(false)
      setList(r.list)
      setPage(currentPage)
      setTotal(r.total)
    }
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title='Software Subscribed'>
        {/* <Link to='/members/listing/add'>
          <Button shape='round' type='primary'>Add Member</Button>
        </Link> */}
      </Page.Header>

      <Page.Content>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={list}
          size='small'
          rowKey={(itm) => (`sgon${itm.id}`)}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total,
            onChange: (e) => fetchList({currentPage: e})
          }}
        />
        <Total total={total} title={'Subscribed'} suffix={null} />
      </Page.Content>
    </Page.Body>
  )
}

export default PortalOrgPlatformList
