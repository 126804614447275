import React, { useEffect, useState } from 'react'

import './styles.css'

function ControlLabel (props) {
  const { children } = props

  return (
    <div className='wd-control-label'>{children}</div>
  )
}

export default ControlLabel
