import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Table, Pagination, Row, Col, Space, Tooltip } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { RiBuilding4Line, RiFileList2Line, RiDashboardFill, RiAppsLine, RiPercentLine, RiUser2Line, RiLockLine, RiUserFollowLine, RiGroupLine, RiWindowLine, RiNewspaperLine, RiImageLine, RiContactsBookUploadLine, RiInboxLine } from 'react-icons/ri'
import { Loading, Page, Panel, Total } from '../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../constants'
import { orgService } from '../../../../../services'
import { auth, validator } from '../../../../../util'
import { OrgStore } from '../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.OrgBase,
  Breadcrumb.OrgList
]

const columns = [
  {
    title: 'Trading Name',
    dataIndex: 'name',
    key: 'name',
    width: 7
  },
  {
    title: 'Legal Name',
    dataIndex: 'legal_name',
    key: 'legal_name',
    width: 7
  },
  {
    title: 'ABN',
    dataIndex: 'abn',
    key: 'code',
    width: 4
  },
  {
    title: 'NDIS Number',
    dataIndex: 'ndis_number',
    key: 'ndis_number',
    width: 4
  },
  {
    title: 'Active',
    width: 1,
    render: (item) => item.active
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#ccc', fontSize: '14pt'}} />
  },
  {
    width: 2,
    render: (item) => <Space size='middle'>
      { hasAccess(SysPermission.ORGANISATIONS.INFO.READ)
        ? <Tooltip title='Edit'>
          <Link to={`/organisations/listing/${item.id}/info`} key={`orgkey${item.id}-edit`}>
            <FormOutlined />
          </Link>
        </Tooltip>
        : null }
      { hasAccess(SysPermission.ORGANISATIONS.PORTAL_USER.LIST)
        ? <Tooltip title='Portal (Org) Admin Users'>
          <Link to={`/organisations/listing/${item.id}/admin-users`} key={`orgkey${item.id}-adu`}>
            <RiGroupLine />
          </Link>
        </Tooltip>
        : null }
      { hasAccess(SysPermission.ORGANISATIONS.PORTAL_ROLES.LIST)
        ? <Tooltip title='Portal (Org) User Roles'>
          <Link to={`/organisations/listing/${item.id}/roles`} key={`orgkey${item.id}-rle`}>
            <RiUserFollowLine />
          </Link>
        </Tooltip>
        : null }
      { hasAccess(SysPermission.ORGANISATIONS.SFT_DETAILS.LIST)
        ? <Tooltip title='Software Signed on and Details'>
          <Link to={`/organisations/listing/${item.id}/platform-specific`} key={`orgkey${item.id}-pse`}>
            <RiInboxLine />
          </Link>
        </Tooltip>
        : null }
    </Space>
  },
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function OrgList () {
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState({})
  const [sort, setSort] = useState({})

  useEffect(() => {
    fetchList({ currentPage: 1 })
  }, [])

  const list = OrgStore.useState(e => e.orgList)
  const page = OrgStore.useState(e => e.orgPage)
  const total = OrgStore.useState(e => e.orgTotal)
  const isOrgListLoading = OrgStore.useState(e => e.isOrgListLoading)

  async function fetchList ({ currentPage = page }) {
    if (!hasAccess(SysPermission.ORGANISATIONS.INFO.LIST)) return

    setIsLoading(true)
    const r = await orgService.listByPage(currentPage, pageSize, filter, sort)

    if (r && r.list) {
      setIsLoading(false)
      OrgStore.update(e => {
        e.orgList = r.list
        e.orgTotal = r.total
        e.orgPage = currentPage
      })
    }
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title='Organisations'>
        <Link to='/organisations/listing/add'>
          <Button shape='round' type='primary'>Add Organisation</Button>
        </Link>
      </Page.Header>

      <Page.Content>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={list}
          rowKey={(itm) => (`orglist${itm.id}`)}
          size='small'
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total,
            onChange: (e) => fetchList({currentPage: e})
          }}
        />
        <Total total={total} title={'organisation'} />
      </Page.Content>
    </Page.Body>
  )
}

export default OrgList
