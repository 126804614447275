export default {
  ORGANISATION: {
    INFO: {
      LIST: 'listOrganisation',
      READ: 'readOrganisation',
      CREATE: 'createOrganisation',
      UPDATE: 'updateOrganisation',
      DELETE: 'deleteOrganisation'
    },
  },
  PORTAL: {
    ADMIN_USER: {
      LIST: 'listPortalAdminUsers',
      READ: 'readPortalAdminUsers',
      CREATE: 'createPortalAdminUsers',
      UPDATE: 'updatePortalAdminUsers',
      DELETE: 'deletePortalAdminUsers'
    },
    USER_ROLE: {
      LIST: 'listPortalUserRoles',
      READ: 'readPortalUserRoles',
      CREATE: 'createPortalUserRoles',
      UPDATE: 'updatePortalUserRoles',
      DELETE: 'deletePortalUserRoles'
    }
  },
  SOFTWARE: {
    INFO: {
      LIST: 'listSoftware',
      READ: 'readSoftware',
      CREATE: 'createSoftware',
      UPDATE: 'updateSoftware',
      DELETE: 'deleteSoftware'
    },
    SETTINGS: {
      LIST: 'listSoftwareSettings',
      READ: 'readSoftwareSettings',
      CREATE: 'createSoftwareSettings',
      UPDATE: 'updateSoftwareSettings',
      DELETE: 'deleteSoftwareSettings'
    },
    ADMIN_USER: {
      LIST: 'listSoftwareAdminUsers',
      READ: 'readSoftwareAdminUsers',
      CREATE: 'createSoftwareAdminUsers',
      UPDATE: 'updateSoftwareAdminUsers',
      DELETE: 'deleteSoftwareAdminUsers'
    },
    USER_ROLE: {
      LIST: 'listSoftwareUserRoles',
      READ: 'readSoftwareUserRoles',
      CREATE: 'createSoftwareUserRoles',
      UPDATE: 'updateSoftwareUserRoles',
      DELETE: 'deleteSoftwareUserRoles'
    }
  }
}
