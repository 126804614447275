import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input, notification, Row, Col, Space, Switch, Tabs, Spin, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { auth, formatter, validator, uploader } from '../../util'

import 'antd/dist/antd.css'
import './styles.css'

function Uploader (props) {
  useEffect(() => {

  }, [])

  const { className, apiUrl, children, loading = false, options = {} } = props
  const uploaderClassName = formatter.toClassName(['wd-upload', 'wd-upload-uploader', options.className])
  const upload = uploader.getProps(apiUrl, options)
  const file = options && validator.isNotEmptyArray(options.fileList) ? options.fileList[0] : options && options.file ? options.file : null
  const isFile = file && file.fileUrl

  return (
    <div className={formatter.toClassName(['wd-upload', className])}>
      <Upload
        className={uploaderClassName}
        {...upload}
        // method={'POST'}
        // action={apiUrl}
        // headers={{Authorization: `Bearer ${auth.getCurrentToken()}`}}
        // data={options.data}
        // name={'file'}
        // file={options.file}
        // onRemove={options.onRemove}
        // onChange={options.onChange}
        // beforeUpload={options.beforeUpload}
        // fileList={options.fileList}
        // multiple={false}
        // file={file ? file.fileUrl : undefined}
        // fileName={file ? file.fileName : undefined}
      >
        <Spin spinning={loading}>
          { children ||
            <div>
              <Button style={{zIndex: 10, backgroundColor: isFile ? '#e84e1ccc' : '#e84e1c', borderColor: '#e84e1cbb', color: '#fff'}} shape='round' loading={loading}>
                <UploadOutlined>Upload</UploadOutlined>
                { file && file.fileUrl ? 'Click to Change' : 'Click to Upload' }
              </Button>

              { isFile
                ? <img
                  className='wd-upload-image'
                  src={file.fileUrl}
                  alt={file.fileName || 'Image'}
                />
                : null}
            </div>}
        </Spin>
      </Upload>
    </div>
  )
}

export default Uploader
