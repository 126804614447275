export default {
  SC_FUNDER_TYPE: [
    {
      name: 'NDIS Only',
      value: 'ndis-only'
    },
    {
      name: 'Multi Funder',
      value: 'multi-funder'
    }
  ]
}
