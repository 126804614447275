import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { RiArrowRightSLine, RiArrowDownSLine } from 'react-icons/ri'

import { formatter } from '../../../util'
import './styles.css'

function Total ({ className = '', style = {}, total = '', title, titles, suffix = 's' }) {
  const totalVal = parseInt(total)
  const qtyText = totalVal === 1 ? title : titles ? titles : `${title}${suffix}`
  return (
    <div className={formatter.toClassName(['wd-total-container', className])} style={style}>
      {total ? <div className='wd-total-text'>{`Total ${total} ${qtyText}`}</div> : null}

      <div style={{ clear: 'both' }} />
    </div>
  )
}

export default Total
