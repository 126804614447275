import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
// import authService from '../../services/auth'
import formatter from '../../util/formatter'

import { Breadcrumb as BreadCrumb, Skeleton, Spin } from 'antd'

import './styles.css'

export class Body extends Component {
  render () {
    const { children, className } = this.props

    return [
      <div key='b1' className={formatter.toClassName(['wd-body', className])}>
        {children}
      </div>,
      <div key='b2' className='wd-footer'>Powered by <img src='/img/pumpkinIT-long.png' className='app-logo' /> Copyright © 2022 Pumpkin IT. All rights reserved.</div>
    ]
  }
}

Body.propTypes = {
  className: PropTypes.string
}

export class Content extends Component {
  render () {
    const { children, className } = this.props

    return (
      <div className={formatter.toClassName(['wd-content', 'wd-content-full', className])}>
        {children}
      </div>
    )
  }
}

Content.propTypes = {
  className: PropTypes.string
}

export class ContentLoading extends Component {
  render () {
    const { children, className, isAvatar = false, isLoading = false, isUpdate = false } = this.props

    return (
      <div className={formatter.toClassName(['wd-content', 'wd-content-full', className])}>
        <Spin size='large' spinning={isUpdate}>
          <Skeleton active avatar={isAvatar} loading={isLoading}>
            {children}
          </Skeleton>
        </Spin>
      </div>
    )
  }
}

ContentLoading.propTypes = {
  className: PropTypes.string,
  isAvatar: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUpdate: PropTypes.bool
}

export class Right extends Component {
  render () {
    const { children, className } = this.props

    return (
      <div className={formatter.toClassName(['wd-right', className])}>
        {children}
      </div>
    )
  }
}

export class Panel extends Component {
  render () {
    const { children, className, title } = this.props

    return (
      <div className={formatter.toClassName(['wd-panel-box', className])}>
        <div className='title'>{title || 'No title'}</div>

        <div className='content'>
          {children || 'No content available'}
        </div>
      </div>
    )
  }
}

export class Filter extends Component {
  render () {
    const { className } = this.props

    return (
      <div className={formatter.toClassName(['wd-filter', className])}>
        {this.props.children}
      </div>
    )
  }
}

Filter.propTypes = {
  className: PropTypes.string
}

export class Header extends Component {
  render () {
    const { children, className, title } = this.props

    return (
      <div className={formatter.toClassName(['wd-header', className])}>
        <div className='title'>
          <h2>{title}</h2>
        </div>
        <div className='header-action'>
          {children}
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  className: PropTypes.string
}
export class HeaderSub extends Component {
  render () {
    const { children, className, title } = this.props

    return (
      <div className={formatter.toClassName(['wd-header', className])}>
        <div className='title'>
          <h3>{title}</h3>
        </div>
        <div className='header-action'>
          {children}
        </div>
      </div>
    )
  }
}

HeaderSub.propTypes = {
  className: PropTypes.string
}

export class Breadcrumb extends Component {
  render () {
    const { item } = this.props

    return (
      <div className='wd-breadcrumb'>
        <BreadCrumb>
          {
            item && item.map((bc, idx) => <BreadCrumb.Item key={idx}>{bc.link && bc.link !== '' ? <Link to={bc.link}>{bc.label}</Link> : bc.label}</BreadCrumb.Item>)
          }
        </BreadCrumb>
      </div>
    )
  }
}

export class Tab extends Component {
  render () {
    const { className } = this.props

    return (
      <div className={formatter.toClassName(['wd-tab', className])}>
        {this.props.children}
      </div>
    )
  }
}

Tab.propTypes = {
  className: PropTypes.string
}

export class Table extends Component {
  render () {
    const { className } = this.props

    return (
      <div className={formatter.toClassName(['wd-section', className])}>
        {this.props.children}
      </div>
    )
  }
}

Table.propTypes = {
  className: PropTypes.string
}

export default { Body, Breadcrumb, Content, ContentLoading, Filter, Header, HeaderSub, Panel, Right, Tab, Table }
