import { Store } from 'pullstate'

export const SysStore = new Store({
  isSysListLoading: false,
  isShouldUpdateSysLog: false,
  isSysModuleListLoading: false,
  sysModuleList: [],
  sysModulePage: 1,
  sysModuleTotal: 0,
  isSysModuleOrgListLoading: false,
  sysModuleOrgList: [],
  sysModuleOrgPage: 1,
  sysModuleOrgTotal: 0,
  isSysUserRoleListLoading: false,
  sysUserRoleList: [],
  sysUserRolePage: 1,
  sysUserRoleTotal: 0,
  isSysClassificationListLoading: false,
  sysClassificationList: [],
  sysClassificationPage: 1,
  sysClassificationTotal: 0,
  isSysAdminUserLoading: false,
  sysAdminUserList: [],
  sysAdminUserPage: 1,
  sysAdminUserTotal: 0,
})
