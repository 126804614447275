import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input, notification, Pagination, Row, Col, Popconfirm, Space, Select, Skeleton, Spin, Switch, Tabs } from 'antd'
import { CheckCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Page, Panel, Total } from '../../../../../../components'
import { BreadcrumbSystem as Breadcrumb, PlatformKey, SysPermission } from '../../../../../../constants'
import { memberService, memberAccessService, orgService, orgAdminRoleService, platformAdminRoleService, sysAdminRoleService } from '../../../../../../services'
import { auth, formatter, validator } from '../../../../../../util'
import { SysStore } from '../../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'

const Option = Select.Option
const FormItem = Form.Item
const FormList = Form.List
const TextArea = Input.TextArea
const { TabPane } = Tabs

const keyPM = PlatformKey.keyPM
const keySC = PlatformKey.keySC

const pageSize = 20

const formItemLayout = {
  labelCol: { sm: 4, md: 4, lg: 4 },
  wrapperCol: { sm: 11, md: 11, lg: 11 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 4, md: 4, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const MODE_MEMBER = 'MEMBER'
const MODE_ADMIN = 'ADMIN'
const MODE_ADMIN_ORG = 'ADMIN_ORG'
const MODE_PLATFORM_PM = 'PLATFORM_PM'
const MODE_PLATFORM_SC = 'PLATFORM_SC'

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 2, path: '/admin' },
  { tabId: 3, path: '/admin-org' },
  { tabId: 4, path: '/org' },
  { tabId: 5, path: '/logs' }
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function MemberLogin (props) {
  const [form] = Form.useForm()
  const history = useHistory()
  const currentId = props.currentId

  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [item, setItem] = useState({})
  const [portalAdminRoleList, setAdminRoleList] = useState([])
  const [portalAdminOrgRoleList, setAdminOrgRoleList] = useState([])
  const [currentAdminOrgRoleList, setCurrentAdminOrgRoleList] = useState([])
  const [platformRoleList, setPlatformRoleList] = useState([])
  const [currentPMRoleList, setcurrentPMRoleList] = useState([])
  const [currentSCRoleList, setcurrentSCRoleList] = useState([])
  const [platformList, setPlatformList] = useState([])
  const [orgList, setOrgList] = useState([])

  useEffect(() => {
    fetchItem()
  }, [])

  async function fetchItem () {
    if (!hasAccess(SysPermission.MEMBER.LOGIN.READ)) return

    setIsLoading(true)
    const r = await memberService.getLogin(currentId)
    if (r && r.id) {
      setItem(r)

      const filter1 = { active: true }

      const portalAdminRole = await sysAdminRoleService.listByPage(1, 0, filter1)
      const portalOrgAdminRole = await orgAdminRoleService.listByPage(1, 0, filter1)
      const platformAdminRole = await platformAdminRoleService.listByPage(1, 0, filter1)

      const org = await orgService.listByPage(1, 0, filter1)

      if (portalAdminRole && validator.isNotEmptyArray(portalAdminRole.list)) {
        setAdminRoleList(portalAdminRole.list)
      }

      if (portalOrgAdminRole && validator.isNotEmptyArray(portalOrgAdminRole.list)) {
        setAdminOrgRoleList(portalOrgAdminRole.list)

        if (r.system_org_type_id) {
          const list = portalOrgAdminRole.list.filter(e => e.org_id === 0 || e.org_id === r.system_org_type_id)
          setCurrentAdminOrgRoleList(list)
        }
      }

      if (platformAdminRole && validator.isNotEmptyArray(platformAdminRole.list)) {
        setPlatformRoleList(platformAdminRole.list)

        if (r.org_pm_type_id) {
          const list = platformAdminRole.list.filter(e => (e.org_id === 0 || e.org_id === r.org_pm_type_id) && e.platform_id === keyPM)
          setcurrentPMRoleList(list)
        }

        if (r.org_sc_type_id) {
          const list = platformAdminRole.list.filter(e => (e.org_id === 0 || e.org_id === r.org_sc_type_id) && e.platform_id === keySC)
          setcurrentSCRoleList(list)
        }
      }

      if (org && validator.isNotEmptyArray(org.list)) {
        setOrgList(org.list)
      }

      setIsLoading(false)
    }
  }

  async function onSubmitFailed ({ values, errorFields, outOfDate }) {
    notification.error({
      message: 'Save Member not successful',
      description: 'Member is unable to save successfully.'
    })

    setIsUpdate(false)
  }

  function updatePortalOrgRoleList (orgId) {
    const list = portalAdminOrgRoleList.filter(e => e.org_id === 0 || e.org_id === orgId)
    setCurrentAdminOrgRoleList(list)
  }

  function updatePlatformRoleList (orgId, platformId) {
    const list = platformRoleList.filter(e => (e.org_id === 0 || e.org_id === orgId) && e.platform_id === platformId)
    if (platformId === keyPM) {
      setcurrentPMRoleList(list)
    } else if (platformId === keySC) {
      setcurrentSCRoleList(list)
    }
  }

  function addLogin (mode) {
    const newLogin = mode === MODE_ADMIN
      ? { system_access_id: 0, system_role_id: 1 }
      : mode === MODE_ADMIN_ORG
        ? { system_org_access_id: 0, system_org_role_id: null }
        : mode === MODE_PLATFORM_PM
          ? { org_pm_access_id: 0, org_pm_role_id: null }
          : mode === MODE_PLATFORM_SC
            ? { org_sc_access_id: 0, org_sc_role_id: null }
            : ''
    setItem({...item, ...newLogin})
  }

  async function removeLogin (item, mode) {
    let id = ''

    if (mode === MODE_ADMIN) {
      id = item.system_access_id
    } else if (mode === MODE_ADMIN_ORG) {
      id = item.system_org_access_id
    } else if (mode === MODE_PLATFORM_PM) {
      id = item.org_pm_access_id
    } else if (mode === MODE_PLATFORM_SC) {
      id = item.org_sc_access_id
    }

    if (id) {
      setIsUpdate(true)

      const r = await memberAccessService.remove(id)
      if (r && r.id) {
        notification.success({
          message: 'Remove Login successfully',
          description: 'Login is removed successfully.'
        })

        fetchItem()
      } else {
        notification.error({
          message: 'Remove Login not successful',
          description: 'Login is unable to remove successfully.'
        })
      }
      setIsUpdate(false)
    } else if (id === 0) {
      const newLogin = mode === MODE_ADMIN
        ? { system_access_id: null, system_role_id: null }
        : mode === MODE_ADMIN_ORG
          ? { system_org_access_id: null, system_org_role_id: null }
          : mode === MODE_PLATFORM_PM
            ? { org_pm_access_id: null, org_pm_role_id: null }
            : mode === MODE_PLATFORM_SC
              ? { org_sc_access_id: null, org_sc_role_id: null }
              : ''
      setItem({...item, ...newLogin})
    } else {
      notification.error({
        message: 'Remove Login not successful',
        description: 'No login available.'
      })
    }
  }

  async function onSubmit (mode) {
    form.validateFields()
      .then(async (values) => {
        setIsUpdate(true)
        let data = {}
        let r = null
        let isAdd = false
        if (mode === MODE_ADMIN) {
          if (item.system_access_id === 0) {
            data = {
              role_id: values.system_role_id,
              active: values.system_active || false,
              member_id: props.currentId,
              platform_id: 0,
              type: 'system',
              type_id: 0
            }
            isAdd = true

            r = await memberAccessService.add(data)
          } else {
            data = {
              role_id: values.system_role_id,
              active: values.system_active || false
            }
            r = await memberAccessService.save(item.system_access_id, data)
          }
        } else if (mode === MODE_ADMIN_ORG) {
          if (item.system_org_access_id === 0) {
            data = {
              role_id: values.system_org_role_id,
              active: values.system_org_active || false,
              member_id: props.currentId,
              platform_id: 0,
              type: 'system',
              type_id: values.system_org_type_id
            }
            isAdd = true

            r = await memberAccessService.add(data)
          } else {
            data = {
              role_id: values.system_org_role_id,
              active: values.system_org_active || false,
              type_id: values.system_org_type_id
            }

            r = await memberAccessService.save(item.system_org_access_id, data)
          }
        } else if (mode === MODE_PLATFORM_PM) {
          if (item.org_pm_access_id === 0) {
            data = {
              role_id: values.org_pm_role_id,
              active: values.org_pm_active || false,
              member_id: props.currentId,
              type: 'organisation',
              type_id: values.org_pm_type_id,
              platform_id: keyPM
            }
            isAdd = true

            r = await memberAccessService.add(data)
          } else {
            data = {
              role_id: values.org_pm_role_id,
              active: values.org_pm_active || false,
              type_id: values.org_pm_type_id
            }

            r = await memberAccessService.save(item.org_pm_access_id, data)
          }
        } else if (mode === MODE_PLATFORM_SC) {
          if (item.org_sc_access_id === 0) {
            data = {
              role_id: values.org_sc_role_id,
              active: values.org_sc_active || false,
              member_id: props.currentId,
              type: 'organisation',
              type_id: values.org_sc_type_id,
              platform_id: keySC
            }
            isAdd = true

            r = await memberAccessService.add(data)
          } else {
            data = {
              role_id: values.org_sc_role_id,
              active: values.org_sc_active || false,
              type_id: values.org_sc_type_id
            }

            r = await memberAccessService.save(item.org_sc_access_id, data)
          }
        }

        if (r && r.id) {
          if (isAdd) {
            fetchItem()
          }
          notification.success({
            message: 'Save Login successfully',
            description: 'Login is saved successfully.'
          })
        } else {
          notification.error({
            message: 'Save Login not successful',
            description: 'Login is unable to save successfully.'
          })
        }

        setIsUpdate(false)
      })
      .catch(errorInfo => {
        notification.error({
          message: 'Save Login not successful',
          description: 'Login is unable to save successfully.'
        })

        setIsUpdate(false)
      })
  }

  const isAddPortalAdminLogin = (item.system_access_id === null || item.system_access_id === undefined)
  const isAddPotalOrgLogin = (item.system_org_access_id === null || item.system_org_access_id === undefined)
  const isAddPMLogin = (item.org_pm_access_id === null || item.org_pm_access_id === undefined)
  const isAddSCLogin = (item.org_sc_access_id === null || item.org_sc_access_id === undefined)

  return (
    <div>
      <Page.ContentLoading isLoading={isLoading} isUpdate={isUpdate}>
        <Form
          form={form}
          name='login'
          // onFinish={() => onSubmit(MODE_ADMIN)}
          // onFinishFailed={onSubmitFailed}
        >
          <Panel>
            <Page.HeaderSub title={'Portal (Admin) Login'}>
              <Space>
                { isLoading
                  ? null
                  : isAddPortalAdminLogin && hasAccess(SysPermission.MEMBER.LOGIN.CREATE)
                    ? <Button shape='round' type='primary' onClick={() => addLogin(MODE_ADMIN)}>
                      {'Add Login'}
                    </Button>
                    : <Space>
                      { hasAccess(SysPermission.MEMBER.LOGIN.UPDATE)
                        ? <Button shape='round' type='primary' onClick={() => onSubmit(MODE_ADMIN)}>
                          {'Save'}
                        </Button>
                        : null }
                      { hasAccess(SysPermission.MEMBER.LOGIN.DELETE)
                        ? <Popconfirm
                          title='Confirm to remove login?'
                          onConfirm={() => removeLogin(item, MODE_ADMIN)}
                          okText='Yes'
                          cancelText='No'
                        >
                          <Button shape='round' ghost type='primary'>
                            {'Remove Login'}
                          </Button>
                        </Popconfirm>
                        : null }
                    </Space> }
              </Space>
            </Page.HeaderSub>
            { !isAddPortalAdminLogin
              ? <div>
                <FormItem
                  {...formItemLayout}
                  hasFeedback
                  label='Role'
                  name='system_role_id'
                  initialValue={item.system_role_id}
                  rules={[
                    { required: true, message: 'Please enter Portal Role' }
                  ]}
                >
                  <Select>
                    { portalAdminRoleList.map(e => (
                      <Option key={`pmarof${e.id}`} value={e.id}>
                        <div>
                          <div>{ e.name }</div>
                          <div className='sub-option'>{ e.description }</div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label='Active'
                  name='system_active'
                  initialValue={item.system_active}
                  valuePropName='checked'
                  extra={item.active ? undefined : 'Member is disabled now.'}
                >
                  <Switch
                    defaultChecked={item.system_active || false}
                    checkedChildren={'Enable'}
                    unCheckedChildren={'Disable'}
                  />
                </FormItem>
              </div>
              : <div className='no-login-text'>No Portal (Admin) Login is available, press on Add Login button to add.</div> }
          </Panel>
          <Panel>
            <Page.HeaderSub title={'Portal (Organisation) Login'}>
              <Space>
                { isLoading
                  ? null
                  : isAddPotalOrgLogin && hasAccess(SysPermission.MEMBER.LOGIN.CREATE)
                    ? <Button shape='round' type='primary' onClick={() => addLogin(MODE_ADMIN_ORG)}>
                      {'Add Login'}
                    </Button>
                    : <Space>
                      { hasAccess(SysPermission.MEMBER.LOGIN.UPDATE)
                        ? <Button shape='round' type='primary' onClick={() => onSubmit(MODE_ADMIN_ORG)}>
                          {'Save'}
                        </Button>
                        : null }
                      { hasAccess(SysPermission.MEMBER.LOGIN.DELETE)
                        ? <Popconfirm
                          title='Confirm to remove login?'
                          onConfirm={() => removeLogin(item, MODE_ADMIN_ORG)}
                          okText='Yes'
                          cancelText='No'
                        >
                          <Button shape='round' ghost type='primary'>
                            {'Remove Login'}
                          </Button>
                        </Popconfirm>
                        : null }
                    </Space> }
              </Space>
            </Page.HeaderSub>
            { !isAddPotalOrgLogin
              ? <div>
                <FormItem
                  {...formItemLayout}
                  hasFeedback
                  label='Organisation'
                  name='system_org_type_id'
                  initialValue={item.system_org_type_id}
                  rules={[
                    { required: true, message: 'Please enter Organisation' }
                  ]}
                >
                  <Select onChange={(e) => updatePortalOrgRoleList(e)}>
                    { orgList.map(e => (
                      <Option key={`orgls${e.id}`} value={e.id}>
                        <div>{ e.name }</div>
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  hasFeedback
                  label='Role'
                  name='system_org_role_id'
                  initialValue={item.system_org_role_id}
                  rules={[
                    { required: true, message: 'Please enter Portal Role' }
                  ]}
                >
                  <Select>
                    { currentAdminOrgRoleList.map(e => (
                      <Option key={`pmaorg${e.id}`} value={e.id}>
                        <div>
                          <div>{ e.name }</div>
                          <div className='sub-option'>{ e.description }</div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label='Active'
                  name='system_org_active'
                  initialValue={item.system_org_active}
                  valuePropName='checked'
                  extra={item.active ? undefined : 'Member is disabled now.'}
                >
                  <Switch
                    defaultChecked={item.system_org_active || false}
                    checkedChildren={'Enable'}
                    unCheckedChildren={'Disable'}
                  />
                </FormItem>
              </div>
              : <div className='no-login-text'>No Portal (Organisation) Login is available, press on Add Login button to add.</div> }
          </Panel>
          <Panel>
            <Page.HeaderSub title={'PM Login'}>
              <Space>
                { isLoading
                  ? null
                  : isAddPMLogin && hasAccess(SysPermission.MEMBER.LOGIN.CREATE)
                    ? <Button shape='round' type='primary' onClick={() => addLogin(MODE_PLATFORM_PM)}>
                      {'Add Login'}
                    </Button>
                    : <Space>
                      { hasAccess(SysPermission.MEMBER.LOGIN.UPDATE)
                        ? <Button shape='round' type='primary' onClick={() => onSubmit(MODE_PLATFORM_PM)}>
                          {'Save'}
                        </Button>
                        : null }
                      { hasAccess(SysPermission.MEMBER.LOGIN.DELETE)
                        ? <Popconfirm
                          title='Confirm to remove login?'
                          onConfirm={() => removeLogin(item, MODE_PLATFORM_PM)}
                          okText='Yes'
                          cancelText='No'
                        >
                          <Button shape='round' ghost type='primary'>
                            {'Remove Login'}
                          </Button>
                        </Popconfirm>
                        : null }
                    </Space> }
              </Space>
            </Page.HeaderSub>
            { !isAddPMLogin
              ? <div>
                <FormItem
                  {...formItemLayout}
                  hasFeedback
                  label='Organisation'
                  name='org_pm_type_id'
                  initialValue={item.org_pm_type_id}
                  rules={[
                    { required: true, message: 'Please enter Organisation' }
                  ]}
                >
                  <Select onChange={e => updatePlatformRoleList(e, keyPM)}>
                    { orgList.map(e => (
                      <Option key={`pmcorg${e.id}`} value={e.id}>
                        <div>{ e.name }</div>
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  hasFeedback
                  label='Role'
                  name='org_pm_role_id'
                  initialValue={item.org_pm_role_id}
                  rules={[
                    { required: true, message: 'Please enter Organisation Role' }
                  ]}
                >
                  <Select>
                    { currentPMRoleList.map(e => (
                      <Option key={`pmrslr${e.id}`} value={e.id}>
                        <div>
                          <div>{`${e.name} (${e.org_name})`}</div>
                          <div className='sub-option'>{ e.description }</div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label='Active'
                  name='org_pm_active'
                  initialValue={item.org_pm_active}
                  valuePropName='checked'
                  extra={item.active ? undefined : 'Member is disabled now.'}
                >
                  <Switch
                    defaultChecked={item.org_pm_active || false}
                    checkedChildren={'Enable'}
                    unCheckedChildren={'Disable'}
                  />
                </FormItem>
              </div>
              : <div className='no-login-text'>No PM Login is available, press on Add Login button to add.</div>}
          </Panel>
          <Panel>
            <Page.HeaderSub title={'SC Login'}>
              <Space>
                { isLoading
                  ? null
                  : isAddSCLogin && hasAccess(SysPermission.MEMBER.LOGIN.CREATE)
                    ? <Button shape='round' type='primary' onClick={() => addLogin(MODE_PLATFORM_SC)}>
                      {'Add Login'}
                    </Button>
                    : <Space>
                      { hasAccess(SysPermission.MEMBER.LOGIN.UPDATE)
                        ? <Button shape='round' type='primary' onClick={() => onSubmit(MODE_PLATFORM_SC)}>
                          {'Save'}
                        </Button>
                        : null }
                      { hasAccess(SysPermission.MEMBER.LOGIN.DELETE)
                        ? <Popconfirm
                          title='Confirm to remove login?'
                          onConfirm={() => removeLogin(item, MODE_PLATFORM_SC)}
                          okText='Yes'
                          cancelText='No'
                        >
                          <Button shape='round' ghost type='primary'>
                            {'Remove Login'}
                          </Button>
                        </Popconfirm>
                        : null }
                    </Space> }
              </Space>
            </Page.HeaderSub>
            { !isAddSCLogin
              ? <div>
                <FormItem
                  {...formItemLayout}
                  hasFeedback
                  label='Organisation'
                  name='org_sc_type_id'
                  initialValue={item.org_sc_type_id}
                  rules={[
                    { required: true, message: 'Please enter Organisation' }
                  ]}
                >
                  <Select onChange={e => updatePlatformRoleList(e, keySC)}>
                    { orgList.map(e => (
                      <Option key={`sccorg${e.id}`} value={e.id}>
                        <div>{ e.name }</div>
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  hasFeedback
                  label='Role'
                  name='org_sc_role_id'
                  initialValue={item.org_sc_role_id}
                  rules={[
                    { required: true, message: 'Please enter Organisation Role' }
                  ]}
                >
                  <Select>
                    { currentSCRoleList.map(e => (
                      <Option key={`scrslr${e.id}`} value={e.id}>
                        <div>
                          <div>{`${e.name} (${e.org_name})`}</div>
                          <div className='sub-option'>{ e.description }</div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  label='Active'
                  name='org_sc_active'
                  initialValue={item.org_sc_active}
                  valuePropName='checked'
                  extra={item.active ? undefined : 'Member is disabled now.'}
                >
                  <Switch
                    defaultChecked={item.org_sc_active || false}
                    checkedChildren={'Enable'}
                    unCheckedChildren={'Disable'}
                  />
                </FormItem>
              </div>
              : <div className='no-login-text'>No SC Login is available, press on Add Login button to add.</div>}
          </Panel>
        </Form>
      </Page.ContentLoading>
    </div>
  )
}

export default MemberLogin
