import { RiBuilding4Line, RiFileList2Line, RiDashboardFill, RiAppsLine, RiPercentLine, RiUser2Line, RiLockLine, RiLock2Line, RiLockPasswordLine, RiUserFollowLine, RiGroupLine, RiWindowLine, RiNewspaperLine, RiImageLine, RiContactsBookUploadLine } from 'react-icons/ri'
import SysPermission from '../permissions/sys'

const menu = [
  {
    name: 'Dashboard',
    icon: <RiDashboardFill style={{ fontSize: '18pt' }} />,
    link: '/',
    path: '/'
  },
  {
    name: 'Organisations',
    icon: <RiBuilding4Line style={{ fontSize: '18pt' }} />,
    path: '/organisations',
    permission: SysPermission.ORGANISATIONS.INFO.LIST,
    submenu: [
      {
        name: 'Organisation List',
        icon: <RiFileList2Line />,
        link: '/organisations/listing',
        permission: SysPermission.ORGANISATIONS.INFO.LIST
      },
    ]
  },
  {
    name: 'Members',
    icon: <RiGroupLine style={{ fontSize: '18pt' }} />,
    path: '/members',
    permission: SysPermission.MEMBER.INFO.LIST,
    submenu: [
      {
        name: 'Member List',
        icon: <RiFileList2Line />,
        link: '/members/listing',
        permission: SysPermission.MEMBER.INFO.LIST
      },
    ]
  },
  {
    name: 'Portal',
    icon: <RiWindowLine style={{ fontSize: '18pt' }} />,
    path: '/systems',
    submenu: [
      {
        name: 'Admins',
        icon: <RiUser2Line />,
        submenu: [
          {
            name: 'List',
            icon: <RiUser2Line />,
            link: '/systems/admin-users',
            permission: SysPermission.PORTAL.SYS_USER.LIST
          },
          {
            name: 'User Roles',
            icon: <RiUserFollowLine />,
            link: '/systems/roles',
            permission: SysPermission.PORTAL.SYS_ROLES.LIST
          },
        ]
      },
      {
        name: 'Modules & Permissions',
        icon: <RiLockLine />,
        submenu: [
          {
            name: 'Admins (Portal)',
            icon: <RiLock2Line />,
            link: '/systems/modules',
            permission: SysPermission.PORTAL.SYS_MODULE_PMS.LIST
          },
          {
            name: 'Admins (Organisation)',
            icon: <RiLockPasswordLine />,
            link: '/systems/modules-org',
            permission: SysPermission.PORTAL.SYS_ORG_MODULE_PMS.LIST
          },
        ]
      }
    ]
  },
  {
    name: 'Software',
    icon: <RiAppsLine style={{ fontSize: '18pt' }} />,
    path: '/softwares',
    submenu: [
      {
        name: 'List',
        icon: <RiAppsLine />,
        link: '/softwares/listing',
        permission: SysPermission.SOFTWARE.INFO.LIST
      },
      {
        name: 'Rates',
        icon: <RiPercentLine />,
        link: '/softwares/rates-select',
        permission: SysPermission.SOFTWARE.INFO.LIST
      }
    ]
  }
]

export default menu
