import { RiBuilding4Line, RiFileList2Line, RiDashboardFill, RiAppsLine, RiPercentLine, RiUser2Line, RiLockLine, RiUserFollowLine, RiGroupLine, RiWindowLine, RiNewspaperLine, RiImageLine, RiContactsBookUploadLine } from 'react-icons/ri'
import OrgPermission from '../permissions/org'

const menu = (orgId) => ([
  {
    name: 'Dashboard',
    icon: <RiDashboardFill style={{ fontSize: '18pt' }} />,
    link: '/',
    path: '/'
  },
  {
    name: 'Account',
    icon: <RiBuilding4Line style={{ fontSize: '18pt' }} />,
    path: '/organisation',
    permission: OrgPermission.ORGANISATION.INFO.READ,
    submenu: [
      {
        name: 'Details',
        icon: <RiFileList2Line />,
        link: `/organisation/${orgId}/info`,
        permission: OrgPermission.ORGANISATION.INFO.READ
      },
    ]
  },
  {
    name: 'Acc. Admins',
    icon: <RiGroupLine style={{ fontSize: '18pt' }} />,
    path: '/member',
    permission: OrgPermission.PORTAL.ADMIN_USER.LIST,
    submenu: [
      {
        name: 'List',
        icon: <RiFileList2Line />,
        link: '/member/listing',
        permission: OrgPermission.PORTAL.ADMIN_USER.LIST
      },
      {
        name: 'Admin Roles',
        icon: <RiUserFollowLine />,
        link: '/member/roles',
        permission: OrgPermission.PORTAL.USER_ROLE.LIST
      },
    ]
  },
  {
    name: 'Software',
    icon: <RiAppsLine style={{ fontSize: '18pt' }} />,
    path: '/software',
    permission: OrgPermission.SOFTWARE.INFO.READ,
    submenu: [
      {
        name: 'List',
        icon: <RiFileList2Line />,
        link: '/software/listing',
        permission: OrgPermission.SOFTWARE.INFO.LIST
      },
      // {
      //   name: 'Pumpkin PM',
      //   icon: <RiUser2Line />,
      //   path: '/software',
      //   submenu: [
      //     {
      //       name: 'Settings',
      //       icon: <RiAppsLine style={{ fontSize: '18pt' }} />,
      //       link: '/software/detail/1/info',
      //       permission: OrgPermission.SOFTWARE.SETTINGS.READ
      //     },
      //     {
      //       name: 'Software Users',
      //       icon: <RiAppsLine style={{ fontSize: '18pt' }} />,
      //       link: '/software/detail/1/admin-users',
      //       permission: OrgPermission.SOFTWARE.ADMIN_USER.LIST
      //     },
      //     {
      //       name: 'Software User Roles',
      //       icon: <RiAppsLine style={{ fontSize: '18pt' }} />,
      //       link: '/software/detail/1/roles',
      //       permission: OrgPermission.SOFTWARE.USER_ROLE.LIST
      //     }
      //   ]
      // },
      // {
      //   name: 'Modules & Permissions (Portal Admin)',
      //   icon: <RiLockLine />,
      //   link: '/systems/modules'
      // },
      // {
      //   name: 'Modules & Permissions (Portal Org Admin)',
      //   icon: <RiLockLine />,
      //   link: '/systems/modules-org'
      // },
      // {
      //   name: 'User Roles (Portal Admin)',
      //   icon: <RiUserFollowLine />,
      //   link: '/systems/roles'
      // },
      // {
      //   name: 'Platforms',
      //   icon: <RiAppsLine />,
      //   link: '/systems/platform'
      // },
      // {
      //   name: 'Rates',
      //   icon: <RiPercentLine />
      // }
    ]
  },
  // {
  //   name: 'Settings',
  //   icon: <RiSettings2Line style={{ fontSize: '18pt' }} />,
  //   link: '/settings'
  // }
])

export default menu
