import './styles.css'
import { RiBuilding4Fill, RiBuilding4Line } from 'react-icons/ri'
import { Button } from 'antd'

function Org ({ visible = false, platform = [], selectedPlatform = null, onSelectPlatform = null, onSelect = () => {} }) {
  return (
    <div className={`App-Org ${visible ? 'show' : 'hide'}`}>
      <div className='header'>{'Select Mode'}</div>
      { platform.map(p => {
        const isSelect = p.org_id === parseInt(selectedPlatform)

        return (<div key={`orgas${p.org_id}`} className={`content`} onClick={!isSelect ? () => onSelect(p.org_id) : null}>
          <div className={`row row-style ${isSelect ? 'selected' : ''}`}>
            { p.org_id
              ? <img alt={p.org_name} src={p.thumb_url} className='app-org-logo' />
              : isSelect
                ? <RiBuilding4Line color={'#F4F4F4'} style={{ fontSize: '20px', marginRight: '5px' }} />
                : <RiBuilding4Fill color={'#e84e1c'} style={{ fontSize: '20px', marginRight: '5px' }} />}
            <div>{p.org_name}</div>
          </div>
        </div>)
      })}

    </div>
  )
}

export default Org
