import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input, notification, Pagination, Row, Col, Space, Skeleton, Spin, Switch, Tabs, Table } from 'antd'
import { CloseOutlined, CheckOutlined, CheckCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Page, Panel, Total } from '../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../constants'
import { platformService } from '../../../../../services'
import { auth, validator } from '../../../../../util'
import { SysStore } from '../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'
import ModuleList from './Modules/List'
import UserList from './UserList'
import UserRole from './UserRole/List'

const FormItem = Form.Item
const FormList = Form.List
const TextArea = Input.TextArea
const { TabPane } = Tabs

const pageSize = 20

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 5 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 2, path: '/modules' }
]

const ActiveTabList = [
  { tabId: 1, path: '/info' },
  { tabId: 2, path: '/modules' },
  { tabId: 3, path: '/roles' },
  { tabId: 4, path: '/admin-users' }
]

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.SoftwareList,
  Breadcrumb.SoftwareEdit
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function SoftwareEdit ({match}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [item, setItem] = useState({})
  const [currentTab, setCurrentTab] = useState('1')

  const [form] = Form.useForm()
  const history = useHistory()
  const { params } = match
  const isEdit = params && params.id !== 'add' && params.id !== 'new'
  const isActive = item && item.id && item.active === true
  const currentId = params ? params.id : ''

  useEffect(() => {
    if (isEdit && hasAccess(SysPermission.SOFTWARE.INFO.READ)) {
      fetchItem()
    }

    if (params.type) {
      let tab = null
      if (isActive) {
        tab = ActiveTabList.find(e => e.path === params.type || e.path === `/${params.type}`)
      } else {
        tab = TabList.find(e => e.path === params.type || e.path === `/${params.type}`)
      }

      const cTab = tab && tab.tabId ? String(tab.tabId) : '1'
      setCurrentTab(cTab)
    } else {
      history.replace(`/softwares/listing/${currentId}${TabList[0].path}`)
    }
  }, [])

  async function fetchItem () {
    setIsLoading(true)
    const r = await platformService.get(currentId)
    if (r && r.id) {
      setItem(r)
      setIsLoading(false)
    }
  }

  async function onSubmit () {
    form.validateFields()
      .then(async (values) => {
        setIsUpdate(true)
        let r = null

        if (isEdit) {
          r = await platformService.save(currentId, values)
        } else {
          r = await platformService.add(values)
        }

        if (r && r.id) {
          if (isEdit) {
            notification.success({
              message: 'Update Software successfully',
              description: 'Software is updated successfully.'
            })
          } else {
            notification.success({
              message: 'Create Software successfully',
              description: 'Software is created successfully.'
            })

            setTimeout(() => {
              const link = `/softwares/listing/${r.id}`
              history.replace(link)
              window.location.replace(link)
            }, 1000)
          }
        } else {
          notification.error({
            message: 'Save Software not successful',
            description: 'Software is unable to save successfully.'
          })
        }

        setIsUpdate(false)
      })
      .catch(errorInfo => {
        notification.error({
          message: 'Save Software not successful',
          description: 'Software is unable to save successfully.'
        })

        setIsUpdate(false)
      })
  }

  async function onSubmitFailed ({ values, errorFields, outOfDate }) {
    notification.error({
      message: 'Save User Role not successful',
      description: 'User Role is unable to save successfully.'
    })

    setIsUpdate(false)
  }

  function handleTabChange (index) {
    let tab = null
    if (isActive) {
      tab = ActiveTabList.find(e => e.tabId === parseInt(index))
    } else {
      tab = TabList.find(e => e.tabId === parseInt(index))
    }

    setCurrentTab(index)
    if (tab && tab.tabId) {
      history.replace(`/softwares/listing/${currentId}${tab.path}`)
    }
  }

  function onAllToggleChanged (e) {
    if (validator.isNotEmptyArray(item.permissionsList)) {
      for (let i = 0; i < item.permissionsList.length; i++) {
        const itm = item.permissionsList[i]
        form.setFieldsValue({[`active${itm.id}`]: e})
      }
    }
  }

  function getInfoTab () {
    return (
      <Form
        form={form}
        name='detail'
        onFinish={onSubmit}
        onFinishFailed={onSubmitFailed}
      >
        <Panel title='Details'>
          <FormItem
            {...formItemLayout}
            hasFeedback
            label='Name'
            name='name'
            initialValue={item.name}
            rules={[
              { min: 2, message: 'Name must be between 2 and 128 characters' },
              { max: 128, message: 'Name must be between 2 and 128 characters' },
              { required: true, message: 'Please enter name' },
              { whitespace: true, message: 'Please enter name' }
            ]}
          >
            <Input placeholder={'Enter Classification Name'} />
          </FormItem>
          <FormItem
            {...formItemLayout}
            hasFeedback
            label='Description'
            name='description'
            initialValue={item.description}
            rules={[
              { min: 2, message: 'Description must be at least 2 characters' },
              { required: true, message: 'Please enter description' },
              { whitespace: true, message: 'Please enter description' }
            ]}
          >
            <TextArea rows={1} autoSize placeholder={'Enter Classification Description'} />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label='Active'
            name='active'
            initialValue={item.active}
            valuePropName='checked'
          >
            <Switch
              defaultChecked={item.active}
              disabled
              checkedChildren={'Enable'}
              unCheckedChildren={'Disable'}
            />
          </FormItem>
        </Panel>
      </Form>
    )
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title={isLoading ? `Software` : item.id ? `Software - ${item.name}` : `Add Software`}>
        <Space>
          { currentTab === String(TabList[0].tabId) &&
            ((isEdit && hasAccess(SysPermission.SOFTWARE.INFO.UPDATE)) || (!isEdit && hasAccess(SysPermission.SOFTWARE.INFO.CREATE)))
            ? <Button shape='round' type='primary' loading={isUpdate} onClick={onSubmit}>{ isUpdate ? 'Saving' : 'Save'}</Button>
            : null }
          <div onClick={history.goBack}>
            <Button shape='round' ghost type='primary'>Back</Button>
          </div>
        </Space>
      </Page.Header>

      <Page.ContentLoading isLoading={isLoading} isUpdate={isUpdate}>
        <Tabs
          defaultActiveKey={currentTab}
          onChange={handleTabChange}
        >
          <TabPane tab='Software Info' key='1'>
            { getInfoTab() }
          </TabPane>
          { isEdit && hasAccess(SysPermission.SOFTWARE.MODULE_PMS.LIST)
            ? <TabPane tab='Modules & Permissions' key='2'>
              <ModuleList platformId={currentId} />
            </TabPane>
            : null }
          { isEdit && isActive && hasAccess(SysPermission.SOFTWARE.ADMIN_USER.LIST)
            ? <TabPane tab='Admin Users' key='3'>
              <UserList platformId={currentId} />
            </TabPane>
            : null }
          { isEdit && isActive && hasAccess(SysPermission.SOFTWARE.ADMIN_ROLES.LIST)
            ? <TabPane tab='User Roles' key='4'>
              <UserRole platformId={currentId} />
            </TabPane>
            : null }
        </Tabs>
      </Page.ContentLoading>
    </Page.Body>
  )
}

export default SoftwareEdit
