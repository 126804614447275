import { queryString, request } from '../util'

const headers = { 'Content-Type': 'application/json' }
const urlsys = '/private/api/portal/sys/platforms/modules'

export default {
  add (values) {
    return request(urlsys, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${urlsys}/${id}`)
  },
  remove (id) {
    return request(`${urlsys}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${urlsys}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${urlsys}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
}
