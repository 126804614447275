import { apiHostname } from '../config'
import auth from '../util/auth'
import { localStorage, request } from './index'

const UploadType = {
  FILE: 'file',
  IMAGE: 'image'
}

export default {
  getProps (apiUrl, options) {
    const defaultOptions = {
      name: 'image',
      accept: '.jpg, .jpeg, .png',
      multiple: false
    }
    options = Object.assign(defaultOptions, options || {})
    const { accept, data, fileList = [], method = 'GET', name, multiple = false, beforeUpload, onChange, onError, onProgress, onRemove, onStart, onSuccess } = options
    return {
      method,
      action: `${apiHostname}${apiUrl}`,
      headers: { Authorization: `Bearer ${auth.getCurrentToken()}` },
      data,
      name,
      accept,
      fileList,
      multiple,
      beforeUpload (file) {
        if (typeof beforeUpload === 'function') {
          beforeUpload(file)
        }
      },
      onChange (file) {
        if (typeof onChange === 'function') {
          onChange(file)
        }
      },
      onError (err, response, file) {
        if (typeof onError === 'function') {
          onError(err, response, file)
        } else {
          console.error(err, response, file)
        }
      },
      onRemove (file) {
        if (typeof onRemove === 'function') {
          onRemove(file)
        }
      }
    }
  },
  UploadType: {
    FILE: UploadType.FILE,
    IMAGE: UploadType.IMAGE
  },
  async upload (id, info, token, type) {
    type = type || UploadType.IMAGE
    const { file } = info
    const { error, originFileOb } = file
    const { method, url } = error
    const formData = new window.FormData()
    formData.append('id', id)
    formData.append(type, originFileOb)
    localStorage.setItem('token', token)
    return request(url, {
      method,
      body: formData
    })
  }
}
