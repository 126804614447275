import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input, notification, Pagination, Row, Col, Space, Skeleton, Spin, Switch, Table } from 'antd'
import { CloseOutlined, CheckOutlined, CheckCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Page, Panel, Total } from '../../../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../../../constants'
import { sysAdminRoleService, orgAdminRoleService } from '../../../../../../../services'
import { auth, validator } from '../../../../../../../util'
import { SysStore } from '../../../../../../..//stores'

import 'antd/dist/antd.css'
import './styles.css'

const FormItem = Form.Item
const FormList = Form.List
const TextArea = Input.TextArea

const pageSize = 20

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = (orgId) => [
  Breadcrumb.HomeBase,
  Breadcrumb.OrgBase,
  Breadcrumb.OrgList,
  {label: 'Edit', link: `/organisations/listing/${orgId}/roles`},
  {label: 'Portal (Organisation) Admin Role Edit', link: ''}
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function OrgAdminRoleEdit ({match}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [item, setItem] = useState({})

  const [form] = Form.useForm()
  const history = useHistory()
  const { params } = match
  const currentId = params ? params.id : null
  const roleId = params ? params.pId : null
  const isEdit = params && params.pId !== 'add' && params.pId !== 'new'
  const isMasterRole = item && item.id && !item.is_editable

  useEffect(() => {
    if (isEdit && hasAccess(SysPermission.ORGANISATIONS.PORTAL_ROLES.READ)) {
      fetchItem()
    }
  }, [])

  async function fetchItem () {
    setIsLoading(true)
    const r = await orgAdminRoleService.get(roleId)
    if (r && r.id) {
      if (!validator.isArray(r.permission_ids)) r.permission_ids = []
      setItem(r)
      setIsLoading(false)
    }
  }

  async function onSubmit () {
    form.validateFields()
      .then(async (values) => {
        setIsUpdate(true)
        let r = null

        if (isEdit) {
          let list = []
          if (validator.isNotEmptyArray(item.permissionsList)) {
            for (let i = 0; i < item.permissionsList.length; i++) {
              const p = item.permissionsList[i]
              if (values[`active${p.id}`] === true) {
                list.push(p.id)
              }
            }
          }

          let body = {
            permission_ids: list,
            name: values.name,
            description: values.description
          }
          r = await orgAdminRoleService.save(roleId, body)
        } else {
          r = await orgAdminRoleService.add(values)
        }

        if (r && r.id) {
          if (isEdit) {
            notification.success({
              message: 'Update User Role successfully',
              description: 'User Role is updated successfully.'
            })
          } else {
            notification.success({
              message: 'Create User Role successfully',
              description: 'User Role is created successfully.'
            })

            setTimeout(() => {
              const link = `/systems/roles/${r.id}`
              history.replace(link)
              window.location.replace(link)
            }, 1000)
          }
        } else {
          notification.error({
            message: 'Save User Role not successful',
            description: 'User Role is unable to save successfully.'
          })
        }

        setIsUpdate(false)
      })
      .catch(errorInfo => {
        notification.error({
          message: 'Save User Role not successful',
          description: 'User Role is unable to save successfully.'
        })

        setIsUpdate(false)
      })
  }

  async function onSubmitFailed ({ values, errorFields, outOfDate }) {
    notification.error({
      message: 'Save User Role not successful',
      description: 'User Role is unable to save successfully.'
    })

    setIsUpdate(false)
  }

  function onAllToggleChanged (e) {
    if (validator.isNotEmptyArray(item.permissionsList)) {
      for (let i = 0; i < item.permissionsList.length; i++) {
        const itm = item.permissionsList[i]
        form.setFieldsValue({[`active${itm.id}`]: e})
      }
    }
  }

  let lastModuleId = ''
  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb(currentId)} />

      <Page.Header title={item.id ? `Portal (Organisation) User Role - ${item.name}` : `Add User Role for Portal (Organisation)`}>
        <Space>
          { (isEdit && hasAccess(SysPermission.ORGANISATIONS.INFO.UPDATE)) || (!isEdit && hasAccess(SysPermission.ORGANISATIONS.INFO.CREATE))
            ? <Button shape='round' type='primary' loading={isUpdate} onClick={onSubmit}>{ isUpdate ? 'Saving' : 'Save'}</Button>
            : null }
          <div onClick={history.goBack}>
            <Button shape='round' ghost type='primary'>Back</Button>
          </div>
        </Space>
      </Page.Header>

      <Page.ContentLoading isLoading={isLoading} isUpdate={isUpdate}>
        <Form
          form={form}
          layout={'vertical'}
          name='detail'
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Panel title='Details'>
            <Row gutter={12}>
              <Col lg={7}>
                <FormItem
                  hasFeedback
                  label='Name'
                  name='name'
                  initialValue={item.name}
                  rules={[
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter name' },
                    { whitespace: true, message: 'Please enter module name' }
                  ]}
                >
                  <Input disabled={isMasterRole} placeholder={'Enter Module Name'} />
                </FormItem>
              </Col>
              <Col lg={15}>
                <FormItem
                  hasFeedback
                  label='Description'
                  name='description'
                  initialValue={item.description}
                  rules={[
                    { min: 2, message: 'Description must be at least 2 characters' },
                    { required: true, message: 'Please enter module description' },
                    { whitespace: true, message: 'Please enter module description' }
                  ]}
                >
                  <TextArea disabled={isMasterRole} rows={1} autoSize placeholder={'Enter Module Description'} />
                </FormItem>
              </Col>
              <Col lg={2}>
                <FormItem
                  label='Active'
                  name='active'
                  initialValue={item.active}
                  valuePropName='checked'
                >
                  <Switch
                    defaultChecked={item.active}
                    checkedChildren={'Enable'}
                    unCheckedChildren={'Disable'}
                    disabled={isMasterRole}
                  />
                </FormItem>
              </Col>
            </Row>
          </Panel>

          { validator.isNotEmptyArray(item.permissionsList)
            ? <Panel
              title='Permissions'
              subtitle={<div>
                <Switch
                  defaultChecked={false}
                  onChange={e => onAllToggleChanged(e)}
                  checkedChildren={'Allow All'}
                  unCheckedChildren={'Deny All'}
                />
              </div>}
            >
              { item.permissionsList.map((itm, index) => {
                const header = (<Row>
                  <div className='role-header'>{itm.module_name}</div>
                </Row>)
                const isShowHeader = lastModuleId !== itm.module_id
                lastModuleId = itm.module_id
                const isChecked = validator.isArray(item.permission_ids) && item.permission_ids.findIndex(e => e === itm.id) > -1
                return (<div key={`msgrows${itm.id}`}>
                  { isShowHeader ? header : null }
                  <Row className='row-role' gutter={12}>
                    <Col lg={5}>
                      <div className='title'>{itm.name}</div>
                    </Col>
                    <Col lg={16}>
                      <div className='description'>{itm.description}</div>
                    </Col>
                    <Col lg={3}>
                      <FormItem
                        name={`active${itm.id}`}
                        initialValue={isChecked}
                        valuePropName='checked'
                      >
                        <Switch
                          defaultChecked={isChecked}
                          checkedChildren={'Allow'}
                          unCheckedChildren={'Deny'}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                </div>)
              })}
            </Panel>
            : null }
        </Form>
      </Page.ContentLoading>
    </Page.Body>
  )
}

export default OrgAdminRoleEdit
