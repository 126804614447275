export default {
  HomeBase: { label: 'Home', link: '/' },
  HomeDashboard: { label: 'Dashboard', link: '' },
  MemberBase: { label: 'Members', link: '/members' },
  MemberList: { label: 'List', link: '/members/listing' },
  MemberEdit: { label: 'Edit', link: '' },
  OrgBase: { label: 'Organisations', link: '/organisations' },
  OrgList: { label: 'List', link: '/organisations/listing' },
  OrgEdit: { label: 'Edit', link: '' },
  OrgAdminList: { label: 'Admins', link: '/organisations/admin-users' },
  SystemBase: { label: 'Portal', link: '/systems' },
  SystemAdminList: { label: 'Admins', link: '/systems/admin-users' },
  SystemAdminPage: { label: 'Edit', link: '' },
  SoftwareList: { label: 'Software', link: '/softwares/listing' },
  SoftwareEdit: { label: 'Edit', link: '' },
  SoftwareModuleEdit: { label: 'Software Module Edit', link: '' },
  SoftwareRatesSoftwareList: { label: 'Rates - Select Software', link: '/softwares/rates-select' },
  SoftwareRatesListPM: { label: 'Rates', link: '/softwares/rates/1/rate-sets' },
  SoftwareRatesEditRateSetPM: { label: 'Rate Set Edit', link: '' },
  SoftwareRatesEditRatesPM: { label: 'Rate Edit', link: '' },
  SoftwareRatesEditCatPM: { label: 'Category Edit', link: '' },
  SystemModuleList: { label: 'Modules & Permissions (Portal Admin)', link: '/systems/modules' },
  SystemModuleEdit: { label: 'Edit', link: '' },
  SystemModuleOrgList: { label: 'Modules & Permissions (Portal Org)', link: '/systems/modules-org' },
  SystemModuleOrgEdit: { label: 'Edit', link: '' },
  SystemAdminRoleList: { label: 'User Roles', link: '/systems/roles' },
  SystemAdminRoleEdit: { label: 'Edit', link: '' }
}
