import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Table, Pagination, Row, Col, Space } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Loading, Page, Panel, Total } from '../../../../../components'
import { BreadcrumbOrg as Breadcrumb, OrgPermission } from '../../../../../constants'
import { memberService } from '../../../../../services'
import { auth, localStorage, validator } from '../../../../../util'
import { MemberStore } from '../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.MemberBase,
  Breadcrumb.MemberList
]

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 6
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    width: 4
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 5
  },
  {
    title: 'Organisation Role',
    dataIndex: 'system_org_role_name',
    key: 'org_role_name',
    width: 5
  },
  {
    title: 'Active',
    dataIndex: 'system_org_active',
    key: 'org_active',
    width: 1,
    render: (active) => active
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#ccc', fontSize: '14pt'}} />
  },
  {
    width: 1,
    render: (item) => <Space size='middle'>
      { hasAccess(OrgPermission.PORTAL.ADMIN_USER.READ)
        ? <Link to={`/member/listing/${item.id}`} key={`memberkey${item.id}`}>
          <FormOutlined />
        </Link>
        : null }

    </Space>
  },
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function MemberList () {
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState({})
  const [sort, setSort] = useState({})
  const [orgId, setOrgId] = useState(null)

  useEffect(() => {
    setCurrentOrgId(() => {
      fetchList({ currentPage: 1 })
    })
  }, [])

  const list = MemberStore.useState(e => e.memberList)
  const page = MemberStore.useState(e => e.memberPage)
  const total = MemberStore.useState(e => e.memberTotal)
  const isMemberListLoading = MemberStore.useState(e => e.isMemberListLoading)

  function setCurrentOrgId (cb = null) {
    let orgId = auth.getMode()
    if (String(orgId)) {
      setOrgId(orgId)
      setFilter({ system_org_type_id: orgId })
      if (cb) cb()
    }
  }

  async function fetchList ({ currentPage = page }) {
    if (!hasAccess(OrgPermission.PORTAL.ADMIN_USER.LIST)) return

    let showMode = auth.getMode()
    filter.system_org_type_id = showMode

    setIsLoading(true)
    const r = await memberService.orglistByPage(currentPage, pageSize, filter, sort)
    if (r && r.list) {
      setIsLoading(false)
      MemberStore.update(e => {
        e.memberList = r.list
        e.memberTotal = r.total
        e.memberPage = currentPage
      })
    }
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title='Admin'>
        { hasAccess(OrgPermission.PORTAL.ADMIN_USER.CREATE)
          ? <Link to='/member/listing/add'>
            <Button shape='round' type='primary'>Add Admin</Button>
          </Link>
          : null }
      </Page.Header>

      <Page.Content>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={list}
          size='small'
          rowKey={(itm) => (`memitm${itm.id}`)}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total,
            onChange: (e) => fetchList({currentPage: e})
          }}
        />
        <Total total={total} title={'admin'} />
      </Page.Content>
    </Page.Body>
  )
}

export default MemberList
