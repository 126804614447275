import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { RiArrowRightSLine, RiArrowDownSLine } from 'react-icons/ri'

import { MainMenu, MainOrgMenu } from '../../../constants'
import { auth, localStorage, validator } from '../../../util'
import './styles.css'

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function Menu ({ type, visible, submenuVisible, isSubMenuVisible }) {
  const [selectedMenu, setSelectedMenu] = useState(-1)
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0)
  const [showSubMenu, setShowSubMenu] = useState(false)
  const [expandedIndexes, setExpandedIndexes] = useState([])

  const loc = useLocation()

  const menu = !type ? MainMenu : MainOrgMenu(type)

  useEffect(() => {
    function setSubMenuVisibility (submenuVisible) {
      setShowSubMenu(submenuVisible)
    }

    setSubMenuVisibility(submenuVisible)
  }, [submenuVisible])

  useEffect(() => {
    function setMenuIndex () {
      let path = loc.pathname.split('/')
      let pathname = '/'
      if (validator.isNotEmptyArray(path)) {
        path = path.filter(e => e !== '')
        pathname += path[0]
      }

      const menuIndex = menu.findIndex(e => e.path === pathname)

      if (menuIndex > -1) {
        setSelectedMenuIndex(menuIndex)
        setSelectedMenu(menu[menuIndex])
      } else {
        setSelectedMenuIndex(0)
        setSelectedMenu(menu[0])
      }
    }

    if (loc.pathname) {
      setMenuIndex()
    }
  }, [loc.pathname])

  function toggleSubMenu (index) {
    let shouldShow = false

    if (Number.isInteger(index)) {
      setSelectedMenuIndex(index)
      setSelectedMenu(menu[index].submenu ? menu[index] : null)

      shouldShow = !!menu[index].submenu
    }

    setShowSubMenu(shouldShow)
    isSubMenuVisible(shouldShow)
  }

  function isSubMenuExpanded (index) {
    const isExpanded = expandedIndexes.findIndex(expandedIndex => index === expandedIndex)
    return isExpanded > -1
  }

  function toggleSubMenuSubMenu (index) {
    const expandedIndex = expandedIndexes.findIndex(expandedIndex => index === expandedIndex)
    const expandedIndexNew = expandedIndexes.slice(0)

    if (expandedIndex > -1) {
      expandedIndexNew.splice(expandedIndex, 1)
    } else {
      expandedIndexNew.push(index)
    }

    setExpandedIndexes(expandedIndexNew)
  }

  return (
    <div>
      <div className={`App-menu ${!visible ? 'App-menu-hide' : ''}`}>
        <div className='App-menu-list'>
          {
            menu.map((item, idx) => {
              const isSelected = selectedMenuIndex === idx
              const isShow = !item.permission ? true : hasAccess(item.permission)
              const menuDiv = visible
                ? (
                  <div key={`mi${idx}`} className={`App-menu-item ${isSelected ? 'active' : ''}`} onClick={() => toggleSubMenu(idx)}>
                    {item.icon}
                    <div>{item.name}</div>
                  </div>
                ) : (
                  <div key={`mic${idx}`} className={`App-menu-item ${isSelected ? 'active' : ''} hide`} onClick={() => toggleSubMenu(idx)}>
                    {item.icon}
                  </div>
                )

              return item.link && isShow
                ? <Link style={{ color: 'inherit', textDecoration: 'inherit' }} key={idx} to={item.link}>{menuDiv}</Link>
                : menuDiv
            })
          }
        </div>
      </div>
      <div className={`App-submenu-list ${showSubMenu && selectedMenu ? 'show' : ''} ${visible ? '' : 'hide-main'}`}>
        {
          selectedMenu
            ? (<div>
              <div className='title'>{selectedMenu.name}</div>
              { selectedMenu.submenu && selectedMenu.submenu.map((submenu, idx) => {
                const hasSubmenu = submenu.submenu && submenu.submenu.length > 0
                const isShow = !submenu.permission ? true : hasAccess(submenu.permission)
                const submenuDiv = <div key={`s-` + idx} className='item' onClick={() => hasSubmenu ? toggleSubMenuSubMenu(selectedMenuIndex + '-' + idx) : null}>
                  <div className='sub-item-w-icon'>
                    <div className='icon'>{submenu.icon}</div>
                    {/* {submenu.icon}&nbsp;{submenu.name} */}
                    <div className='text'>{submenu.name}</div>
                  </div>
                  {hasSubmenu ? isSubMenuExpanded(selectedMenuIndex + '-' + idx) ? <RiArrowDownSLine /> : <RiArrowRightSLine /> : null}
                </div>

                return <div key={`micx${idx}`}>
                  {submenu.link && isShow
                    ? <Link style={{ color: 'inherit', textDecoration: 'inherit' }} key={`l-` + idx} to={submenu.link} onClick={() => setShowSubMenu(false)}>{submenuDiv}</Link>
                    : submenuDiv}
                  {hasSubmenu && isSubMenuExpanded(selectedMenuIndex + '-' + idx) && submenu.submenu.map((submenu2, idx2) => {
                    const menuDiv = (<div key={idx2} className='item sub-item'>
                      <div>&#8226;&nbsp; {submenu2.name}</div>
                    </div>)

                    return submenu2.link && isShow
                      ? <Link style={{ color: 'inherit', textDecoration: 'inherit' }} key={`ls-` + idx2} to={submenu2.link} onClick={() => setShowSubMenu(false)}>{menuDiv}</Link>
                      : menuDiv
                  })}
                </div>
              })
              }
            </div>
            ) : null
        }
      </div>
    </div>
  )
}

export default Menu
