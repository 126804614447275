import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Table, Pagination, Row, Col, Space } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Loading, Page, Panel, Total } from '../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../constants'
import { memberService } from '../../../../../services'
import { auth, validator } from '../../../../../util'
import { SysStore } from '../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.SystemBase,
  Breadcrumb.SystemAdminList
]

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 6
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    width: 4
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 5
  },
  {
    title: 'Role',
    dataIndex: 'system_role_name',
    key: 'system_role_name',
    width: 5
  },
  {
    title: 'Active',
    dataIndex: 'system_active',
    key: 'system_active',
    width: 1,
    render: (active) => active
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#ccc', fontSize: '14pt'}} />
  },
  {
    width: 3,
    render: (item) => <Space size='middle'>
      { hasAccess(SysPermission.MEMBER.INFO.UPDATE)
        ? <Link to={`/members/listing/${item.id}`} key={`modulekey${item.id}`}>
          <FormOutlined />
        </Link>
        : null }

    </Space>
  },
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function SystemAdminUserList () {
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState({ active: true, system_access_id: {condition: 'is not null'} })
  const [sort, setSort] = useState({})

  useEffect(() => {
    fetchList({ currentPage: 1 })
  }, [])

  const list = SysStore.useState(e => e.sysAdminUserList)
  const page = SysStore.useState(e => e.sysAdminUserPage)
  const total = SysStore.useState(e => e.sysAdminUserTotal)
  const isSysModuleListLoading = SysStore.useState(e => e.isSysAdminUserLoading)

  async function fetchList ({ currentPage = page }) {
    if (!hasAccess(SysPermission.PORTAL.SYS_USER.LIST)) return

    setIsLoading(true)
    const r = await memberService.listByPage(currentPage, pageSize, filter, sort)
    if (r && r.list) {
      setIsLoading(false)
      SysStore.update(e => {
        e.sysAdminUserList = r.list
        e.sysAdminUserTotal = r.total
        e.sysAdminUserPage = currentPage
      })
    }
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title='Portal Admins'>
        { hasAccess(SysPermission.MEMBER.INFO.CREATE)
          ? <Link to='/members/listing/add'>
            <Button shape='round' type='primary'>Add Portal Admin</Button>
          </Link>
          : null }

      </Page.Header>

      <Page.Content>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={list}
          size='small'
          rowKey={(itm) => (`ptamn${itm.id}`)}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total,
            onChange: (e) => fetchList({currentPage: e})
          }}
        />
        <Total total={total} title='admin' />
      </Page.Content>
    </Page.Body>
  )
}

export default SystemAdminUserList
