import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Table, Pagination, Row, Col, Space } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Loading, Page, Panel, Total } from '../../../../../../components'
import { BreadcrumbSystem as Breadcrumb, PlatformKey, SysPermission } from '../../../../../../constants'
import { memberService } from '../../../../../../services'
import { auth, validator } from '../../../../../../util'
import { MemberStore } from '../../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20

const columns = (platformId) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 4
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    width: 4
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 4
  },
  platformId === PlatformKey.keyPM
  ? {
    title: 'Organisation',
    dataIndex: 'org_pm_name',
    key: 'org_pm_name',
    width: 5
  }
  : platformId === PlatformKey.keySC
    ? {
      title: 'Organisation',
      dataIndex: 'org_sc_name',
      key: 'org_sc_name',
      width: 5
    }
    : { width: 0 },
  platformId === PlatformKey.keyPM
    ? {
      title: 'User Role',
      dataIndex: 'org_pm_role_name',
      key: 'org_pm_role_name',
      width: 4
    }
    : platformId === PlatformKey.keySC
      ? {
        title: 'User Role',
        dataIndex: 'org_sc_role_name',
        key: 'org_sc_role_name',
        width: 4
      }
      :  { width: 0 },
  platformId === PlatformKey.keyPM
    ? {
      title: 'Active',
      dataIndex: 'org_pm_active',
      key: 'org_pm_active',
      width: 1,
      render: (active) => active
        ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
        : <CloseCircleOutlined style={{color: '#ccc', fontSize: '14pt'}} />
    }
    : platformId === PlatformKey.keySC
      ? {
        title: 'Active',
        dataIndex: 'org_sc_active',
        key: 'org_sc_active',
        width: 1,
        render: (active) => active
          ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
          : <CloseCircleOutlined style={{color: '#ccc', fontSize: '14pt'}} />
      }
      : { width: 0 },
  {
    width: 1,
    render: (item) => <Space size='middle'>
      { hasAccess(SysPermission.MEMBER.INFO.UPDATE)
        ? <Link to={`/members/listing/${item.id}`} key={`memberkey${item.id}`}>
          <FormOutlined />
        </Link>
        : null }
    </Space>
  },
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function SoftwareMemberList (props) {
  const platformId = parseInt(props.platformId)
  const filterCondition = platformId === PlatformKey.keyPM
    ? { org_pm_platform_id: platformId, org_pm_access_id: { condition: 'is not null' } }
    : platformId === PlatformKey.keySC
      ? { org_sc_platform_id: platformId, org_sc_access_id: { condition: 'is not null' } }
      : {}
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState(filterCondition)
  const [isMemberListLoading, setIsMemberListLoading] = useState(false)
  const [sort, setSort] = useState({ org_pm_name: 1, org_sc_name: 1, name: 1 })

  useEffect(() => {
    fetchList({ currentPage: 1 })
  }, [])

  async function fetchList ({ currentPage = page }) {
    if (!hasAccess(SysPermission.SOFTWARE.ADMIN_USER.LIST)) return
    setIsLoading(true)
    const r = await memberService.listByPage(currentPage, pageSize, filter, sort)
    if (r && r.list) {
      setIsLoading(false)
      setList(r.list)
      setPage(currentPage)
      setTotal(r.total)
    }
  }

  return (
    <div>
      <Page.Header title='Admin Users'>
        { hasAccess(SysPermission.MEMBER.INFO.CREATE)
          ? <Link to='/members/listing/add'>
            <Button shape='round' type='primary'>Add Member</Button>
          </Link>
          : null }

      </Page.Header>

      <Page.Content>
        <Table
          loading={isLoading}
          columns={columns(platformId)}
          dataSource={list}
          size='small'
          rowKey={(itm) => (`memitm${itm.id}`)}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total,
            onChange: (e) => fetchList({currentPage: e})
          }}
        />
        <Total total={total} title={'member'} />
      </Page.Content>
    </div>
  )
}

export default SoftwareMemberList
