import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Table, Pagination, Row, Col, Space } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Loading, Page, Panel, Total } from '../../../../../components'
import { BreadcrumbOrg as Breadcrumb, OrgPermission } from '../../../../../constants'
import { orgAdminRoleService } from '../../../../../services'
import { auth, localStorage, validator } from '../../../../../util'
import { SysStore } from '../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20

const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.MemberBase,
  Breadcrumb.MemberRoleList
]

const columns = () => ([
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 8
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 11
  },
  {
    width: 2,
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#ccc', fontSize: '14pt'}} />
  },
  {
    width: 3,
    render: (item) => <Space size='middle'>
      { hasAccess(OrgPermission.PORTAL.USER_ROLE.READ)
        ? <Link to={`/member/roles/${item.id}`} key={`rolekey${item.id}`}>
          <FormOutlined />
        </Link>
        : null }

    </Space>
  },
])

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function OrgAdminUserRoleList (props) {
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState({ active: true })
  const [sort, setSort] = useState({})

  useEffect(() => {
    fetchList({ currentPage: 1 })
  }, [])

  async function fetchList ({ currentPage = page }) {
    if (!hasAccess(OrgPermission.PORTAL.USER_ROLE.LIST)) return

    let showMode = auth.getMode()
    setIsLoading(true)

    // filter.org_id = [0, parseInt(showMode)]
    filter.org_id = parseInt(showMode)
    const r = await orgAdminRoleService.orgListByPage(currentPage, pageSize, filter, sort)

    if (r && r.list) {
      setIsLoading(false)
      setList(r.list)
      setPage(currentPage)
      setTotal(r.total)
    }
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title='Admin Roles'>
        { hasAccess(OrgPermission.PORTAL.USER_ROLE.CREATE)
          ? <Link to={`/member/roles/add`}>
            <Button shape='round' type='primary'>Add Admin Role</Button>
          </Link>
          : null }

      </Page.Header>

      <Page.Content>
        <Table
          loading={isLoading}
          columns={columns()}
          dataSource={list}
          size='small'
          rowKey={(itm) => (`rolels${itm.id}`)}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total,
            onChange: (e) => fetchList({currentPage: e})
          }}
        />
        <Total total={total} title={'role'} />
      </Page.Content>
    </Page.Body>
  )
}

export default OrgAdminUserRoleList
