import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input, notification, Pagination, Row, Col, Space, Skeleton, Spin, Switch, Tabs, Table } from 'antd'
import { CloseOutlined, CheckOutlined, CheckCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Page, Panel, Total } from '../../../../../components'
import { BreadcrumbOrg as Breadcrumb, OrgPermission, PlatformKey } from '../../../../../constants'
import { platformService } from '../../../../../services'
import { auth, localStorage, validator } from '../../../../../util'
import { SysStore } from '../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'
import RoleList from './AdminRole/List'
import UserList from './UserList/List'
import DetailsInfo from './PlatformDetails'

const FormItem = Form.Item
const FormList = Form.List
const TextArea = Input.TextArea
const { TabPane } = Tabs

const pageSize = 20

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 5 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const TabList = [
  { tabId: 1, path: '/info' }
]

const ActiveTabList = [
  { tabId: 1, path: '/info' },
  { tabId: 2, path: '/admin-users' },
  { tabId: 3, path: '/roles' }
]

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = (platformId) => [
  Breadcrumb.HomeBase,
  Breadcrumb.PortalPlatform,
  platformId === PlatformKey.keyPM
  ? Breadcrumb.PortalPlatformPM
  : platformId === PlatformKey.keySC
    ? Breadcrumb.PortalPlatformSC
    : null,
  platformId === PlatformKey.keyPM
    ? Breadcrumb.PortalPlatformPMEdit
    : platformId === PlatformKey.keySC
      ? Breadcrumb.PortalPlatformSCEdit
      : null
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function PlatformEdit ({match}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [item, setItem] = useState({})
  const [currentTab, setCurrentTab] = useState('1')

  const [form] = Form.useForm()
  const history = useHistory()
  const { params } = match
  const platformId = params && params.id ? parseInt(params.id) : ''
  const isActive = item && item.id && item.active === true
  const orgId = auth.getMode()

  useEffect(() => {
    fetchItem()

    if (params.type) {
      let tab = null
      if (isActive) {
        tab = ActiveTabList.find(e => e.path === params.type || e.path === `/${params.type}`)
      } else {
        tab = TabList.find(e => e.path === params.type || e.path === `/${params.type}`)
      }

      const cTab = tab && tab.tabId ? String(tab.tabId) : '1'
      setCurrentTab(cTab)
    } else {
      history.replace(`/software/detail/${platformId}${TabList[0].path}`)
    }
  }, [])

  async function fetchItem () {
    // if (!hasAccess(OrgPermission.SOFTWARE.INFO.READ)) return
    setIsLoading(true)
    const r = await platformService.orgGet(platformId)
    if (r && r.id) {
      setItem(r)
      setIsLoading(false)
    }
  }

  // async function onSubmit () {
  //   form.validateFields()
  //     .then(async (values) => {
  //       setIsUpdate(true)

  //       const r = await platformService.save(currentId, values)

  //       if (r && r.id) {
  //         notification.success({
  //           message: 'Update Platform successfully',
  //           description: 'Platform is updated successfully.'
  //         })
  //       } else {
  //         notification.error({
  //           message: 'Save Platform not successful',
  //           description: 'Platform is unable to save successfully.'
  //         })
  //       }

  //       setIsUpdate(false)
  //     })
  //     .catch(errorInfo => {
  //       notification.error({
  //         message: 'Save Platform not successful',
  //         description: 'Platform is unable to save successfully.'
  //       })

  //       setIsUpdate(false)
  //     })
  // }

  // async function onSubmitFailed ({ values, errorFields, outOfDate }) {
  //   notification.error({
  //     message: 'Save Platform not successful',
  //     description: 'Platform is unable to save successfully.'
  //   })

  //   setIsUpdate(false)
  // }

  function handleTabChange (index) {
    let tab = null
    if (isActive) {
      tab = ActiveTabList.find(e => e.tabId === parseInt(index))
    } else {
      tab = TabList.find(e => e.tabId === parseInt(index))
    }

    setCurrentTab(index)
    if (tab && tab.tabId) {
      history.replace(`/software/detail/${platformId}${tab.path}`)
    }
  }

  function onAllToggleChanged (e) {
    if (validator.isNotEmptyArray(item.permissionsList)) {
      for (let i = 0; i < item.permissionsList.length; i++) {
        const itm = item.permissionsList[i]
        form.setFieldsValue({[`active${itm.id}`]: e})
      }
    }
  }

  function getInfoTab () {
    return (
      <Form
        form={form}
        name='detail'
        // onFinish={onSubmit}
        // onFinishFailed={onSubmitFailed}
      >
        <Panel title='Details'>
          <FormItem
            {...formItemLayout}
            hasFeedback
            label='Name'
            name='name'
            initialValue={item.name}
            rules={[
              { min: 2, message: 'Name must be between 2 and 128 characters' },
              { max: 128, message: 'Name must be between 2 and 128 characters' },
              { required: true, message: 'Please enter name' },
              { whitespace: true, message: 'Please enter name' }
            ]}
          >
            <Input disabled placeholder={'Enter Classification Name'} />
          </FormItem>
          <FormItem
            {...formItemLayout}
            hasFeedback
            label='Description'
            name='description'
            initialValue={item.description}
            rules={[
              { min: 2, message: 'Description must be at least 2 characters' },
              { required: true, message: 'Please enter description' },
              { whitespace: true, message: 'Please enter description' }
            ]}
          >
            <TextArea disabled rows={1} autoSize placeholder={'Enter Classification Description'} />
          </FormItem>
          <FormItem
            {...formItemLayout}
            label='Active'
            name='active'
            initialValue={item.active}
            valuePropName='checked'
          >
            <Switch
              defaultChecked={item.active}
              disabled
              checkedChildren={'Enable'}
              unCheckedChildren={'Disable'}
            />
          </FormItem>
        </Panel>
      </Form>
    )
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb(platformId)} />

      <Page.Header title={isLoading ? `Software` : item.id ? `Software - ${item.name}` : `Software`}>
        <Space>
          {/* { currentTab === String(TabList[0].tabId)
            ? <Button shape='round' type='primary' loading={isUpdate} onClick={onSubmit}>{ isUpdate ? 'Saving' : 'Save'}</Button>
            : null } */}
          {/* <div onClick={history.goBack}>
            <Button shape='round' ghost type='primary'>Back</Button>
          </div> */}
        </Space>
      </Page.Header>

      <Page.ContentLoading isLoading={isLoading} isUpdate={isUpdate}>
        <Tabs
          defaultActiveKey={currentTab}
          onChange={handleTabChange}
        >
          {/* <TabPane tab='Platform Info' key='1'>
            { getInfoTab() }
          </TabPane> */}
          { isActive && hasAccess(OrgPermission.SOFTWARE.SETTINGS.READ)
            ? <TabPane tab='Settings' key='1'>
              { <Panel><DetailsInfo platformId={platformId} orgId={orgId} /></Panel> }
            </TabPane>
            : null }
          { isActive && hasAccess(OrgPermission.SOFTWARE.ADMIN_USER.LIST)
            ? <TabPane tab='Software Users' key='2'>
              <Panel><UserList platformId={platformId} /></Panel>
            </TabPane>
            : null }
          { isActive && hasAccess(OrgPermission.SOFTWARE.USER_ROLE.LIST)
            ? <TabPane tab='Software User Roles' key='3'>
              <Panel><RoleList platformId={platformId} /></Panel>
            </TabPane>
            : null }
        </Tabs>
      </Page.ContentLoading>
    </Page.Body>
  )
}

export default PlatformEdit
