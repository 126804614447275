import { queryString, request } from '../util'

const headers = { 'Content-Type': 'application/json' }
const urlsys = '/private/api/portal/sys/organisations'
const urlorg = '/private/api/portal/org/organisations'

export default {
  /** sys */
  add (values) {
    return request(urlsys, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${urlsys}/${id}`)
  },
  getPlatformSpecificDetails (orgId, platformId) {
    return request(`${urlsys}/platform/${orgId}/${platformId}`)
  },
  updatePlatformSpecificDetails (id, platformId, values) {
    return request(`${urlsys}/platform/${platformId}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  remove (id) {
    return request(`${urlsys}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${urlsys}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${urlsys}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  checkDuplicateOrg (values) {
    return request(`${urlsys}/check/duplicate${queryString.stringify(values)}`)
  },
  listPlatformByPage (page, size, filter, sorting, text) {
    return request(`${urlsys}/platform/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listUnsignedPlatform (id) {
    return request(`${urlsys}/platform-unsigned/${id}`)
  },
  addPlatform (id, values) {
    return request(`${urlsys}/platform-unsigned/${id}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  /** org */
  orgGet (id) {
    return request(`${urlorg}/${id}`)
  },
  orgSave (id, values) {
    return request(`${urlorg}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  orgListPlatformByPage (orgId, page, size, filter, sorting, text) {
    return request(`${urlorg}/platform/${orgId}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  orgGetPlatformSpecificDetails (orgId, platformId) {
    return request(`${urlorg}/platform/${orgId}/${platformId}`)
  },
  orgUpdatePlatformSpecificDetails (id, platformId, values) {
    return request(`${urlorg}/platform/${platformId}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
}
