import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input, notification, Pagination, Row, Col, Space, Skeleton, Spin, Switch, Tabs, Table } from 'antd'
import { CloseOutlined, CheckOutlined, CheckCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Page, Panel, Total } from '../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../constants'
import { platformService } from '../../../../../services'
import { auth, validator } from '../../../../../util'
import { SysStore } from '../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'

import RateSet from './RateSet/List'
import Categories from './Categories/List'
import Rates from './Rates/List'

const FormItem = Form.Item
const FormList = Form.List
const TextArea = Input.TextArea
const { TabPane } = Tabs

const pageSize = 20

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 5 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const PMTabList = [
  { tabId: 1, path: '/rate-sets' },
  { tabId: 2, path: '/categories' },
  { tabId: 3, path: '/rates' }
]

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.SoftwareRatesSoftwareList,
  Breadcrumb.SoftwareRatesListPM
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function PMRates ({match}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [item, setItem] = useState({})
  const [currentTab, setCurrentTab] = useState('1')

  const [form] = Form.useForm()
  const history = useHistory()
  const { params } = match
  const isEdit = params && params.id !== 'add' && params.id !== 'new'
  const isPM = params && params.id === '1'
  const currentId = params ? params.id : ''

  useEffect(() => {
    if (params.type) {
      const tab = PMTabList.find(e => e.path === params.type || e.path === `/${params.type}`)
      const cTab = tab && tab.tabId ? String(tab.tabId) : '1'

      setCurrentTab(cTab)
    } else {
      history.replace(`/softwares/rates/${currentId}${PMTabList[0].path}`)
    }

    fetchItem()
  }, [])

  async function fetchItem () {
    setIsLoading(true)
    const r = await platformService.get(currentId)
    if (r && r.id) {
      setItem(r)
      setIsLoading(false)
    }
  }

  function handleTabChange (index) {
    const tab = PMTabList.find(e => e.tabId === parseInt(index))

    setCurrentTab(index)
    if (tab && tab.tabId) {
      history.replace(`/softwares/rates/${currentId}${tab.path}`)
    }
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title={isLoading ? `Software - Rates` : item.id ? `Rates - ${item.name}` : `Software - Rates`}>
        <Space>
          <div onClick={history.goBack}>
            <Button shape='round' ghost type='primary'>Back</Button>
          </div>
        </Space>
      </Page.Header>

      <Page.ContentLoading loading={isLoading}>
        <Tabs
          activeKey={currentTab}
          onChange={handleTabChange}
        >
          { hasAccess(SysPermission.SOFTWARE.RATE_SET.LIST)
            ? <TabPane tab='Rate Sets' key='1'>
              <Panel><RateSet platformId={currentId} path={PMTabList[0].path} /></Panel>
            </TabPane>
            : null }
          { hasAccess(SysPermission.SOFTWARE.RATE_SET.LIST)
            ? <TabPane tab='Categories' key='2'>
              <Panel><Categories platformId={currentId} path={PMTabList[1].path} /></Panel>
            </TabPane>
            : null }
          { hasAccess(SysPermission.SOFTWARE.RATE_SET.LIST)
            ? <TabPane tab='Rates' key='3'>
              <Panel><Rates platformId={currentId} path={PMTabList[2].path} /></Panel>
            </TabPane>
            : null }
        </Tabs>
      </Page.ContentLoading>
    </Page.Body>
  )
}

export default PMRates
