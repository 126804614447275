import 'whatwg-fetch'

import { apiHostname, apiVersion, redirectHostname } from '../config'
import auth from './auth'
import localStorage from './localStorage'
import sessionStorage from './sessionStorage'

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request (url, options = {}) {
  if (url.startsWith('/private')) {
    const { headers = {} } = options
    options.headers = Object.assign({ 'Authorization': `Bearer ${auth.getCurrentToken()}` }, headers)
  }

  url = apiVersion + url

  async function handleError (response) {
    const { status } = response

    if ((status >= 200 && status < 300) || status === 401) {
      const jsonResponse = await response.json()
      return { response: jsonResponse }
    } else if (status === 400) {
      const error = new Error(`${response.status} ${response.statusText}`)
      const jsonResponse = await response.json()
      error.response = jsonResponse

      return error
    } else {
      throw response
    }
  }

  async function handleSession (response) {
    try {
      const jsonResponse = response.response
      const redirectToken = auth.getCurrentRedirectToken()

      if (jsonResponse.tokenExpired || jsonResponse.tokenRevoked || jsonResponse.unauthorized) {
        if (redirectToken) {
          // handling for sso login expiry handling
          const r1 = await fetch(`${apiHostname}${apiVersion}/api/portal/redirect/dive-out`, Object.assign({ credentials: 'same-origin' }, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({redirect_token: redirectToken})
          }))

          sessionStorage.setItem('tokenExpired', true)
          window.location.href = redirectHostname

          return {}
        } else {
          // handling for general expiry handling
          if (jsonResponse.tokenExpired) {
            sessionStorage.setItem('tokenExpired', true)
            window.location.href = `/login`
            return {}
          } else if (jsonResponse.tokenRevoked) {
            sessionStorage.setItem('tokenRevoked', true)
            window.location.href = `/login`
            return {}
          } else if (jsonResponse.unauthorized) {
            sessionStorage.setItem('unauthorized', true)
            window.location.href = `/login`
            return {}
          }
        }
      }

      return jsonResponse
    } catch (e) {
      console.error(e)
    }
  }

  async function handleRefresh (response) {
    try {
      if (response.tokenRefreshed) {
        const { token } = response
        const { headers = {} } = options
        localStorage.setItem('token', token)
        options.headers = Object.assign({ 'Authorization': `Bearer ${token}` }, headers)
        return fetch(`${apiHostname}${url}`, Object.assign({ credentials: 'same-origin' }, options))
          .then(handleError)
          .then(handleSession)
          .then(handleRefresh)
      }

      return response
    } catch (e) {
      console.error(e)
    }
  }

  return fetch(`${apiHostname}${url}`, Object.assign({ credentials: 'same-origin' }, options))
    .then(handleError)
    .then(handleSession)
    .then(handleRefresh)
}
