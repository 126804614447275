import { auth, localStorage, queryString, request } from '../util'

const headers = { 'Content-Type': 'application/json' }
const urlRedirect = '/api/portal/redirect'

export default {
  enter (values) {
    return request(`${urlRedirect}/dive-in`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  exit (values) {
    return request(`${urlRedirect}/dive-out`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
