import formatter from './formatter'
const storage = window.localStorage

export default {
  clear () {
    return storage.clear()
  },
  getItem (key) {
    const item = storage.getItem(key)
    return (item === 'null' || item === null) ? null : (item === 'undefined' || item === undefined) ? undefined : item
  },
  getObject (key, defaultValue) {
    defaultValue = defaultValue || {}
    try {
      const item = storage.getItem(key)
      return JSON.parse(item) || defaultValue
    } catch (e) {
      return defaultValue
    }
  },
  getBoolean (key, defaultValue) {
    defaultValue = defaultValue || null
    try {
      const item = storage.getItem(key)
      return formatter.toBoolean(item)
    } catch (e) {
      return defaultValue
    }
  },
  removeItem (key) {
    return storage.removeItem(key)
  },
  removeObject (key) {
    return storage.removeItem(key)
  },
  setItem (key, value) {
    return storage.setItem(key, value)
  },
  setObject (key, value) {
    return storage.setItem(key, JSON.stringify(value))
  }
}
