import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Modal, Table, notification, Pagination, Row, Col, Select, Spin, Space } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Loading, Page, Panel, Total } from '../../../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../../../constants'
import { platformPMRateService } from '../../../../../../../services'
import { auth, validator } from '../../../../../../../util'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20
const Option = Select.Option
const FormItem = Form.Item

const formItemLayout = {
  labelCol: { sm: 4, md: 4, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const columns = (platformId, path) => ([
  {
    title: 'Set',
    width: 7,
    dataIndex: 'rate_name',
    key: 'rate_name'
  },
  {
    title: 'Name',
    width: 11,
    dataIndex: 'rate_set_name',
    key: 'rate_set_name'
  },
  {
    title: 'Active',
    dataIndex: 'rate_set_active',
    key: 'rate_set_active',
    width: 2,
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#ccc', fontSize: '14pt'}} />
  },
  {
    title: 'Action',
    width: 1,
    render: (item) => <div className='action-buttons'>
      { hasAccess(SysPermission.SOFTWARE.RATE_SET.READ)
        ? <Link to={`/softwares/rates/${platformId}${path}/${item.id}`} key={`psrw${item.id}`}>
          <FormOutlined />
        </Link>
        : null }
      {/* <Tooltip mouseLeaveDelay={0} title='Remove Item'>
        <Popconfirm
          title='Confirm to remove this rate set?'
          onConfirm={() => this.removeItem(item)}
          okText='Yes'
          cancelText='No'
        >
          <Icon type='delete' style={{ marginTop: '2px', marginRight: 15 }} />
        </Popconfirm>
      </Tooltip> */}
    </div>
  },
])

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function PMRatesRSList (props) {
  const platformId = props.platformId
  const path = props.path
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState({})
  const [isListLoading, setIsListLoading] = useState(false)
  const [sort, setSort] = useState({})
  const [platformList, setPlatformList] = useState([])

  const [form] = Form.useForm()

  useEffect(() => {
    fetchList({ currentPage: 1 })
  }, [])

  async function fetchList ({ currentPage = page }) {
    if (!hasAccess(SysPermission.SOFTWARE.RATE_SET.LIST)) return

    setIsLoading(true)
    const r = await platformPMRateService.listRateSetByPage(currentPage, pageSize, filter, sort)
    if (r && r.list) {
      setIsLoading(false)
      setList(r.list)
      setPage(currentPage)
      setTotal(r.total)
    }
  }

  return (
    <div>
      <Page.Header title='Rate Sets'>
        { hasAccess(SysPermission.SOFTWARE.RATE_SET.CREATE)
          ? <Link to={`/softwares/rates/${platformId}${path}/add`}>
            <Button shape='round' type='primary' onClick={() => setShowModal(true)}>Add Rate Set</Button>
          </Link>
          : null }
      </Page.Header>

      <Page.Content>
        <Table
          loading={isLoading}
          columns={columns(platformId, path)}
          dataSource={list}
          size='small'
          rowKey={(itm) => (`prs${itm.id}`)}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total,
            onChange: (e) => fetchList({currentPage: e})
          }}
        />
        <Total total={total} title={'Rate Set'} />
      </Page.Content>
    </div>
  )
}

export default PMRatesRSList
