import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Page } from '../../components'
import { dashboardService } from '../../services'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../constants'
import { auth } from '../../util'

import 'antd/dist/antd.css'
import './styles.css'

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.HomeDashboard,
]

function Dashboard () {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchDashboard()
  }, [])

  async function fetchDashboard () {
    // if (!hasAccess(SysPermission.MEMBER.INFO.LIST)) return

    setIsLoading(true)
    let r = null
    const mode = auth.getMode()

    if (parseInt(mode) === 0) {
      r = await dashboardService.get()
    } else {
      r = await dashboardService.orgGet()
    }

    if (r && r.list) {
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title='Dashboard'>
      </Page.Header>

      <Page.ContentLoading isLoading={isLoading}>
        <div className='Dashboard-summary'>
          <div className='Dashboard-summary-col' style={{ backgroundColor: '#FEDBA6', color: '#FDA602' }}>
            0.00
          </div>
          <div className='Dashboard-summary-col' style={{ backgroundColor: '#FFC6CA', color: '#FF737B' }}>
            0.00
          </div>
          <div className='Dashboard-summary-col' style={{ backgroundColor: '#A9E6DF', color: '#1EC1B0' }}>
            0.00
          </div>
          <div className='Dashboard-summary-col' style={{ backgroundColor: '#B4CEFB', color: '#4285F4' }}>
            0.00
          </div>
        </div>
      </Page.ContentLoading>
    </Page.Body>
  )
}

export default Dashboard
