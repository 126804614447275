/** Contents Portal */
import Dashboard from './modules/Dashboard'
import {
  MemberList,
  MemberEdit
} from './modules/Contents/Member'
import {
  OrgList,
  OrgPage,
  OrgAdminRolePage,
  OrgPlatformDetailsPage
} from './modules/Contents/Organisation'
import {
  SystemAdminUserList,
  SystemModuleList,
  SystemModuleEdit,
  SystemModuleOrgList,
  SystemModuleOrgEdit,
  SystemAdminRoleList,
  SystemAdminRoleEdit
} from './modules/Contents/System'
import {
  SoftwareList,
  SoftwarePage,
  SoftwareModuleList,
  SoftwareModulePage,
  SoftwareUserList,
  SoftwareRoleList,
  SoftwareRolePage,
  SoftwareRateList,
  SoftwareRatePage,
  SoftwareRateCategoryPage,
  SoftwareRateRatesPage,
  SoftwareRateSetPage
} from './modules/Contents/Software'
/** Contents Org */
import {
  MemberOrgEdit,
  MemberOrgList,
  MemberOrgRoleList,
  MemberOrgRoleEdit
} from './modules/ContentsOrg/Members'
import {
  PortalOrgPlatformList,
  PortalPlatformList,
  PortalPlatformPage,
  PortalPlatformUserPage,
  PortalPlatformRolePage
} from './modules/ContentsOrg/Portal'
import {
  OrgOrgPage,
  // OrgOrgPlatformDetailsPage
} from './modules/ContentsOrg/Organisation'

export const routes = [
  {
    component: Dashboard,
    exact: true,
    name: 'Dashboard',
    path: '/'
  },
  /** org route */
  {
    component: MemberOrgList,
    exact: true,
    name: 'Acc. Admins',
    path: '/member'
  },
  {
    component: MemberOrgList,
    exact: true,
    name: 'Acc. Admins',
    path: '/member/listing'
  },
  {
    component: MemberOrgEdit,
    exact: true,
    name: 'Acc. Admins',
    path: '/member/listing/:id/:type'
  },
  {
    component: MemberOrgEdit,
    exact: true,
    name: 'Acc. Admins',
    path: '/member/listing/:id'
  },
  {
    component: MemberOrgRoleList,
    exact: true,
    name: 'Acc. Admins',
    path: '/member/roles'
  },
  {
    component: MemberOrgRoleEdit,
    exact: true,
    name: 'Acc. Admins',
    path: '/member/roles/:id'
  },
  {
    component: MemberOrgRoleEdit,
    exact: true,
    name: 'Acc. Admins',
    path: '/member/roles/:id/:type'
  },
  {
    component: OrgOrgPage,
    exact: true,
    name: 'Organisation',
    path: '/organisation/:id/:type'
  },
  // {
  //   component: OrgOrgPlatformDetailsPage,
  //   exact: true,
  //   name: 'Organisation',
  //   path: '/organisation/:id/platform-specific/:pId'
  // },
  {

    component: PortalOrgPlatformList,
    exact: true,
    name: 'Software Subscribed',
    path: '/software/listing'
  },
  {
    component: PortalPlatformPage,
    exact: true,
    name: 'Software',
    path: '/software/detail/:id'
  },
  {
    component: PortalPlatformPage,
    exact: true,
    name: 'Software',
    path: '/software/detail/:id/:type'
  },
  {
    component: PortalPlatformUserPage,
    exact: true,
    name: 'Software',
    path: '/software/detail/:id/admin-users/:mId'
  },
  {
    component: PortalPlatformRolePage,
    exact: true,
    name: 'Software',
    path: '/software/detail/:id/roles/:rId'
  },
  /** sys route */
  {
    component: MemberList,
    exact: true,
    name: 'Members',
    path: '/members'
  },
  {
    component: MemberList,
    exact: true,
    name: 'Members',
    path: '/members/listing'
  },
  {
    component: MemberEdit,
    exact: true,
    name: 'Members',
    path: '/members/listing/:id/:type'
  },
  {
    component: MemberEdit,
    exact: true,
    name: 'Members',
    path: '/members/listing/:id'
  },
  {
    component: OrgList,
    exact: true,
    name: 'Organisations',
    path: '/organisations'
  },
  {
    component: OrgList,
    exact: true,
    name: 'Organisations',
    path: '/organisations/listing'
  },
  {
    component: OrgPage,
    exact: true,
    name: 'Organisations',
    path: '/organisations/listing/:id'
  },
  {
    component: OrgPage,
    exact: true,
    name: 'Organisations',
    path: '/organisations/listing/:id/:type'
  },
  {
    component: OrgPlatformDetailsPage,
    exact: true,
    name: 'Organisations',
    path: '/organisations/listing/:id/platform-specific/:pId'
  },
  {
    component: OrgAdminRolePage,
    exact: true,
    name: 'Organisations',
    path: '/organisations/listing/:id/roles/:pId'
  },
  {
    component: SystemAdminUserList,
    exact: true,
    name: 'Portal Admins',
    path: '/systems/admin-users'
  },
  {
    component: SystemModuleList,
    exact: true,
    name: 'Portal Admin Modules',
    path: '/systems/modules'
  },
  {
    component: SystemModuleEdit,
    exact: true,
    name: 'Portal Admin Modules',
    path: '/systems/modules/:id'
  },
  {
    component: SystemModuleOrgList,
    exact: true,
    name: 'Portal Org Modules',
    path: '/systems/modules-org'
  },
  {
    component: SystemModuleOrgEdit,
    exact: true,
    name: 'Portal Org Modules',
    path: '/systems/modules-org/:id'
  },
  {
    component: SystemAdminRoleList,
    exact: true,
    name: 'Portal User Roles',
    path: '/systems/roles'
  },
  {
    component: SystemAdminRoleEdit,
    exact: true,
    name: 'Portal User Role Edit',
    path: '/systems/roles/:id'
  },
  {
    component: SoftwareList,
    exact: true,
    name: 'Software List',
    path: '/softwares/listing'
  },
  {
    component: SoftwarePage,
    exact: true,
    name: 'Software Edit',
    path: '/softwares/listing/:id'
  },
  {
    component: SoftwarePage,
    exact: true,
    name: 'Software Edit',
    path: '/softwares/listing/:id/:type'
  },
  {
    component: SoftwareModulePage,
    exact: true,
    name: 'Software Module Edit',
    path: '/softwares/listing/:id/modules/:mId'
  },
  {
    component: SoftwareRolePage,
    exact: true,
    name: 'Software Role Edit',
    path: '/softwares/listing/:id/roles/:pId'
  },
  {
    component: SoftwareRateList,
    exact: true,
    name: 'Rates - Select Software',
    path: '/softwares/rates-select'
  },
  {
    component: SoftwareRatePage,
    exact: true,
    name: 'Rates',
    path: '/softwares/rates/:id'
  },
  {
    component: SoftwareRatePage,
    exact: true,
    name: 'Rates',
    path: '/softwares/rates/:id/:type'
  },
  {
    component: SoftwareRateCategoryPage,
    exact: true,
    name: 'Category',
    path: '/softwares/rates/:id/categories/:cId'
  },
  {
    component: SoftwareRateRatesPage,
    exact: true,
    name: 'Rate',
    path: '/softwares/rates/:id/rates/:rId'
  },
  {
    component: SoftwareRateSetPage,
    exact: true,
    name: 'Rate Set',
    path: '/softwares/rates/:id/rate-sets/:rId'
  },
  // {
  //   component: SystemModuleList,
  //   exact: true,
  //   name: 'Portal Admin Modules',
  //   path: '/systems/modules'
  // },
  // {
  //   component: SystemModuleEdit,
  //   exact: true,
  //   name: 'Portal Admin Modules',
  //   path: '/systems/modules/:id'
  // },
  // {
  //   component: SystemModuleOrgList,
  //   exact: true,
  //   name: 'Portal Org Modules',
  //   path: '/systems/modules-org'
  // },
  // {
  //   component: SystemModuleOrgEdit,
  //   exact: true,
  //   name: 'Portal Org Modules',
  //   path: '/systems/modules-org/:id'
  // },
  // {
  //   component: SystemAdminRoleList,
  //   exact: true,
  //   name: 'User Roles',
  //   path: '/systems/roles'
  // },
  // {
  //   component: SystemAdminRoleEdit,
  //   exact: true,
  //   name: 'Portal Modules',
  //   path: '/systems/roles/:id'
  // },
  // {
  //   component: SystemAdminUserList,
  //   exact: true,
  //   name: 'Portal Admins',
  //   path: '/systems/admin-users'
  // },
  // {
  //   component: MemberEdit,
  //   exact: true,
  //   name: 'Portal Admins Edit',
  //   path: '/systems/admin-users/:id'
  // },
  // {
  //   component: SystemClassificationList,
  //   exact: true,
  //   name: 'Platform',
  //   path: '/systems/platform'
  // },
  // {
  //   component: SystemClassificationPage,
  //   exact: true,
  //   name: 'Platform',
  //   path: '/systems/platform/:id'
  // },
  // {
  //   component: SystemClassificationPage,
  //   exact: true,
  //   name: 'Platform',
  //   path: '/systems/platform/:id/:type'
  // },
  // {
  //   component: SystemClassificationModulePage,
  //   exact: true,
  //   name: 'Platform',
  //   path: '/systems/platform/:id/:type/:moduleId'
  // },
  // {
  //   component: SystemClassificationModulePage,
  //   exact: true,
  //   name: 'Platform',
  //   path: '/systems/platform/:id/roles/:moduleId'
  // }
]
