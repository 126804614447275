import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Table, Pagination, Row, Col, Space } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Loading, Page, Panel, Total } from '../../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../../constants'
import { memberService } from '../../../../../../services'
import { auth, validator } from '../../../../../../util'
import { MemberStore } from '../../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 6
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    width: 4
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 5
  },
  {
    title: 'Organisation Role',
    dataIndex: 'system_org_role_name',
    key: 'org_role_name',
    width: 5
  },
  {
    title: 'Active',
    dataIndex: 'system_org_active',
    key: 'org_active',
    width: 1,
    render: (active) => active
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#ccc', fontSize: '14pt'}} />
  },
  {
    width: 1,
    render: (item) => <Space size='middle'>
      { hasAccess(SysPermission.MEMBER.INFO.READ)
        ? <Link to={`/members/listing/${item.id}`} key={`memberkey${item.id}`}>
          <FormOutlined />
        </Link>
        : null }

    </Space>
  },
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function OrgMemberList (props) {
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState({ system_org_type_id: props.orgId, system_org_access_id: {condition: 'is not null'} })
  const [isMemberListLoading, setIsMemberListLoading] = useState(false)
  const [sort, setSort] = useState({})

  useEffect(() => {
    fetchList({ currentPage: 1 })
  }, [])

  async function fetchList ({ currentPage = page }) {
    if (!hasAccess(SysPermission.ORGANISATIONS.PORTAL_USER.LIST)) return

    setIsLoading(true)
    const r = await memberService.listByPage(currentPage, pageSize, filter, sort)
    if (r && r.list) {
      setIsLoading(false)
      setList(r.list)
      setPage(currentPage)
      setTotal(r.total)
    }
  }

  return (
    <div>
      <Page.Header title='Admin Users'>
        { hasAccess(SysPermission.MEMBER.INFO.CREATE)
          ? <Link to='/members/listing/add'>
            <Button shape='round' type='primary'>Add Member</Button>
          </Link>
          : null }
      </Page.Header>

      <Page.Content>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={list}
          size='small'
          rowKey={(itm) => (`memitm${itm.id}`)}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total,
            onChange: (e) => fetchList({currentPage: e})
          }}
        />
        <Total total={total} title={'member'} />
      </Page.Content>
    </div>
  )
}

export default OrgMemberList
