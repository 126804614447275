export default {
  MEMBER: {
    INFO: {
      LIST: 'listMember',
      READ: 'readMember',
      CREATE: 'createMember',
      UPDATE: 'updateMember',
      DELETE: 'deleteMember'
    },
    LOGIN: {
      LIST: 'listMemberLoginSettings',
      READ: 'readMemberLoginSettings',
      CREATE: 'createMemberLoginSettings',
      UPDATE: 'updateMemberLoginSettings',
      DELETE: 'deleteMemberLoginSettings'
    }
  },
  ORGANISATIONS: {
    INFO: {
      LIST: 'listOrganisations',
      READ: 'readOrganisations',
      CREATE: 'createOrganisations',
      UPDATE: 'updateOrganisations',
      DELETE: 'deleteOrganisations'
    },
    PORTAL_USER: {
      LIST: 'listOrganisationsAdminUsers',
      READ: 'readOrganisationsAdminUsers',
      CREATE: 'createOrganisationsAdminUsers',
      UPDATE: 'updateOrganisationsAdminUsers',
      DELETE: 'deleteOrganisationsAdminUsers'
    },
    PORTAL_ROLES: {
      LIST: 'listOrganisationsUserRoles',
      READ: 'readOrganisationsUserRoles',
      CREATE: 'createOrganisationsUserRoles',
      UPDATE: 'updateOrganisationsUserRoles',
      DELETE: 'deleteOrganisationsUserRoles'
    },
    SFT_MGMT: {
      LIST: 'listOrganisationsSoftwareManagement',
      READ: 'readOrganisationsSoftwareManagement',
      CREATE: 'createOrganisationsSoftwareManagement',
      UPDATE: 'updateOrganisationsSoftwareManagement',
      DELETE: 'deleteOrganisationsSoftwareManagement'
    },
    SFT_DETAILS: {
      LIST: 'listOrganisationsSoftwareDetails',
      READ: 'readOrganisationsSoftwareDetails',
      CREATE: 'createOrganisationsSoftwareDetails',
      UPDATE: 'updateOrganisationsSoftwareDetails',
      DELETE: 'deleteOrganisationsSoftwareDetails'
    },
  },
  PORTAL: {
    SYS_USER: {
      LIST: 'listPortalAdminUsers',
      READ: 'readPortalAdminUsers',
      CREATE: 'createPortalAdminUsers',
      UPDATE: 'updatePortalAdminUsers',
      DELETE: 'deletePortalAdminUsers'
    },
    SYS_ROLES: {
      LIST: 'listPortalUserRoles',
      READ: 'readPortalUserRoles',
      CREATE: 'createPortalUserRoles',
      UPDATE: 'updatePortalUserRoles',
      DELETE: 'deletePortalUserRoles'
    },
    SYS_MODULE_PMS: {
      LIST: 'listPortalAdminModulePermission',
      READ: 'readPortalAdminModulePermission',
      CREATE: 'createPortalAdminModulePermission',
      UPDATE: 'updatePortalAdminModulePermission',
      DELETE: 'deletePortalAdminModulePermission'
    },
    SYS_ORG_MODULE_PMS: {
      LIST: 'listPortalOrgAdminModulePermission',
      READ: 'readPortalOrgAdminModulePermission',
      CREATE: 'createPortalOrgAdminModulePermission',
      UPDATE: 'updatePortalOrgAdminModulePermission',
      DELETE: 'deletePortalOrgAdminModulePermission'
    },
  },
  SOFTWARE: {
    INFO: {
      LIST: 'listSoftware',
      READ: 'readSoftware',
      CREATE: 'createSoftware',
      UPDATE: 'updateSoftware',
      DELETE: 'deleteSoftware'
    },
    ADMIN_USER: {
      LIST: 'listSoftwareAdminUsers',
      READ: 'readSoftwareAdminUsers',
      CREATE: 'createSoftwareAdminUsers',
      UPDATE: 'updateSoftwareAdminUsers',
      DELETE: 'deleteSoftwareAdminUsers'
    },
    ADMIN_ROLES: {
      LIST: 'listSoftwareUserRoles',
      READ: 'readSoftwareUserRoles',
      CREATE: 'createSoftwareUserRoles',
      UPDATE: 'updateSoftwareUserRoles',
      DELETE: 'deleteSoftwareUserRoles'
    },
    MODULE_PMS: {
      LIST: 'listSoftwareModulePermission',
      READ: 'readSoftwareModulePermission',
      CREATE: 'createSoftwareModulePermission',
      UPDATE: 'updateSoftwareModulePermission',
      DELETE: 'deleteSoftwareModulePermission'
    },
    RATE_SET: {
      LIST: 'listSoftwareRateSets',
      READ: 'readSoftwareRateSets',
      CREATE: 'createSoftwareRateSets',
      UPDATE: 'updateSoftwareRateSets',
      DELETE: 'deleteSoftwareRateSets'
    },
    RATE_SET_IMPORT: {
      LIST: 'listSoftwareRateSetImport',
      READ: 'readSoftwareRateSetImport',
      CREATE: 'createSoftwareRateSetImport',
      UPDATE: 'updateSoftwareRateSetImport',
      DELETE: 'deleteSoftwareRateSetImport'
    },

  }
}
