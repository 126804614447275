export default {
  HomeBase: { label: 'Home', link: '/' },
  MemberBase: { label: 'Admin', link: '/member' },
  MemberList: { label: 'List', link: '/member/listing' },
  MemberEdit: { label: 'Edit', link: '' },
  MemberRoleList: { label: 'Admin Roles', link: '/member/roles' },
  MemberRoleEdit: { label: 'Role Edit', link: '' },
  OrgBase: { label: 'Account Info', link: '/organisation' },
  OrgEdit: { label: 'Edit', link: '' },
  PortalAdminList: { label: 'Admins', link: '/portal/admin-users' },
  PortalAdminPage: { label: 'Edit', link: '' },
  PortalPlatform: { label: 'Software', link: '' },
  PortalPlatformList: { label: 'List', link: '' },
  PortalPlatformPM: { label: 'Pumpkin PM', link: '' },
  PortalPlatformSC: { label: 'Pumpkin SC', link: '' },
  PortalPlatformPMEdit: { label: 'Edit', link: '/software/platform/1/info' },
  PortalPlatformSCEdit: { label: 'Edit', link: '/software/platform/2/info' },
  PortalPlatformPMUserEdit: { label: 'User Edit', link: '' },
  PortalPlatformPMRoleEdit: { label: 'Role Edit', link: '' }
}
