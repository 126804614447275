import moment from 'moment-timezone'
import numeral from 'numeral'
import * as geolib from 'geolib'
import validator from './validator'

moment.tz.setDefault('Australia/Melbourne')

export default {
  capitalize (str, toLowerCase = true) {
    if (typeof str === 'string') {
      const words = str.split(' ')
      return words.map((word) => word.charAt(0).toUpperCase() + (toLowerCase ? word.substring(1).toLowerCase() : word.substring(1))).join(' ')
    }

    return ''
  },
  round (number) {
    if (typeof number === 'number') {
      return Math.round(number * 100) / 100
    }

    return 0
  },
  toBoolean (str) {
    let regex = /^\s*(true|1|on)\s*$/i
    return regex.test(str)
  },
  toClassName (arr) {
    if (arr && arr.length > 0) {
      return arr.filter((str) => typeof str === 'string' && str).join(' ')
    }

    return ''
  },
  toMoment (str) {
    if (str) {
      return moment(str)
    }

    return moment(new Date())
  },
  toDate (str, format) {
    if ((typeof str === 'string' || str) && typeof format === 'string') {
      return moment(str).format(format)
    }

    return ''
  },
  toNumber (number) {
    const num = numeral(number)
    return num.value()
  },
  toDecimal (number) {
    if (typeof number === 'number') {
      return `${numeral(number).format('0,0.00')}`
    } else if (!isNaN(parseFloat(number))) {
      return `${numeral(number).format('0,0.00')}`
    }

    return 0
  },
  toDecimalS (number) {
    if (typeof number === 'number') {
      return `${numeral(number).format('0,0.[00]')}`
    } else if (!isNaN(parseFloat(number))) {
      return `${numeral(number).format('0,0.[00]')}`
    }

    return 0
  },
  toPercentage (value, decimal = 1) {
    return value ? Number(Math.round(value + `e${decimal}`) + `e-${decimal}`) + '%' : '0%'
  },
  toPrice (number, currency = 'RM') {
    if (typeof number === 'number') {
      return `${currency} ${numeral(number).format('0,0.00')}`
    }

    return 0
  },
  toShortDate (str) {
    return this.toDate(str, 'DD/MM/YYYY')
  },
  toDay (str) {
    return this.toDate(str, 'dddd')
  },
  toStandardDate (str) {
    return this.toDate(str, 'DD/MM/YYYY hh:mm A')
  },
  toStandardLongDate (str) {
    return this.toDate(str, 'DD/MM/YYYY hh:mm:ss A')
  },
  toShortTime (str) {
    return this.toDate(str, 'hh:mm A')
  },
  toIcon (mediaType, defaultIcon) {
    if (typeof mediaType === 'string') {
      if (mediaType.startsWith('audio')) {
        return '/icon/audio.svg'
      } else if (mediaType.startsWith('video')) {
        return '/icon/video.svg'
      } else {
        switch (mediaType) {
          case 'application/pdf':
            return '/icon/pdf.svg'
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
          case 'application/vnd.ms-word.document.macroEnabled.12':
          case 'application/vnd.ms-word.template.macroEnabled.12':
            return '/icon/doc.svg'
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
          case 'application/vnd.ms-excel.sheet.macroEnabled.12':
          case 'application/vnd.ms-excel.template.macroEnabled.12':
          case 'application/vnd.ms-excel.addin.macroEnabled.12':
          case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
            return '/icon/xls.svg'
          case 'application/vnd.ms-powerpoint':
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          case 'application/vnd.openxmlformats-officedocument.presentationml.template':
          case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
          case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
          case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
          case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
            return '/icon/ppt.svg'
          case 'image/jpeg':
            return '/icon/jpg.svg'
          case 'image/png':
            return '/icon/png.svg'
          default:
            return '/icon/file.svg'
        }
      }
    }

    return defaultIcon || '/icon/resource.svg'
  },
  duration (date) {
    const duration = moment.duration(moment().endOf('days').diff(moment(date).endOf('days')))
    return duration.asDays().toFixed(0)
  },
  expiringDuration (date) {
    const duration = moment.duration(moment(date).endOf('days').diff(moment().endOf('days')))
    return duration.asDays().toFixed(0)
  },
  toStandardFileName (str) {
    const fileNameIndex = str.lastIndexOf('_')
    const fileExtIndex = str.lastIndexOf('.')
    const name = decodeURI(str.substring(0, fileNameIndex))
    const extName = str.substring(fileExtIndex)
    return name + extName
  },
  toShortenText (str, range = 30) {
    let length = str.length
    let index = str.indexOf(' ', range)
    index = index === -1 ? length : index
    return length <= range ? str : str.substring(0, index) + (index === length ? '' : '...')
  },
  toHtmlLineBreak (str) {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
  },
  toDistance (radius = 0) {
    let r1 = parseFloat(radius)

    if (r1 > 1000) {
      r1 = r1 / 1000
      return `${this.toDecimalS(r1)} km`
    } else {
      return `${this.toDecimalS(r1)} m`
    }
  },
  updateMapDetails (clientCoords, signoffCoords, radius = 0) {
    let d = 0
    let c = {latitude: -37.8829427, longitude: 145.1599169}
    let dis
    let ctr = {latitude: NaN, longitude: NaN}
    let zm
    if (clientCoords && signoffCoords) {
      dis = geolib.getDistance(clientCoords, signoffCoords)
      ctr = geolib.getCenter([
        clientCoords, signoffCoords
      ])
      zm = isNaN(dis)
        ? 14
        : (dis > 1000000)
          ? 2
          : (dis > 100000)
            ? 5
            : (dis > 10000)
              ? 8
              : (dis > 1000 || parseInt(radius) > 500)
                ? 12
                : parseInt(radius) > 100
                  ? 15.5
                  : 16
    }

    return { distance: isNaN(dis) ? d : (dis || d), center: isNaN(ctr.latitude) || isNaN(ctr.latitude) ? c : ctr, zoom: zm }
  },
  updateJobTimeDiff (jobEndDate, signoffDate) {
    const d2 = moment.isMoment(jobEndDate) ? jobEndDate : moment(jobEndDate)
    const now = moment.isMoment(signoffDate) ? signoffDate : moment(signoffDate)

    return d2.diff(now, 'minutes')
  },
  toTimeCountText (minutes, textMinus = '', textPlus = '') {
    let day = 0
    let hour = 0
    let min = 0

    const fMinutes = Math.abs(minutes)
    min = fMinutes % 60
    hour = Math.floor(fMinutes / 60)
    day = Math.floor(fMinutes / 1440)

    const prefixText = (!textMinus && !textPlus) ? '' : minutes === 0 ? '' : minutes > 0 ? textPlus : textMinus

    return `${day > 0 ? `${day} Day${day === 1 ? '' : 's'} ` : ''}${hour > 0 ? `${hour} Hour${hour === 1 ? '' : 's'} ` : ''}${min > 0 ? `${min} Min${min === 1 ? '' : 's'} ` : ''}${prefixText}`
  },
  toDurationText (date1, date2, timeFormat = 'hh:mm A', dateFormat = 'DD/MM/YYYY') {
    const d1 = moment.isMoment(date1) ? date1 : moment(date1)
    const d2 = moment.isMoment(date2) ? date2 : moment(date2)

    const isSameDay = d1.isSame(d2, 'day')
    const diffDay = d1.diff(d2, 'day')

    const d1Text = `${d1.format(dateFormat)} ${d1.format(timeFormat)}`
    const d2Text = isSameDay ? `${d2.format(timeFormat)}` : `${d2.format(dateFormat)} ${d2.format(timeFormat)}`

    if (d1.isValid() && d2.isValid()) {
      return `${d1Text} - ${d2Text}`
    } else if (d1.isValid() && !d2.isValid()) {
      return `from ${d1Text}`
    } else if (!d1.isValid() && d2.isValid()) {
      return `until ${d2Text}`
    } else {
      return ''
    }
  },
  roundKMS (k) {
    let kms = parseFloat(k)
    let val = k
    if (!isNaN(kms)) {
      let r1 = kms * 100
      let r2 = r1 % 100

      if (r2 === 0) {
        val = r1 / 100
      } else if (r2 <= 50) {
        const balance = 50 - r2
        val = (r1 + balance) / 100
      } else {
        const balance = 100 - r2
        val = (r1 + balance) / 100
      }
    } else {
      console.log('round kms error', k)
      val = 0
    }

    return val
  },
  toErrorMessage (r) {
    if (validator.isNotEmptyArray(r)) {
      return r.map(({message}) => message).join('\n')
    } else if (r && r.errors && validator.isNotEmptyArray(r.errors)) {
      return r.errors.map(({message}) => message).join('\n')
    }
    return ''
  },
}
