import { queryString, request } from '../util'

const headers = { 'Content-Type': 'application/json' }
const urlsysRatesPM = '/private/api/portal/sys/pm-rates/rates'
const urlsysRateSetPM = '/private/api/portal/sys/pm-rates/rate-sets'
const urlsysCatsPM = '/private/api/portal/sys/pm-rates/rate-cats'

export default {
  /** rates */
  addRate (values) {
    return request(urlsysRatesPM, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getRate (id) {
    return request(`${urlsysRatesPM}/${id}`)
  },
  removeRate (id) {
    return request(`${urlsysRatesPM}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  saveRate (id, values) {
    return request(`${urlsysRatesPM}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listRateByPage (page, size, filter, sorting, text) {
    return request(`${urlsysRatesPM}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  /** rate sets */
  addRateSet (values) {
    return request(urlsysRateSetPM, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getRateSet (id) {
    return request(`${urlsysRateSetPM}/${id}`)
  },
  removeRateSet (id) {
    return request(`${urlsysRateSetPM}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  saveRateSet (id, values) {
    return request(`${urlsysRateSetPM}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listRateSetByPage (page, size, filter, sorting, text) {
    return request(`${urlsysRateSetPM}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getAllActiveRateSets (rateId, setId) {
    return request(`${urlsysRateSetPM}/active/${rateId}/${setId}`)
  },
  startImportCsv (id, values) {
    return request(`${urlsysRateSetPM}/import/${id}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  /** categories */
  addCat (values) {
    return request(urlsysCatsPM, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getCat (id) {
    return request(`${urlsysCatsPM}/${id}`)
  },
  removeCat (id) {
    return request(`${urlsysCatsPM}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  saveCat (id, values) {
    return request(`${urlsysCatsPM}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listCatsByPage (page, size, filter, sorting, text) {
    return request(`${urlsysCatsPM}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
}
