import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Table, Pagination, Row, Col, Space } from 'antd'
import { CheckCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Loading, Page, Panel, Total } from '../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../constants'
import { sysModuleService } from '../../../../../services'
import { auth, validator } from '../../../../../util'
import { SysStore } from '../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.SystemBase,
  Breadcrumb.SystemModuleList
]

const columns = [
  {
    title: 'Module Name',
    dataIndex: 'name',
    key: 'name',
    width: 8
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 11
  },
  {
    width: 3,
    render: (item) => <Space size='middle'>
      { hasAccess(SysPermission.PORTAL.SYS_MODULE_PMS.READ)
        ? <Link to={`/systems/modules/${item.id}`} key={`modulekey${item.id}`}>
          <FormOutlined />
        </Link>
        : null }
    </Space>
  },
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function SystemModuleList () {
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState({ active: true })
  const [sort, setSort] = useState({})

  useEffect(() => {
    fetchList({ currentPage: 1 })
  }, [])

  const list = SysStore.useState(e => e.sysModuleList)
  const page = SysStore.useState(e => e.sysModulePage)
  const total = SysStore.useState(e => e.sysModuleTotal)
  const isSysModuleListLoading = SysStore.useState(e => e.isSysModuleListLoading)

  async function fetchList ({ currentPage = page }) {
    if (!hasAccess(SysPermission.PORTAL.SYS_MODULE_PMS.LIST)) return

    setIsLoading(true)
    const r = await sysModuleService.listByPage(currentPage, pageSize, filter, sort)
    if (r && r.list) {
      setIsLoading(false)
      SysStore.update(e => {
        e.sysModuleList = r.list
        e.sysModuleTotal = r.total
        e.sysModulePage = currentPage
      })
    }
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title='Modules & Permissions (Portal Admin)'>
        { hasAccess(SysPermission.PORTAL.SYS_MODULE_PMS.CREATE)
          ? <Link to='/systems/modules/add'>
            <Button shape='round' type='primary'>Add Modules</Button>
          </Link>
          : null }

      </Page.Header>

      <Page.Content>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={list}
          size='small'
          rowKey={(itm) => (`lcpms${itm.id}`)}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total,
            onChange: (e) => fetchList({currentPage: e})
          }}
        />
        <Total total={total} title={'module'} />
      </Page.Content>
    </Page.Body>
  )
}

export default SystemModuleList
