import { queryString, request } from '../util'

const headers = { 'Content-Type': 'application/json' }
const urlsys = '/private/api/portal/sys/members'
const urlorg = '/private/api/portal/org/members'

export default {
  /** sys */
  add (values) {
    return request(`${urlsys}/detail`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${urlsys}/detail/${id}`)
  },
  remove (id) {
    return request(`${urlsys}/detail/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${urlsys}/detail/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${urlsys}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  checkDuplicateMember (values) {
    return request(`${urlsys}/check/duplicate${queryString.stringify(values)}`)
  },
  getLogin (id) {
    return request(`${urlsys}/check/login/${id}`)
  },
  getExMembers (type, typeId) {
    return request(`${urlsys}/ex/list-all/${type}/${typeId}`)
  },
  addExMembers (values) {
    return request(`${urlsys}/ex/add`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  /** org */
  orglistByPage (page, size, filter, sorting, text) {
    return request(`${urlorg}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  orgAdd (values) {
    return request(`${urlorg}/detail`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  orgGet (id, type) {
    return request(`${urlorg}/detail/${id}/${type}`)
  },
  orgRemove (id) {
    return request(`${urlorg}/detail/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  orgSave (id, values) {
    return request(`${urlorg}/detail/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  orgDuplicateMember (values) {
    return request(`${urlorg}/check/duplicate${queryString.stringify(values)}`)
  },
  orgGetExMembers (type, typeId) {
    return request(`${urlorg}/ex/list-all/${type}/${typeId}`)
  },
  orgAddExMembers (values) {
    return request(`${urlorg}/ex/add`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
