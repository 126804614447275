import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Modal, Table, notification, Pagination, Row, Col, Select, Spin, Space } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Loading, Page, Panel, Total } from '../../../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../../../constants'
import { platformPMRateService } from '../../../../../../../services'
import { auth, formatter, validator } from '../../../../../../../util'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20
const Option = Select.Option
const FormItem = Form.Item

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.SoftwareRatesSoftwareList,
  Breadcrumb.SoftwareRatesListPM,
  Breadcrumb.SoftwareRatesEditCatPM
]

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const columns = [
  {
    title: 'Name',
    // width: 10,
    dataIndex: 'cat_item_name',
    key: 'cat_item_name'
  },
  {
    title: 'Support Item Number',
    // width: 5,
    dataIndex: 'cat_item_identifier',
    key: 'cat_item_identifier'
  },
  {
    title: 'Unit',
    // width: 2,
    dataIndex: 'unit',
    key: 'unit',
    render: (unit) => {
      return formatter.capitalize(unit, false)
    }
  },
  {
    title: 'Quote?',
    // width: 1,
    dataIndex: 'is_quote',
    key: 'is_quote',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#EC7063', fontSize: '14pt'}} />
  },
  {
    title: 'Price Limit?',
    // width: 1,
    dataIndex: 'is_price_limit',
    key: 'is_price_limit',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#EC7063', fontSize: '14pt'}} />
  },
  {
    title: 'Non-face-to-face?',
    // width: 1,
    dataIndex: 'is_nf2f',
    key: 'is_nf2f',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#EC7063', fontSize: '14pt'}} />
  },
  {
    title: 'Provider Travel?',
    // width: 1,
    dataIndex: 'is_provider_travel',
    key: 'is_provider_travel',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#EC7063', fontSize: '14pt'}} />
  },
  {
    title: 'Short Notice Cancellations?',
    // width: 1,
    dataIndex: 'is_short_notice_cancel',
    key: 'is_short_notice_cancel',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#EC7063', fontSize: '14pt'}} />
  },
  {
    title: 'NDIA Requested Reports?',
    // width: 1,
    dataIndex: 'is_ndia_requested_reports',
    key: 'is_ndia_requested_reports',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#EC7063', fontSize: '14pt'}} />
  },
  {
    title: 'Irregular SIL Supports?',
    // width: 1,
    dataIndex: 'is_irregular_sil',
    key: 'is_irregular_sil',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#EC7063', fontSize: '14pt'}} />
  }
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function PMRatesCatsPage ({match}) {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [item, setItem] = useState({})
  const [currentSet, setCurrentSet] = useState({})
  const [currentRates, setCurrentRates] = useState([])
  const [currentCats, setCurrentCats] = useState([])

  const [form] = Form.useForm()
  const history = useHistory()

  const { params, path } = match
  const isEdit = params && params.id !== 'add' && params.id !== 'new'
  const currentId = params ? params.id : null
  const catId = params ? params.cId : null

  useEffect(() => {
    fetchItem({ currentPage: 1 })
  }, [])

  async function fetchItem () {
    if (!hasAccess(SysPermission.SOFTWARE.RATE_SET.READ)) return

    setIsLoading(true)
    const r = await platformPMRateService.getCat(catId)

    if (r && r.id) {
      const rates = r.rates || []
      const cats = r.category_items || []

      setItem(r)
      setCurrentRates(rates)
      selectRates(0, rates, cats)
      setIsLoading(false)
    }
  }

  function selectRates (idx = 0, rates = [], cats = []) {
    let set = null
    if (validator.isNotEmptyArray(rates) && parseInt(idx) < rates.length) {
      set = rates[idx]
      setCurrentSet(set)
    }

    if (validator.isNotEmptyArray(cats) && set) {
      const cts = cats.filter(e => e.rate_set_id === set.rate_set_id)
      setCurrentCats(cts)
    }
  }

  function onRateSetChange (e) {
    const idx = currentRates.findIndex(f => f.rate_set_id === e)

    if (idx > -1) {
      selectRates(idx, item.rates, item.category_items)
    }
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title={item.id ? `Category - ${item.name}` : 'Category'}>
        <Space>
          <div onClick={() => history.goBack()}>
            <Button shape='round' ghost type='primary'>Back</Button>
          </div>
        </Space>
      </Page.Header>

      <Page.ContentLoading isLoading={isLoading} isUpdate={isUpdate}>
        <Row>
          <Col lg={18}>
            <Form
              form={form}
              name='rs'
            >
              <FormItem
                {...sideBySideFormItemLayout}
                hasFeedback
                label='Select Rate Set'
                name='rate_set_id'
                initialValue={currentSet.rate_set_id}
              >
                <Select onChange={(e) => onRateSetChange(e)}>
                  { currentRates.map(e => (
                    <Option key={`crmps${e.rate_set_id}`} value={e.rate_set_id}>
                      <div>
                        {`${e.rate_name} - ${e.rate_set_name}`}
                      </div>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Form>
          </Col>
        </Row>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={currentCats}
          size='small'
          rowKey={(itm) => (`crts${itm.id}`)}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total: currentCats.length
          }}
        />
      </Page.ContentLoading>
    </Page.Body>
  )
}

export default PMRatesCatsPage
