import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Table, Pagination, Row, Col, Space } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Loading, Page, Panel, Total } from '../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../constants'
import { platformService } from '../../../../../services'
import { auth, validator } from '../../../../../util'
import { SysStore } from '../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.SoftwareRatesSoftwareList
]

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 10
  },
  {
    width: 2,
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#ccc', fontSize: '14pt'}} />
  },
  {
    width: 3,
    render: (item) => <Space size='middle'>
      { hasAccess(SysPermission.SOFTWARE.RATE_SET.LIST) && item.id === 1
        ? <Link to={`/softwares/rates/${item.id}/rate-sets`} key={`clasikey${item.id}`}>
          <FormOutlined />
        </Link>
        : null }
    </Space>
  },
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function SoftwareListRates () {
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState({ active: true })
  const [sort, setSort] = useState({})

  useEffect(() => {
    fetchList({ currentPage: 1 })
  }, [])

  async function fetchList ({ currentPage = page }) {
    if (!hasAccess(SysPermission.SOFTWARE.INFO.LIST)) return

    setIsLoading(true)
    const r = await platformService.listByPage(currentPage, pageSize, filter, sort)
    if (r && r.list) {
      setIsLoading(false)
      setList(r.list)
      setPage(currentPage)
      setTotal(r.total)
    }
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title='Rates - Select Software' />

      <Page.Content>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={list}
          size='small'
          rowKey={(itm) => (`ptmls${itm.id}`)}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total,
            onChange: (e) => fetchList({currentPage: e})
          }}
        />
        <Total total={total} title={'software'} suffix='' />
      </Page.Content>
    </Page.Body>
  )
}

export default SoftwareListRates
