import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Table, Pagination, Row, Col, Space } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Loading, Page, Panel, Total } from '../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../constants'
import { memberService } from '../../../../../services'
import { auth, validator } from '../../../../../util'
import { MemberStore } from '../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.MemberBase,
  Breadcrumb.MemberList
]

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 5
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    width: 3
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 5
  },
  {
    title: 'Active',
    width: 1,
    render: (item) => item.active
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#ccc', fontSize: '14pt'}} />
  },
  {
    title: 'Portal (Admin) Login & Role',
    width: 4,
    render: (item) => <div>
      <div className={`list-text-norm1 ${item.active ? '' : 'list-text-disabled'}`}>{item.system_role_name}</div>
    </div>
  },
  {
    title: 'Portal (Org) Login & Role',
    width: 4,
    render: (item) => <div>
      <div className={`list-text-bold1 ${item.active ? '' : 'list-text-disabled'}`}>{item.system_org_name}</div>
      <div className={`list-text-italic1 ${item.active ? '' : 'list-text-disabled'}`}>{item.system_org_role_name}</div>
    </div>
  },
  {
    title: 'PM Login & Role',
    width: 4,
    render: (item) => item.org_pm_platform_id === 1
      ? <div>
        <div className={`list-text-bold1 ${item.active ? '' : 'list-text-disabled'}`}>{item.org_pm_name}</div>
        <div className={`list-text-italic1 ${item.active ? '' : 'list-text-disabled'}`}>{item.org_pm_role_name}</div>
      </div>
      : null
  },
  {
    title: 'SC Login & Role',
    width: 4,
    render: (item) => item.org_sc_platform_id === 2
      ? <div>
        <div className={`list-text-bold1 ${item.active ? '' : 'list-text-disabled'}`}>{item.org_sc_name}</div>
        <div className={`list-text-italic1 ${item.active ? '' : 'list-text-disabled'}`}>{item.org_sc_role_name}</div>
      </div>
      : null
  },
  {
    width: 1,
    render: (item) => <Space size='middle'>
      { hasAccess(SysPermission.MEMBER.INFO.READ)
        ? <Link to={`/members/listing/${item.id}`} key={`memberkey${item.id}`}>
          <FormOutlined />
        </Link>
        : null }
    </Space>
  },
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function MemberList () {
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState({})
  const [sort, setSort] = useState({})

  useEffect(() => {
    fetchList({ currentPage: 1 })
  }, [])

  const list = MemberStore.useState(e => e.memberList)
  const page = MemberStore.useState(e => e.memberPage)
  const total = MemberStore.useState(e => e.memberTotal)
  const isMemberListLoading = MemberStore.useState(e => e.isMemberListLoading)

  async function fetchList ({ currentPage = page }) {
    if (!hasAccess(SysPermission.MEMBER.INFO.LIST)) return

    setIsLoading(true)
    const r = await memberService.listByPage(currentPage, pageSize, filter, sort)
    if (r && r.list) {
      setIsLoading(false)
      MemberStore.update(e => {
        e.memberList = r.list
        e.memberTotal = r.total
        e.memberPage = currentPage
      })
    }
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title='Members'>
        { hasAccess(SysPermission.MEMBER.INFO.CREATE)
          ? <Link to='/members/listing/add'>
            <Button shape='round' type='primary'>Add Member</Button>
          </Link>
          : null }

      </Page.Header>

      <Page.Content>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={list}
          size='small'
          rowKey={(itm) => (`memitm${itm.id}`)}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total,
            onChange: (e) => fetchList({currentPage: e})
          }}
        />
        <Total total={total} title={'member'} />
      </Page.Content>
    </Page.Body>
  )
}

export default MemberList
