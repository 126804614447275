import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Alert, Button, Checkbox, Form, Icon, Input, notification, Pagination, Row, Col, Space, Skeleton, Switch, Spin, Table } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { Page, Panel, Total } from '../../components'
import { redirectService } from '../../services'
import { auth, common, formatter, localStorage, sessionStorage, validator } from '../../util'
import { AuthStore } from '../../stores'
import { build, redirectHostname } from '../../config'

import 'antd/dist/antd.css'
import './styles.css'

const FormItem = Form.Item
const FormList = Form.List
const TextArea = Input.TextArea

function Redirect ({match, location}) {
  const [isRedirect, setIsRedirect] = useState(true)

  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      fetchAuth()
    }, 1000)
  }, [])

  async function fetchAuth () {
    let isRedirectAvailable = false
    const redirectKey = auth.getCurrentRedirectToken()

    if (location && location.search) {
      const q = common.getQueryStringSearchParams(location.search)

      if (validator.isNotEmptyObject(q) && q.key) {
        try {
          if (redirectKey && redirectKey === q.key) {
            const r1 = await redirectService.exit({ redirect_token: q.key })

            if (r1 && r1.id) {
              await localStorage.clear()
              await sessionStorage.clear()
              await localStorage.setItem('redirected', 'true')

              redirectToHost()
            }
          } else {
            const r = await redirectService.enter({ redirect_token: q.key })
            const { permissions = [], token, redirect_token, user, platform = [], invalid, active_id } = r

            if (token) {
              isRedirectAvailable = true

              localStorage.setItem('redirected', false)
              localStorage.setItem('rememberMe', false)
              auth.setCurrentToken(token)
              auth.setMode(active_id)
              auth.setPermission(permissions)
              auth.setCurrentUser(user)
              auth.setPlatform(platform)
              auth.setCurrentRedirectToken(redirect_token)
              sessionStorage.removeItem('tokenExpired')
              sessionStorage.removeItem('tokenRevoked')
              sessionStorage.removeItem('unauthorized')

              window.location.href = '/'
            }
          }
        } catch (e) {

        }
      }

      if (!isRedirectAvailable) {
        redirectToHost()
      }
    }
  }

  function redirectToHost () {
    setIsRedirect(false)
    console.log('redirect', redirectHostname)
    setTimeout(() => {
      window.location.href = redirectHostname
    }, 3000)
  }

  return (
    <div className='login'>
      <div className='login-box'>
        <Row>
          <Col xs={0} sm={0} lg={14}>
            <div className='login-left-side'>
              <img alt='' src={process.env.PUBLIC_URL + '/img/login-default.jpg'} className='login-pic' />
            </div>
          </Col>

          <Col sm={24} lg={10} className='box'>
            <div className='row-center'>
              <img className='logo' alt='Pumpkin IT' src={process.env.PUBLIC_URL + '/img/pumpkinIT-trans.png'} />
              <div>
                <div className='title'>Pumpkin Portal</div>
                <div style={{ fontSize: '11pt', color: '#e84e1c77', fontWeight: 'bold', textAlign: 'right', marginTop: '-8px', marginBottom: '-22px' }}>{build === 'prod' ? '' : build || 'unknown'}</div>
              </div>
            </div>
            <div className='center'>
              { isRedirect
                ? <div className='title'>Redirecting....</div>
                : <div className='title-err'>Unable to redirect. Will back to the Hub shortly...</div>}
            </div>
          </Col>
        </Row>
      </div>
      <div className='copyright'>
        <div className='footer'>
          Powered by <img src='/img/pumpkinIT-long.png' className='app-logo' /> Copyright © 2022 Pumpkin IT. All rights reserved.
        </div>
      </div>
    </div>
  )
}

export default Redirect
