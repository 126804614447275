import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, DatePicker, Form, Input, Modal, Table, notification, Pagination, Row, Col, Select, Switch, Spin, Space, Upload } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined, UploadOutlined } from '@ant-design/icons'
import { ControlLabel, Loading, Page, Panel, Total } from '../../../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../../../constants'
import { apiHostname, apiVersion } from '../../../../../../../config'
import { platformPMRateService } from '../../../../../../../services'
import { auth, formatter, validator } from '../../../../../../../util'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20
const Option = Select.Option
const FormItem = Form.Item
const Search = Input.Search

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.SoftwareRatesSoftwareList,
  Breadcrumb.SoftwareRatesListPM,
  Breadcrumb.SoftwareRatesEditRateSetPM
]

const formItemLayout = {
  labelCol: { sm: 4, md: 4, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const UploadMsgWrongFormat = 'Wrong format of file selected.'
const UploadMsgNoFile = 'No file selected.'

const columns = [
  {
    title: 'Support Category',
    width: 200,
    dataIndex: 'cat_name',
    key: 'cat_name',
    fixed: 'left'
  },
  {
    title: 'Support Item',
    width: 300,
    dataIndex: 'cat_item_name',
    key: 'cat_item_name',
    fixed: 'left'
  },
  {
    title: 'Support Item Number',
    width: 180,
    dataIndex: 'cat_item_identifier',
    key: 'cat_item_identifier',
    fixed: 'left'
  },
  {
    title: <div>ACT</div>,
    dataIndex: 'value_d0',
    key: 'd0'
  },
  {
    title: <div>NSW</div>,
    dataIndex: 'value_d1',
    key: 'd1'
  },
  {
    title: <div>NT</div>,
    dataIndex: 'value_d2',
    key: 'd2'
  },
  {
    title: <div>QLD</div>,
    dataIndex: 'value_d3',
    key: 'd3'
  },
  {
    title: <div>SA</div>,
    dataIndex: 'value_d4',
    key: 'd4'
  },
  {
    title: <div>TAS</div>,
    dataIndex: 'value_d5',
    key: 'd5'
  },
  {
    title: <div>VIC</div>,
    dataIndex: 'value_d6',
    key: 'd6'
  },
  {
    title: <div>WA</div>,
    dataIndex: 'value_d7',
    key: 'd7'
  },
  {
    title: <div>Remote</div>,
    dataIndex: 'value_d20',
    key: 'd20'
  },
  {
    title: <div>Very<br />Remote</div>,
    dataIndex: 'value_d30',
    key: 'd30'
  },
  {
    title: 'Unit',
    // width: 2,
    dataIndex: 'unit',
    key: 'unit',
    render: (unit) => {
      return formatter.capitalize(unit, false)
    }
  },
  {
    title: 'Quote?',
    // width: 1,
    dataIndex: 'is_quote',
    key: 'is_quote',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#EC7063', fontSize: '14pt'}} />
  },
  {
    title: 'Price Limit?',
    // width: 1,
    dataIndex: 'is_price_limit',
    key: 'is_price_limit',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#EC7063', fontSize: '14pt'}} />
  },
  {
    title: 'Non-face-to-face?',
    // width: 1,
    dataIndex: 'is_nf2f',
    key: 'is_nf2f',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#EC7063', fontSize: '14pt'}} />
  },
  {
    title: 'Provider Travel?',
    // width: 1,
    dataIndex: 'is_provider_travel',
    key: 'is_provider_travel',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#EC7063', fontSize: '14pt'}} />
  },
  {
    title: 'Short Notice Cancellations?',
    // width: 1,
    dataIndex: 'is_short_notice_cancel',
    key: 'is_short_notice_cancel',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#EC7063', fontSize: '14pt'}} />
  },
  {
    title: 'NDIA Requested Reports?',
    // width: 1,
    dataIndex: 'is_ndia_requested_reports',
    key: 'is_ndia_requested_reports',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#EC7063', fontSize: '14pt'}} />
  },
  {
    title: 'Irregular SIL Supports?',
    // width: 1,
    dataIndex: 'is_irregular_sil',
    key: 'is_irregular_sil',
    render: (active) => active === true
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#EC7063', fontSize: '14pt'}} />
  }
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function PMRateSetPage ({match}) {
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isImport, setIsImport] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [filter, setFilter] = useState({ active: true })
  const [item, setItem] = useState({})
  const [allRates, setAllRates] = useState([])
  const [allRateSets, setAllRateSets] = useState([])
  const [filterCats, setFilterCats] = useState([])

  const [fileList, setFileList] = useState([])
  const [fileUploadItem, setFileUploadItem] = useState({})
  const [uploadErrorMsg, setUploadErrorMsg] = useState('')

  const [form] = Form.useForm()
  const history = useHistory()

  const { params, path } = match
  const isEdit = params && params.rId !== 'add' && params.rId !== 'new'
  const platformId = params ? params.id : null
  const rateId = params ? params.rId : null

  useEffect(() => {
    fetchItem({ currentPage: 1 })
  }, [])

  async function fetchItem () {
    if (!hasAccess(SysPermission.SOFTWARE.RATE_SET.READ)) return
    setIsLoading(true)

    if (isEdit) {
      const r = await platformPMRateService.getRateSet(rateId)
      if (r && r.id) {
        setItem(r)

        if (r && validator.isNotEmptyArray(r.categories)) {
          setFilterCats(r.categories.slice())
        }

        await fetchAllRates()
        await fetchAllRateSets(r.rate_id)
        setIsLoading(false)
      }
    } else {
      await fetchAllRates()
      setIsLoading(false)
    }
  }

  async function fetchAllRates () {
    const r = await platformPMRateService.listRateByPage(1, 0, filter)
    if (r && validator.isArray(r.list)) {
      setAllRates(r.list)
    }
  }

  async function fetchAllRateSets (rId = null) {
    const id = rId || item.rate_id
    const r = await platformPMRateService.getAllActiveRateSets(id, rateId)
    if (r && validator.isArray(r)) {
      setAllRateSets(r)
    }
  }

  async function onRateChange (e) {
    if (e) {
      fetchAllRateSets(e)
    }
  }

  async function onSubmit () {
    form.validateFields()
      .then(async (values) => {
        setIsUpdate(true)
        let r = null

        values.active = values.active || false

        if (isEdit) {
          r = await platformPMRateService.saveRateSet(rateId, values)
        } else {
          values.platform_id = platformId
          values.active = true
          r = await platformPMRateService.addRateSet(values)
        }

        if (r && r.id) {
          if (isEdit) {
            notification.success({
              message: 'Update Rate Set successfully',
              description: 'Rate Set is updated successfully.'
            })
          } else {
            notification.success({
              message: 'Create Rate Set successfully',
              description: 'Rate Set is created successfully.'
            })

            setTimeout(() => {
              const link = `/softwares/rates/${platformId}/rate-sets/${r.id}`
              history.replace(link)
              window.location.replace(link)
            }, 1000)
          }

          fileClear()
        } else {
          notification.error({
            message: 'Save Rate Set not successful',
            description: 'Rate Set is unable to save successfully.'
          })
        }

        setIsUpdate(false)
      })
      .catch(errorInfo => {
        notification.error({
          message: 'Save Rate Set not successful',
          description: 'Rate Set is unable to save successfully.'
        })

        setIsUpdate(false)
      })
  }

  async function onSubmitFailed ({ values, errorFields, outOfDate }) {
    notification.error({
      message: 'Save Rate not successful',
      description: 'Rate is unable to save successfully.'
    })

    setIsUpdate(false)
  }

  async function validateDateChange (rule, value, callback) {
    if (value) {
      if (rule.field === 'start_date') {
        const endDate = form.getFieldValue('end_date')

        if (endDate && isDateOverLapping(value, endDate)) {
          throw new Error('Date selected is overlapped with other rate sets')
        }
      } else if (rule.field === 'end_date') {
        const startDate = form.getFieldValue('start_date')

        if (startDate && isDateOverLapping(startDate, value)) {
          throw new Error('Date selected is overlapped with other rate sets')
        }
      }
    }
  }

  function isDateOverLapping (startDate, endDate) {
    for (let i = 0; i < allRateSets.length; i++) {
      const as = allRateSets[i]
      const asStartDate = formatter.toMoment(as.start_date)
      const asEndDate = formatter.toMoment(as.end_date)

      if (asStartDate.isAfter(asEndDate)) return true
      if (startDate.isBetween(asStartDate, asEndDate)) return true
      if (endDate.isBetween(asStartDate, asEndDate)) return true
      if (startDate.isAfter(asStartDate) && endDate.isBefore(asEndDate)) return true
      if (startDate.isBefore(asStartDate) && endDate.isAfter(asEndDate)) return true
    }

    return false
  }

  function onSearchName (e) {
    const value = e.target.value
    setIsSearching(true)

    const cats = validator.isNotEmptyArray(item.categories) ? item.categories.slice() : []
    let filteredCats = []

    if (value) {
      let words = []
      if (value.indexOf(' ') >= 0) {
        words = value.split(' ')
      }

      filteredCats = cats.filter(e => {
        let status = false

        if (validator.isNotEmptyArray(words)) {
          for (let i = 0; i < words.length; i++) {
            const w = words[i].toLowerCase()

            if (e &&
              ((e.cat_item_identifier && e.cat_item_identifier.toLowerCase().indexOf(w) > -1) ||
                (e.cat_item_name && e.cat_item_name.toLowerCase().indexOf(w) > -1) ||
                (e.cat_name && e.cat_name.toLowerCase().indexOf(w) > -1)
              )) {
              status = true
              break
            }
          }
        } else {
          const w = value.toLowerCase()

          if (e &&
            ((e.cat_item_identifier && e.cat_item_identifier.toLowerCase().indexOf(w) > -1) ||
              (e.cat_item_name && e.cat_item_name.toLowerCase().indexOf(w) > -1) ||
              (e.cat_name && e.cat_name.toLowerCase().indexOf(w) > -1)
            )) {
            status = true
          }
        }

        return status
      })
    } else {
      filteredCats = cats
    }

    setFilterCats(filteredCats)
    setIsSearching(false)
  }

  function fileRemove (file) {
    const fileIndex = fileList.indexOf(file)
    if (fileIndex > -1) {
      let newFileList = fileList.slice()
      newFileList.shift()
      setFileList(newFileList)
      setFileUploadItem({})
    }
  }

  function fileClear () {
    setFileList([])
    setFileUploadItem({})
  }

  function fileChange (info) {
    if (info && info.file) {
      const f = info.file
      const { percent, response: r = null, status, uid } = f
      if (percent === 100 && r && status && status === 'done') {
        const data = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          name: r.filePath ? r.filePath.originalname : '',
          uid: uid
        }

        setFileList([data])
        setFileUploadItem(data)
        setUploadErrorMsg('')
      }
    }
  }

  function fileSet (file) {
    if (file && (
      file.type === 'text/csv' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.template'
    )) {
      setFileList([file])
      setFileUploadItem(file)
      setUploadErrorMsg('')

      return true
    } else {
      setUploadErrorMsg(UploadMsgWrongFormat)
      return false
    }
  }

  async function startImport () {
    if (isImport) return

    if (fileList.length === 0) {
      setUploadErrorMsg(UploadMsgNoFile)
    } else if (!fileUploadItem.uid) {
      setUploadErrorMsg(UploadMsgNoFile)
    } else {
      setIsImport(true)

      try {
        const r = await platformPMRateService.startImportCsv(rateId, fileUploadItem)

        if (r && r.id) {
          notification.success({
            message: 'Import Rates successfully',
            description: 'Rates are imported successfully.'
          })

          fileClear()
          setIsImport(false)
          setShowModal(false)

          window.location.reload()
        } else {
          fileClear()
          notification.error({
            message: 'Import Rates not successful',
            description: 'Unable to import rates. Please try again later.'
          })
        }
      } catch (e) {
        fileClear()
        notification.error({
          message: 'Import Rates not successful',
          description: 'Unable to import rates. Please try again later.'
        })
      }

      setIsImport(false)
    }
  }

  const isRateSelected = !!form.getFieldValue('rate_id') || !!item.rate_id

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title={item.id ? `Rate Set - ${item.name}` : 'Rate Set'}>
        <Space>
          { ((isEdit && hasAccess(SysPermission.SOFTWARE.RATE_SET.UPDATE)) || (!isEdit && hasAccess(SysPermission.SOFTWARE.RATE_SET.CREATE)))
            ? <Button shape='round' type='primary' loading={isUpdate} onClick={onSubmit}>{ isUpdate ? 'Saving' : 'Save'}</Button>
            : null }
          <div onClick={() => history.goBack()}>
            <Button shape='round' ghost type='primary'>Back</Button>
          </div>
        </Space>
      </Page.Header>

      <Page.ContentLoading isLoading={isLoading} isUpdate={isUpdate}>
        <Form
          form={form}
          name='rs'
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Panel title='Detail'>
            <FormItem
              {...formItemLayout}
              label='Active'
              name='active'
              initialValue={item.active}
              valuePropName='checked'
            >
              <Switch
                defaultChecked={item.active}
                checkedChildren={'Enable'}
                unCheckedChildren={'Disable'}
              />
            </FormItem>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Set'
              name='rate_id'
              initialValue={item.rate_id}
              rules={[
                { required: true, message: 'Select Rate' }
              ]}
            >
              <Select
                disabled={isEdit}
                onChange={onRateChange}
              >
                { allRates.map(e => (
                  <Option key={`armps${e.id}`} value={e.id}>
                    <div>
                      {`${e.name}`}
                    </div>
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Set Name'
              name='name'
              initialValue={item.name}
              rules={[
                { min: 2, message: 'Name must be between 2 and 128 characters' },
                { max: 128, message: 'Name must be between 2 and 128 characters' },
                { required: true, message: 'Please enter member name' },
                { whitespace: true, message: 'Please enter member name' }
              ]}
            >
              <Input placeholder='Enter Set Name' disabled={!isRateSelected} />
            </FormItem>
            <Row>
              <Col lg={12}>
                <FormItem
                  {...sideBySideFormItemLayout}
                  hasFeedback
                  label='Start Date'
                  name='start_date'
                  initialValue={item.start_date ? formatter.toMoment(item.start_date) : undefined}
                  rules={[
                    { required: true, message: 'Please select Start Date' },
                    { validator: validateDateChange }
                  ]}
                >
                  <DatePicker
                    style={{width: '70%'}}
                    disabled={!isRateSelected}
                  />
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem
                  {...sideBySideFormItemLayout}
                  hasFeedback
                  label='End Date'
                  name='end_date'
                  initialValue={item.end_date ? formatter.toMoment(item.end_date) : undefined}
                  rules={[
                    { required: true, message: 'Please select End Date' },
                    { validator: validateDateChange }
                  ]}
                >
                  <DatePicker
                    style={{width: '70%'}}
                    disabled={!isRateSelected}
                  />
                </FormItem>
              </Col>
            </Row>
          </Panel>
        </Form>

        <Modal
          width={'60%'}
          title={<div className='modal-title'>Import Rate Sets and Categories</div>}
          visible={showModal}
          onOk={isImport ? null : startImport}
          onCancel={isImport ? null : () => setShowModal(false)}
          footer={[
            <Button key='back' shape='round' onClick={isImport ? null : () => setShowModal(false)}>
              Cancel
            </Button>,
            hasAccess(SysPermission.SOFTWARE.RATE_SET_IMPORT.CREATE)
              ? <Button key='submit' ghost={!fileUploadItem.uid} shape='round' type='primary' loading={isImport} onClick={isImport ? null : startImport}>
                Confirm
              </Button>
              : undefined
          ]}
        >
          <div>Upload the latest rate set file in csv/Excel format and wait for the updates for a while.</div>
          <div style={{color: 'red', marginBottom: '20px'}}>Be reminded that this action cannot be undone!</div>

          <Upload
            method={'POST'}
            action={`${apiHostname}${apiVersion}/private/api/portal/sys/pm-rates/rate-sets/file/${item.id}`}
            data={{rateId: item.id}}
            name={'file'}
            onRemove={fileRemove}
            onChange={fileChange}
            beforeUpload={fileSet}
            headers={{ Authorization: `Bearer ${auth.getCurrentToken()}` }}
            fileList={fileList}
            multiple={false}
            disabled={isImport}
          >
            <Button key='submit' shape='round' type='primary' loading={isImport}>
              <UploadOutlined />Select File
            </Button>
          </Upload>
        </Modal>

        { isEdit && hasAccess(SysPermission.SOFTWARE.RATE_SET_IMPORT.CREATE)
          ? <Panel title='Import'>
            <div>
              <div className='billing-rate-info'>
                <div className='row'>
                  <div className='billing-rate-row-title'>Import rate set csv to configure the latest set of categories and rates under this rate set.</div>
                  <Button
                    shape='round'
                    type='primary'
                    onClick={() => setShowModal(true)}
                    loading={isUpdate || isImport}
                  >
                    Import
                  </Button>
                </div>
              </div>
            </div>
          </Panel>
          : null }

        { isEdit && validator.isNotEmptyArray(item.categories)
          ? <Panel title='Rate Table'>
            <Page.Filter>
              <Row gutter={8}>
                <Col lg={8}>
                  <ControlLabel>Support Category, Support Items, Support Item Number</ControlLabel>
                  <Search
                    placeholder='Search Categories'
                    onChange={onSearchName}
                    loading={isSearching}
                    disabled={isLoading || isUpdate || isImport}
                  />
                </Col>
              </Row>
            </Page.Filter>
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={filterCats}
              size='small'
              rowKey={(itm) => (`crts${itm.id}`)}
              scroll={{ x: true, scrollToFirstRowOnChange: true }}
              pagination={{
                hideOnSinglePage: true,
                pageSize: 30,
                total: filterCats.length
              }}
            />
          </Panel>
          : null }
      </Page.ContentLoading>
    </Page.Body>
  )
}

export default PMRateSetPage
