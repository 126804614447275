import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input, notification, Pagination, Row, Col, Radio, Select, Space, Skeleton, Spin, Tabs } from 'antd'
import { CheckCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Page, Panel, Total } from '../../../../../../../components'
import { BreadcrumbOrg as Breadcrumb, MemberType, OrgPermission } from '../../../../../../../constants'
import { memberService, platformAdminRoleService } from '../../../../../../../services'
import { auth, formatter, localStorage, validator } from '../../../../../../../util'
import { SysStore } from '../../../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'

const Option = Select.Option
const FormItem = Form.Item
const FormList = Form.List
const TextArea = Input.TextArea
const { TabPane } = Tabs

const pageSize = 20

const formItemLayout = {
  labelCol: { sm: 4, md: 4, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 4, md: 4, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 5, path: '/logs' }
]

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.PortalPlatform,
  Breadcrumb.PortalPlatformPM,
  Breadcrumb.PortalPlatformPMEdit,
  Breadcrumb.PortalPlatformPMUserEdit
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function PlatformMemberEdit (props) {
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isShowPwInput, setShowPwInput] = useState(false)
  const [currentTab, setCurrentTab] = useState('1')
  const [item, setItem] = useState({})
  const [portalAdminOrgRoleList, setAdminOrgRoleList] = useState([])

  const [form] = Form.useForm()
  const history = useHistory()
  const { params, path } = props.match
  const isEdit = params && params.mId !== 'add' && params.mId !== 'new'
  const platformId = params ? params.id : null
  const currentId = params ? params.mId : null

  useEffect(() => {
    fetchUserRole()

    if (isEdit && hasAccess(OrgPermission.SOFTWARE.ADMIN_USER.READ)) {
      fetchItem()
    }

    if (params.type) {
      const tab = TabList.find(e => e.path === params.type || e.path === `/${params.type}`)
      const cTab = tab && tab.tabId ? String(tab.tabId) : '1'
      setCurrentTab(cTab)
    }
  }, [])

  async function fetchUserRole () {
    let showMode = auth.getMode()
    const filter1 = { active: true, org_id: [0, showMode], platform_id: platformId }

    const portalAdminRole = await platformAdminRoleService.orgListByPage(1, 0, filter1)

    if (portalAdminRole && validator.isNotEmptyArray(portalAdminRole.list)) {
      const list = portalAdminRole.list.filter(e => e.org_id === 0 || e.org_id === parseInt(showMode))
      setAdminOrgRoleList(list)
    }
  }

  async function fetchItem () {
    setIsLoading(true)
    const r = await memberService.orgGet(currentId, MemberType.Platform)
    if (r && r.id) {
      setItem(r)
      setIsLoading(false)
    }
  }

  async function onSubmit () {
    form.validateFields()
      .then(async (values) => {
        setIsUpdate(true)
        let r = null

        values.type = MemberType.Platform
        values.platform_id = platformId

        delete values.pw_select
        delete values.confirm_password

        if (isEdit) {
          r = await memberService.orgSave(currentId, values)
        } else {
          r = await memberService.orgAdd(values)
        }

        if (r && r.id) {
          if (isEdit) {
            notification.success({
              message: 'Update Admin User successfully',
              description: 'Admin User is updated successfully.'
            })
          } else {
            notification.success({
              message: 'Create Admin User successfully',
              description: 'Admin User is created successfully.'
            })

            setTimeout(() => {
              const link = `/member/listing/${r.id}/info`
              history.replace(link)
              window.location.replace(link)
            }, 1000)
          }
        } else {
          notification.error({
            message: 'Save Admin User not successful',
            description: 'MembAdmin Userer is unable to save successfully.'
          })
        }

        setIsUpdate(false)
      })
      .catch(errorInfo => {
        notification.error({
          message: 'Save Admin User not successful',
          description: 'Admin User is unable to save successfully.'
        })

        setIsUpdate(false)
      })
  }

  async function onSubmitFailed ({ values, errorFields, outOfDate }) {
    notification.error({
      message: 'Save Admin User not successful',
      description: 'Admin User is unable to save successfully.'
    })

    setIsUpdate(false)
  }

  function handleTabChange (index) {
    const tab = TabList.find(e => e.tabId === parseInt(index))
    setCurrentTab(index)
    if (tab && tab.tabId) {
      history.replace(`/portal/platform/${platformId}/admin-users/${currentId}${tab.path}`)
    }
  }

  function onChangePasswordSelect (e) {
    const value = e.target.value
    if (value === 'input') {
      setShowPwInput(true)
    } else {
      form.setFieldsValue({'new_password': undefined, 'confirm_password': undefined})
      setShowPwInput(false)
    }
  }

  // validator function: async function: throw new error, sync function: callback(new Error())
  async function validateEntry (rule, value, callback) {
    if (value) {
      if (rule.field === 'email') {
        if (!validator.isEmail(value)) {
          throw new Error('Invalid Email Format')
        }
      }

      if (rule.field === 'code' || rule.field === 'email') {
        const r = await memberService.orgDuplicateMember({ [rule.field]: value, id: currentId !== 'add' ? currentId : null })
        if (r && r.errors) {
          throw new Error(formatter.toErrorMessage(r.errors))
        }
      }
    }
  }

  async function validatePw (rule, value, callback) {
    if (value) {
      if (validator.checkPassword(value)) {
        throw new Error('Please enter at least 1 number, 1 lowercase letter and 1 uppercase letter')
      } else if (value.length < 8) {
        throw new Error('Please enter at least 8 characters')
      }
    }
  }

  async function validateConfirmPw (rule, value, callback) {
    if (value) {
      const newPw = form.getFieldValue('new_password')
      if (validator.checkPassword(value)) {
        throw new Error('Please enter at least 1 number, 1 lowercase letter and 1 uppercase letter')
      } else if (value.length < 8) {
        throw new Error('Please enter at least 8 characters')
      } else if (value !== newPw) {
        throw new Error('Your confirm password does not match new password')
      }
    }
  }

  function getInfoTab () {
    return (
      <div>
        <Form
          form={form}
          name='detail'
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Panel title='Details'>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Name'
              name='name'
              initialValue={item.name}
              rules={[
                { min: 2, message: 'Name must be between 2 and 128 characters' },
                { max: 128, message: 'Name must be between 2 and 128 characters' },
                { required: true, message: 'Please enter user name' },
                { whitespace: true, message: 'Please enter user name' }
              ]}
            >
              <Input placeholder='Enter User Name' />
            </FormItem>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Code'
              name='code'
              initialValue={item.code}
              rules={[
                { required: true, message: 'Please enter user code' },
                { validator: validateEntry }
              ]}
            >
              <Input placeholder='Enter User Name' />
            </FormItem>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Email'
              name='email'
              initialValue={item.email}
              rules={[
                { required: true, message: 'Please enter user email' },
                { validator: validateEntry }
              ]}
            >
              <Input placeholder='Enter User Email' />
            </FormItem>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Remark'
              name='remark'
              initialValue={item.remark}
            >
              <Input placeholder='Enter User Remark' />
            </FormItem>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Role'
              name='role_id'
              initialValue={item.role_id}
              rules={[
                { required: true, message: 'Please select role' }
              ]}
            >
              <Select>
                { portalAdminOrgRoleList.map(e => (
                  <Option key={`orglsr${e.id}`} value={e.id} disabled={parseInt(e.org_id) === 0}>
                    <div>
                      <div>{ e.name }</div>
                      <div className='sub-option'>{ e.description }</div>
                    </div>
                  </Option>
                ))}
              </Select>
            </FormItem>

            {/** Password handling */}
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Password Select'
              name='pw_select'
              initialValue={'default'}
            >
              <Radio.Group onChange={(e) => onChangePasswordSelect(e)}>
                <Radio value='default'>{`${isEdit ? 'No Change' : 'Generated by System'}`}</Radio>
                <Radio value='input'>{`${isEdit ? 'Manually Update' : 'Manually Enter'}`}</Radio>
              </Radio.Group>
            </FormItem>
            { isShowPwInput
              ? <div>
                <FormItem
                  {...formItemLayout}
                  hasFeedback
                  label='New Password'
                  name='new_password'
                  initialValue={null}
                  rules={[
                    { required: true, message: 'Please enter new password' },
                    { validator: validatePw }
                  ]}
                >
                  <Input placeholder='Enter User New Password' />
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  hasFeedback
                  label='Confirm Password'
                  name='confirm_password'
                  initialValue={null}
                  rules={[
                    { required: true, message: 'Please enter confirm password' },
                    { validator: validateConfirmPw }
                  ]}
                >
                  <Input placeholder='Enter Confirm Password' />
                </FormItem>
              </div>
              : null }
          </Panel>
        </Form>
      </div>
    )
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title={item.id ? `PM User - ${item.name}` : currentId && isEdit ? 'PM User' : 'Add PM User'}>
        <Space>
          {currentTab === String(TabList[0].tabId) &&
            ((isEdit && hasAccess(OrgPermission.SOFTWARE.ADMIN_USER.UPDATE)) || (!isEdit && hasAccess(OrgPermission.SOFTWARE.ADMIN_USER.CREATE)))
            ? <Button shape='round' type='primary' loading={isUpdate} onClick={onSubmit}>{isUpdate ? 'Saving' : 'Save'}</Button>
            : null}
          <div onClick={history.goBack}>
            <Button shape='round' ghost type='primary'>Back</Button>
          </div>
        </Space>
      </Page.Header>

      <Page.ContentLoading isLoading={isLoading} isUpdate={isUpdate}>
        <Tabs
          defaultActiveKey={currentTab}
          onChange={handleTabChange}
        >
          <TabPane tab='User Info' key='1'>
            {getInfoTab()}
          </TabPane>
          {/* <TabPane tab='Login Settings' key='2'>
            <MemberLogin currentId={currentId} />
          </TabPane> */}
          {/* {isEdit && (mode === MODE_ADMIN || mode === MODE_MEMBER)
            ? <TabPane tab='Portal - System Login' key='2'>
              <Panel><MemberLogin currentId={currentId} mode={MODE_ADMIN} /></Panel>
            </TabPane>
            : null}
          {isEdit && (mode === MODE_ADMIN_ORG || mode === MODE_MEMBER)
            ? <TabPane tab='Portal - Organisation Login' key='3'>
              <Panel><MemberLogin currentId={currentId} mode={MODE_ADMIN_ORG} /></Panel>
            </TabPane>
            : null}
          {isEdit && (mode === MODE_ORG || mode === MODE_MEMBER)
            ? <TabPane tab='Platform Login' key='4'>
              <Panel><MemberLogin currentId={currentId} mode={MODE_ORG} /></Panel>
            </TabPane>
            : null} */}
          {/* {isEdit
            ? <TabPane tab='Activity Log' key='5' />
            : null} */}
        </Tabs>

      </Page.ContentLoading>
    </Page.Body>
  )
}

export default PlatformMemberEdit
