import './styles.css'
import { Button } from 'antd'

function Profile ({ visible = false, userInfo = {}, onLogout }) {
  return (
    <div className={`App-Profile ${visible ? 'show' : 'hide'}`}>
      <div className='header'>{userInfo.name}</div>
      <div className='content'>
        <div className='row'>
          <Button shape='round' type='primary' onClick={onLogout}>Logout</Button>
        </div>
      </div>
    </div>
  )
}

export default Profile
