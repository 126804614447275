import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Form, Input, notification, Row, Col, Space, Tabs } from 'antd'
import { apiVersion } from '../../../../config'
import { Page, Panel, Upload } from '../../../../components'
import { BreadcrumbOrg as Breadcrumb, OrgPermission } from '../../../../constants'
import { orgService } from '../../../../services'
import { auth, formatter, validator } from '../../../../util'

import 'antd/dist/antd.css'
import './styles.css'

const FormItem = Form.Item
const { TabPane } = Tabs

const formItemLayout = {
  labelCol: { sm: 4, md: 4, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 2, path: '/admin-users' },
  { tabId: 3, path: '/roles' },
  { tabId: 4, path: '/platform' },
  { tabId: 6, path: '/platform-specific' },
  { tabId: 7, path: '/logs' }
]

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.OrgBase,
  Breadcrumb.OrgEdit
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

const apiUrl = `${apiVersion}/private/api/portal/org/files/upload`
const UploadMsgWrongFormat = 'Wrong format of file selected.'

function OrgEdit({ match }) {
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [currentTab, setCurrentTab] = useState('1')
  const [item, setItem] = useState({})
  const [fileList, setFileList] = useState([])
  const [fileUploadItem, setFileUploadItem] = useState({})
  const [uploadErrorMsg, setUploadErrorMsg] = useState('')

  const [form] = Form.useForm()
  const history = useHistory()
  const { params } = match
  const currentId = params ? params.id : null
  const isEdit = params && params.id !== 'add' && params.id !== 'new'

  useEffect(() => {
    fetchItem()

    if (params.type) {
      const tab = TabList.find(e => e.path === params.type || e.path === `/${params.type}`)
      const cTab = tab && tab.tabId ? String(tab.tabId) : '1'
      setCurrentTab(cTab)
    } else {
      history.replace(`/organisation/${currentId}${TabList[0].path}`)
    }
  }, [])

  async function fetchItem () {
    if (!hasAccess(OrgPermission.ORGANISATION.INFO.READ)) return

    setIsLoading(true)
    const r = await orgService.orgGet(currentId)
    if (r && r.id) {
      setItem(r)
      setIsLoading(false)
    } else {
      notification.error({
        message: 'Get Account not successful',
        description: 'Account is unable to get successfully.'
      })
    }
  }

  function onSubmit () {
    form.validateFields()
      .then(async (values) => {
        setIsUpdate(true)

        if (fileUploadItem && fileUploadItem.uid) {
          values.new_file_name = fileUploadItem.fileName
          values.new_file_url = fileUploadItem.fileUrl
          values.new_file_path = fileUploadItem.filePath
        }

        const r = await orgService.orgSave(currentId, values)

        if (r && r.id) {
          notification.success({
            message: 'Update Account successfully',
            description: 'Account is updated successfully.'
          })

          fileClear()
          fetchItem()
        } else {
          notification.error({
            message: 'Save Account not successful',
            description: 'Account is unable to save successfully.'
          })
        }

        setIsUpdate(false)
      })
      .catch(errorInfo => {
        notification.error({
          message: 'Save Account not successful',
          description: 'Account is unable to save successfully.'
        })

        setIsUpdate(false)
      })
  }

  function onSubmitFailed ({ values, errorFields, outOfDate }) {
    notification.error({
      message: 'Save Account not successful',
      description: 'Account is unable to save successfully.'
    })

    setIsUpdate(false)
  }

  function handleTabChange (index) {
    const tab = TabList.find(e => e.tabId === parseInt(index))
    setCurrentTab(index)
    if (tab && tab.tabId) {
      history.replace(`/organisation/${currentId}${tab.path}`)
    }
  }

  // validator function: async function: throw new error, sync function: callback(new Error())
  async function validateEntry (rule, value, callback) {
    if (value) {
      if (rule.field === 'code' || rule.field === 'email') {
        const r = await orgService.checkDuplicateMember({ [rule.field]: value, id: currentId })
        if (r && r.errors) {
          return Promise.reject(formatter.toErrorMessage(r.errors))
        }

        return Promise.resolve()
      }
    }
  }

  function validateABN (rule, value, callback) {
    if (value === null || value === undefined || value === '') {
      return Promise.reject('Please enter ABN')
    } else if (!validator.isDigit(value) || value.length !== 11) {
      return Promise.reject('ABN is invalid in format')
    }

    return Promise.resolve()
  }

  const handlePasteABN = (event) => {
    event.preventDefault()
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('text')

    if (pastedData) {
      const cleanedData = pastedData.replace(/\D+/g, '')
      form.setFieldsValue({ abn: cleanedData })
      form.validateFields(['abn']).catch(console.log)
    }
  }

  function validateBSB (rule, value, callback) {
    if (value !== null && value !== undefined && value !== '' && !validator.isBSB(value)) {
      return Promise.reject('BSB is invalid in format')
    }

    return Promise.resolve()
  }

  const validateBankCode = (rule, value) => {
    if (typeof value === 'string' && value.trim().length > 0) {
      // Regular expression to match three uppercase letters
      const regex = /^[A-Z]{3}$/

      // Check if the input value matches the regex pattern
      if (value && regex.test(value)) {
        return Promise.resolve()
      }

      return Promise.reject('Please enter a valid bank code with 3 uppercase letter only (e.g. ANZ, WBC, CBA)')
    }

    return Promise.resolve()
  }

  const handleBankCodeChange = (e) => {
    let value = e.target.value

    // Limit to maximum 3 characters
    value = value.slice(0, 3)

    // Convert to uppercase
    value = value.toUpperCase()

    // Update the form field only if it matches the required pattern
    if (/^[A-Z]{0,3}$/.test(value)) {
      form.setFieldsValue({ 'bank_code': value })
    }
  }

  const validateDEUserID = (rule, value) => {
    if (typeof value === 'string' && value.trim().length > 0) {
      // Regular expression to match exactly 6 digits
      const regex = /^\d{6}$/

      // Check if the input value exists and matches the regex pattern
      if (value && regex.test(value)) {
        return Promise.resolve()
      }

      return Promise.reject('Please enter a valid DE User ID with 6 digits only')
    }

    return Promise.resolve()
  }

  function validateBankAccNo (rule, value, callback) {
    if (value !== null && value !== undefined && value !== '' && (!validator.isDigit(value) || value.length > 10)) {
      return Promise.reject('Account Number is invalid in format')
    }

    return Promise.resolve()
  }

  function fileRemove (file) {
    const fileIndex = fileList.indexOf(file)
    if (fileIndex > -1) {
      let newFileList = fileList.slice()
      newFileList.shift()
      setFileList(newFileList)
      setFileUploadItem({})
    }
  }

  function fileClear () {
    setFileList([])
    setFileUploadItem({})
  }

  function fileChange (info) {
    if (info && info.file) {
      const f = info.file
      const { percent, response: r = null, status, uid } = f
      if (percent === 100 && r && status && status === 'done') {
        const data = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          name: r.filePath ? r.filePath.originalname : '',
          uid: uid
        }

        setFileList([data])
        setFileUploadItem(data)
        setUploadErrorMsg('')
      }
    }
  }

  function fileSet(file) {
    if (file && (
      file.type === 'image/jpeg' ||
      file.type === 'image/png'
    )) {
      setFileList([file])
      setFileUploadItem(file)
      setUploadErrorMsg('')

      return true
    } else {
      setUploadErrorMsg(UploadMsgWrongFormat)
      return false
    }
  }

  function getInfoTab() {
    return (
      <div>
        <Form
          form={form}
          name='detail'
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Panel title='Details'>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Org Legal Name'
              name='legal_name'
              initialValue={item.legal_name}
              rules={[
                { min: 2, message: 'Legal Name must be between 2 and 128 characters' },
                { max: 128, message: 'Legal Name must be between 2 and 128 characters' },
                { required: true, message: 'Please enter organisation legal name' },
                { whitespace: true, message: 'Please enter organisation legal name' }
              ]}
            >
              <Input placeholder={'Enter Organisation Legal Name'} />
            </FormItem>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Org Trading Name'
              name='name'
              initialValue={item.name}
              rules={[
                { min: 2, message: 'Trading Name must be between 2 and 128 characters' },
                { max: 128, message: 'Trading Name must be between 2 and 128 characters' },
                { required: true, message: 'Please enter organisation trading name' },
                { whitespace: true, message: 'Please enter organisation trading name' }
              ]}
            >
              <Input placeholder={'Enter Organisation Trading Name'} />
            </FormItem>
            <Row>
              <Col lg={12}>
                <FormItem
                  {...sideBySideFormItemLayout}
                  hasFeedback
                  label='ABN'
                  name='abn'
                  initialValue={item.abn}
                  rules={[
                    { required: true, message: ' ' },
                    { validator: validateABN }
                  ]}
                >
                  <Input onPaste={handlePasteABN}/>
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem
                  {...sideBySideFormItemLayout}
                  hasFeedback
                  label='NDIS Number'
                  name='ndis_number'
                  initialValue={item.ndis_number}
                  rules={[
                    { required: true, message: 'Please enter ndis number' }
                  ]}
                >
                  <Input />
                </FormItem>
              </Col>
            </Row>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Address'
              name='address'
              initialValue={item.address}
              rules={[
                { required: true, message: 'Please enter organisation name' },
                { whitespace: true, message: 'Please enter organisation name' }
              ]}
            >
              <Input placeholder={'Enter Address'} />
            </FormItem>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Unit/Building (optional)'
              name='unit_building'
              initialValue={item.unit_building}
            >
              <Input />
            </FormItem>
            <Row>
              <Col lg={12}>
                <FormItem
                  {...sideBySideFormItemLayout}
                  hasFeedback
                  label='Phone Number'
                  name='phone_number'
                  initialValue={item.phone_number}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem
                  {...sideBySideFormItemLayout}
                  hasFeedback
                  label='Fax'
                  name='fax'
                  initialValue={item.fax}
                >
                  <Input />
                </FormItem>
              </Col>
            </Row>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Website'
              name='website'
              initialValue={item.website}
            >
              <Input placeholder={'Enter Organisation Website'} />
            </FormItem>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Email'
              name='email'
              initialValue={item.email}
              rules={[
                { required: true, message: 'Please enter organisation email' },
                { whitespace: true, message: 'Please enter organisation email' }
              ]}
            >
              <Input placeholder={'Enter Organisation Email'} />
            </FormItem>
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Contact Person'
              name='contact_person'
              initialValue={item.contact_person}
            >
              <Input />
            </FormItem>
            <Row>
              <Col lg={12}>
                <FormItem
                  {...sideBySideFormItemLayout}
                  hasFeedback
                  label='After Hours Contact Name'
                  name='after_contact_person'
                  initialValue={item.after_contact_person}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col lg={12}>
                <FormItem
                  {...sideBySideFormItemLayout}
                  hasFeedback
                  label='After Hours Contact Number'
                  name='after_contact_number'
                  initialValue={item.after_contact_number}
                >
                  <Input />
                </FormItem>
              </Col>
            </Row>
          </Panel>
          <Panel title='Bank Info'>
            <Row>
              <Col lg={12}>
                <FormItem
                  {...sideBySideFormItemLayout}
                  hasFeedback
                  label={`Account's Name`}
                  name='bank_acc_name'
                  initialValue={item.bank_acc_name}
                  rules={[
                    { min: 2, message: 'Account\'s Name must be between 2 and 26 characters' },
                    { max: 26, message: 'Account\'s Name must be between 2 and 26 characters' },
                    { required: true, message: 'Please enter Account\'s Name' },
                    { whitespace: true, message: 'Please enter Account\'s Name' }
                  ]}
                >
                  <Input />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormItem
                  {...sideBySideFormItemLayout}
                  hasFeedback
                  label={`Remittance Name`}
                  name='bank_rmt_name'
                  initialValue={item.bank_rmt_name}
                  rules={[
                    { min: 2, message: 'Remittance Name must be between 2 and 16 characters' },
                    { max: 16, message: 'Remittance Name must be between 2 and 16 characters' },
                    { required: true, message: 'Please enter Remittance Name' },
                    { whitespace: true, message: 'Please enter Remittance Name' }
                  ]}
                >
                  <Input />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormItem
                  {...sideBySideFormItemLayout}
                  hasFeedback
                  label='BSB'
                  name='bank_bsb'
                  initialValue={item.bank_bsb}
                  rules={[
                    { required: true, message: ' ' },
                    { validator: validateBSB }
                  ]}
                >
                  <Input />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormItem
                  {...sideBySideFormItemLayout}
                  hasFeedback
                  label='Bank Code'
                  name='bank_code'
                  initialValue={item.bank_code}
                  rules={[
                    { whitespace: true, message: 'Please enter Bank Code' },
                    { validator: validateBankCode }
                  ]}
                >
                  <Input maxLength='3' onChange={handleBankCodeChange} />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormItem
                  {...sideBySideFormItemLayout}
                  hasFeedback
                  label='DE User ID'
                  name='bank_de_user_id'
                  initialValue={item.bank_de_user_id}
                  rules={[
                    { whitespace: true, message: 'Please enter DE User ID' },
                    { validator: validateDEUserID }
                  ]}
                >
                  <Input maxLength='6' />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormItem
                  {...sideBySideFormItemLayout}
                  hasFeedback
                  label={`Account Number`}
                  name='bank_acc_no'
                  initialValue={item.bank_acc_no}
                  rules={[
                    { required: true, message: ' ' },
                    { validator: validateBankAccNo }
                  ]}
                >
                  <Input />
                </FormItem>
              </Col>
            </Row>
          </Panel>

          {isEdit
            ? <Panel title='Organisation Logo'>
              <Row>
                {item.thumb_url
                  ? <Col lg={12}>
                    Current Logo:
                    <div className='image-center'>
                      <img className='image-logo' src={item.thumb_url} alt={item.name} />
                    </div>
                  </Col>
                  : null}
                <Col lg={12}>
                  {item.thumb_url ? 'Upload Logo:' : ''}
                  <div className='image-center'>
                    <Upload
                      apiUrl={apiUrl}
                      loading={isLoading}
                      readOnly={false}
                      showError={!!uploadErrorMsg}
                      options={{
                        method: 'POST',
                        data: { orgId: item.id },
                        name: 'file',
                        file: fileUploadItem,
                        onRemove: fileRemove,
                        onChange: fileChange,
                        beforeUpload: fileSet,
                        fileList: fileList,
                        multiple: false
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Panel>
            : null}
        </Form>
      </div>
    )
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title={item.id ? `Account Info - ${item.name}` : 'Account Info'}>
        <Space>
          {currentTab === String(TabList[0].tabId) && hasAccess(OrgPermission.ORGANISATION.INFO.UPDATE)
            ? <Button shape='round' type='primary' loading={isUpdate} onClick={onSubmit}>{isUpdate ? 'Saving' : 'Save'}</Button>
            : null}
          <div onClick={history.goBack}>
            <Button shape='round' ghost type='primary'>Back</Button>
          </div>
        </Space>
      </Page.Header>

      <Page.ContentLoading isLoading={isLoading} isUpdate={isUpdate}>
        <Tabs
          defaultActiveKey={currentTab}
          onChange={handleTabChange}
        >
          <TabPane tab='Account Info' key='1'>
            {getInfoTab()}
          </TabPane>
          {/* <TabPane tab='Platform Signed On' key='4'>
            <Panel><PlatformList orgId={currentId} /></Panel>
          </TabPane>
          <TabPane tab='Platform Specific Details' key='6'>
            <Panel><PlatformDetailList orgId={currentId} /></Panel>
          </TabPane> */}
          {/* { isEdit
            ? <TabPane tab='Portal (Organisation) Admin Users' key='2'>
              <Panel><UserList orgId={currentId} /></Panel>
            </TabPane>
            : null }
          { isEdit
            ? <TabPane tab='Portal (Organisation) User Roles' key='3'>
              <Panel><AdminRole orgId={currentId} /></Panel>
            </TabPane>
            : null }
          { isEdit
            ? <TabPane tab='Platform Signed On' key='4'>
              <Panel><PlatformList orgId={currentId} /></Panel>
            </TabPane>
            : null }
          { isEdit
            ? <TabPane tab='Platform Specific Details' key='6'>
              <Panel><PlatformDetailList orgId={currentId} /></Panel>
            </TabPane>
            : null } */}
          {/* { isEdit
            ? <TabPane tab='Permissions' key='4'>

            </TabPane>
            : null } */}
          {/* { isEdit
            ? <TabPane tab='Activity Log' key='7'>

            </TabPane>
            : null } */}
        </Tabs>

      </Page.ContentLoading>
    </Page.Body>
  )
}

export default OrgEdit
