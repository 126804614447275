import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { auth } from './util'

import App from './modules/App'
import Login from './modules/Login'
import ForgotPassword from './modules/Login/forgotPassword'
import RedirectModule from './modules/Redirect'

import reducers from './states/reducers'
import root from './states/sagas'

import './index.less'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(root)

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/redirect' component={RedirectModule} />
        <Route path='/' render={() => (isLoggedIn() ? <App /> : <Redirect to='/login' />)} />
        {/* <Route path='/' render={() => <App />} /> */}
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
)

function isLoggedIn () {
  return auth.isSignedIn()
}
