import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Form, Input, InputNumber, notification, Row, Col, Select, Space, Switch } from 'antd'
import { apiVersion } from '../../../../../../../config'
import { Page, Panel, Upload } from '../../../../../../../components'
import { BreadcrumbSystem as Breadcrumb, PlatformKey, SysPermission, SCSelectOptions } from '../../../../../../../constants'
import { orgService } from '../../../../../../../services'
import { auth, validator } from '../../../../../../../util'

import 'antd/dist/antd.css'
import './styles.css'

const FormItem = Form.Item
const TextArea = Input.TextArea
const Option = Select.Option

const formItemLayout = {
  labelCol: { sm: 4, md: 4, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const sideBySideFormItemLayout2 = {
  labelCol: { sm: 4, md: 4, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = (orgId, platformId) => ([
  Breadcrumb.HomeBase,
  Breadcrumb.OrgBase,
  Breadcrumb.OrgList,
  { label: 'Edit', link: `/organisations/listing/${orgId}/info` },
  { label: 'Software Specific Details', link: `/organisations/listing/${orgId}/platform-specific/${platformId}` }
])

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

const apiUrl = `${apiVersion}/private/api/portal/sys/files/upload`
const UploadMsgWrongFormat = 'Wrong format of file selected.'

function OrgPlatformDetails({ match }) {
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [item, setItem] = useState({})
  const [isEnableCustomLogo, setEnableCustomLogo] = useState(false)
  const [isEnableBankInfo, setEnableBankInfo] = useState(false)
  const [fileList, setFileList] = useState([])
  const [fileUploadItem, setFileUploadItem] = useState({})
  const [uploadErrorMsg, setUploadErrorMsg] = useState('')

  const [form] = Form.useForm()
  const history = useHistory()
  const { params, path } = match

  const currentId = params ? params.id : null
  const platformId = params ? params.pId : null
  const orgName = item && item.id && item.org ? item.org.name : ''
  const platformName = item && item.id && item.platform ? item.platform.name : ''

  const isPM = item && item.id && parseInt(item.platform_id) === PlatformKey.keyPM
  const isSC = item && item.id && parseInt(item.platform_id) === PlatformKey.keySC

  useEffect(() => {
    if (currentId && platformId && hasAccess(SysPermission.ORGANISATIONS.SFT_DETAILS.READ)) {
      fetchItem()
    }
  }, [])

  async function fetchItem() {
    setIsLoading(true)
    const r = await orgService.getPlatformSpecificDetails(currentId, platformId)

    if (r && r.id) {
      setItem(r)
      setIsLoading(false)
      setEnableBankInfo(r.is_custom_bank_info || false)
      setEnableCustomLogo(r.is_custom_logo || false)
    }
  }

  function onSubmit() {
    form.validateFields()
      .then(async (values) => {
        setIsUpdate(true)

        if (fileUploadItem && fileUploadItem.uid) {
          values.new_file_name = fileUploadItem.fileName
          values.new_file_url = fileUploadItem.fileUrl
          values.new_file_path = fileUploadItem.filePath
        }

        const r = await orgService.updatePlatformSpecificDetails(item.id, item.platform_id, values)

        if (r && r.id) {
          notification.success({
            message: 'Update Details successfully',
            description: 'Details updated successfully.'
          })

          fileClear()
          fetchItem()
        } else {
          notification.error({
            message: 'Details Update not successful',
            description: 'Details are unable to save successfully.'
          })
        }

        setIsUpdate(false)
      })
      .catch(errorInfo => {
        notification.error({
          message: 'Details Update not successful',
          description: 'Details are unable to save successfully.'
        })

        setIsUpdate(false)
      })
  }

  function onSubmitFailed({ values, errorFields, outOfDate }) {
    notification.error({
      message: 'Details Update not successful',
      description: 'Details are unable to save successfully.'
    })

    setIsUpdate(false)
  }

  function validateABN (rule, value, callback) {
    if (value === null || value === undefined || value === '') {
      return Promise.reject('Please enter ABN')
    } else if (!validator.isDigit(value) || value.length !== 11) {
      return Promise.reject('ABN is invalid in format')
    }

    return Promise.resolve()
  }

  const handlePasteABN = (event) => {
    event.preventDefault()
    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('text')

    if (pastedData) {
      const cleanedData = pastedData.replace(/\D+/g, '')
      form.setFieldsValue({ abn: cleanedData })
      form.validateFields(['abn']).catch(console.log)
    }
  }

  function validateBSB (rule, value, callback) {
    if (value !== null && value !== undefined && value !== '' && !validator.isBSB(value)) {
      return Promise.reject('BSB is invalid in format')
    }

    return Promise.resolve()
  }

  const validateBankCode = (rule, value) => {
    if (typeof value === 'string' && value.trim().length > 0) {
      // Regular expression to match three uppercase letters
      const regex = /^[A-Z]{3}$/

      // Check if the input value matches the regex pattern
      if (value && regex.test(value)) {
        return Promise.resolve()
      }

      return Promise.reject('Please enter a valid bank code with 3 uppercase letter only (e.g. ANZ, WBC, CBA)')
    }

    return Promise.resolve()
  }

  const handleBankCodeChange = (e) => {
    let value = e.target.value

    // Limit to maximum 3 characters
    value = value.slice(0, 3)

    // Convert to uppercase
    value = value.toUpperCase()

    // Update the form field only if it matches the required pattern
    if (/^[A-Z]{0,3}$/.test(value)) {
      form.setFieldsValue({ 'bank_code': value })
    }
  }

  const validateDEUserID = (rule, value) => {
    if (typeof value === 'string' && value.trim().length > 0) {
      // Regular expression to match exactly 6 digits
      const regex = /^\d{6}$/

      // Check if the input value exists and matches the regex pattern
      if (value && regex.test(value)) {
        return Promise.resolve()
      }

      return Promise.reject('Please enter a valid DE User ID with 6 digits only')
    }

    return Promise.resolve()
  }

  function validateBankAccNo (rule, value, callback) {
    if (value !== null && value !== undefined && value !== '' && (!validator.isDigit(value) || value.length > 10)) {
      return Promise.reject('Account Number is invalid in format')
    }

    return Promise.resolve()
  }

  function fileRemove(file) {
    const fileIndex = fileList.indexOf(file)
    if (fileIndex > -1) {
      let newFileList = fileList.slice()
      newFileList.shift()
      setFileList(newFileList)
      setFileUploadItem({})
    }
  }

  function fileClear() {
    setFileList([])
    setFileUploadItem({})
  }

  function fileChange(info) {
    if (info && info.file) {
      const f = info.file
      const { percent, response: r = null, status, uid } = f
      if (percent === 100 && r && status && status === 'done') {
        const data = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          name: r.filePath ? r.filePath.originalname : '',
          uid: uid
        }

        setFileList([data])
        setFileUploadItem(data)
        setUploadErrorMsg('')
      }
    }
  }

  function fileSet(file) {
    if (file && (
      file.type === 'image/jpeg' ||
      file.type === 'image/png'
    )) {
      setFileList([file])
      setFileUploadItem(file)
      setUploadErrorMsg('')

      return true
    } else {
      setUploadErrorMsg(UploadMsgWrongFormat)
      return false
    }
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb(currentId, platformId)} />

      <Page.Header title={item.id ? `Organisation - Software Specific Details - ${platformName} (${orgName})` : 'Organisation'}>
        <Space>
          {(isPM || isSC) && hasAccess(SysPermission.ORGANISATIONS.SFT_DETAILS.UPDATE)
            ? <Button shape='round' type='primary' loading={isUpdate} onClick={onSubmit}>{isUpdate ? 'Saving' : 'Save'}</Button>
            : null}
          <div onClick={history.goBack}>
            <Button shape='round' ghost type='primary'>Back</Button>
          </div>
        </Space>
      </Page.Header>

      <Page.ContentLoading isLoading={isLoading} isUpdate={isUpdate}>
        <Form
          form={form}
          name='detail'
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          {isPM || isSC
            ? <div>
              <Panel title={`Entity Info ${platformName ? `for ${platformName}` : ''}`}>
                <Row>
                  <Col lg={24}>
                    <FormItem
                      {...formItemLayout}
                      hasFeedback
                      label='Entity Name'
                      name='entity_name'
                      initialValue={item.entity_name}
                      rules={[
                        { min: 2, message: 'Entity Name must be between 2 and 128 characters' },
                        { max: 128, message: 'Entity Name must be between 2 and 128 characters' },
                        { required: true, message: 'Please enter Entity Name' },
                        { whitespace: true, message: 'Please enter Entity Name' }
                      ]}
                      extra={'The name for internal identifying purpose'}
                    >
                      <Input placeholder={'Enter Entity Name'} />
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={24}>
                    <FormItem
                      {...formItemLayout}
                      hasFeedback
                      label='Trade Name'
                      name='trade_name'
                      initialValue={item.trade_name}
                      rules={[
                        { min: 2, message: 'Trade Name must be between 2 and 128 characters' },
                        { max: 128, message: 'Trade Name must be between 2 and 128 characters' },
                        { required: true, message: 'Please enter Trade Name' },
                        { whitespace: true, message: 'Please enter Trade Name' }
                      ]}
                      extra={'The name for representating the entity, used on most pdf/doc exports'}
                    >
                      <Input placeholder={'Enter Trade Name'} />
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={24}>
                    <FormItem
                      {...formItemLayout}
                      hasFeedback
                      label='Legal Name'
                      name='legal_name'
                      initialValue={item.legal_name}
                      rules={[
                        { min: 2, message: 'Legal Name must be between 2 and 128 characters' },
                        { max: 128, message: 'Legal Name must be between 2 and 128 characters' },
                        { required: true, message: 'Please enter Legal Name' },
                        { whitespace: true, message: 'Please enter Legal Name' }
                      ]}
                      extra={'The legal name under the entity'}
                    >
                      <Input placeholder={'Enter Legal Name'} />
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormItem
                      {...sideBySideFormItemLayout}
                      hasFeedback
                      label='ABN'
                      name='abn'
                      initialValue={item.abn}
                      rules={[
                        { required: true, message: ' ' },
                        { validator: validateABN }
                      ]}
                    >
                      <Input  onPaste={handlePasteABN}/>
                    </FormItem>
                  </Col>
                  <Col lg={12}>
                    <FormItem
                      {...sideBySideFormItemLayout}
                      hasFeedback
                      label='NDIS Number'
                      name='ndis_number'
                      initialValue={item.ndis_number}
                    >
                      <Input />
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={24}>
                    <FormItem
                      {...formItemLayout}
                      label='Address'
                      name='address'
                      initialValue={item.address}
                    >
                      <Input placeholder={'Enter Address'} />
                    </FormItem>
                  </Col>
                  <Col lg={24}>
                    <FormItem
                      {...formItemLayout}
                      label='Unit/Building (optional)'
                      name='unit_building'
                      initialValue={item.unit_building}
                    >
                      <Input />
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormItem
                      {...sideBySideFormItemLayout}
                      hasFeedback
                      label='Trade Phone No.'
                      name='phone_number_1'
                      initialValue={item.phone_number_1}
                      rules={[
                        { required: true, message: 'Please enter trade phone number' },
                        { whitespace: true, message: 'Please enter trade phone number' }
                      ]}
                    >
                      <Input placeholder={'Enter Trade Phone No.'} />
                    </FormItem>
                  </Col>
                  <Col lg={12}>
                    <FormItem
                      {...sideBySideFormItemLayout}
                      hasFeedback
                      label='Trade Phone Name/Addresse'
                      name='phone_name_1'
                      initialValue={item.phone_name_1}
                    >
                      <Input placeholder={'Enter Trade Phone Name/Addresse'} />
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormItem
                      {...sideBySideFormItemLayout}
                      hasFeedback
                      label='Trade Fax No.'
                      name='fax_number_1'
                      initialValue={item.fax_number_1}
                    >
                      <Input placeholder={'Enter Trade Fax No.'} />
                    </FormItem>
                  </Col>
                  <Col lg={12}>
                    <FormItem
                      {...sideBySideFormItemLayout}
                      hasFeedback
                      label='Trade Fax Name/Addresse'
                      name='fax_name_1'
                      initialValue={item.fax_name_1}
                    >
                      <Input placeholder={'Enter Trade Fax Name/Addresse'} />
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormItem
                      {...sideBySideFormItemLayout}
                      hasFeedback
                      label='Trade Email'
                      name='email'
                      initialValue={item.email}
                      rules={[
                        { required: true, message: 'Please enter trade email' },
                        { whitespace: true, message: 'Please enter trade email' }
                      ]}
                    >
                      <Input placeholder={'Enter Trade Email'} />
                    </FormItem>
                  </Col>
                  <Col lg={12}>
                    <FormItem
                      {...sideBySideFormItemLayout}
                      hasFeedback
                      label='Trade Website URL'
                      name='website'
                      initialValue={item.website}
                    >
                      <Input placeholder={'Enter Trade Website'} />
                    </FormItem>
                  </Col>
                </Row>
              </Panel>

              <Panel title={`Software Cap ${platformName ? `for ${platformName}` : ''}`}>
                <Row>
                  <Col lg={12}>
                    <FormItem
                      {...sideBySideFormItemLayout}
                      hasFeedback
                      label='Max Clients'
                      name='client_limit'
                      initialValue={item.client_limit}
                      rules={[
                        { required: true, message: 'Please enter the maximum number of clients allowed' },
                        {
                          validator: async (rule, value) => {
                            if (/\D+/.test(value)) {
                              return Promise.reject('Maximum number of clients can only contain numbers')
                            }

                            return Promise.resolve()
                          }
                        }
                      ]}
                    >
                      <InputNumber min={2} step={10} style={{ width: '100%' }} />
                    </FormItem>
                  </Col>
                </Row>
              </Panel>

              <Panel title={`Bank Info ${platformName ? `for ${platformName}` : ''}`}>
                <Row>
                  <Col lg={24}>
                    <FormItem
                      {...sideBySideFormItemLayout2}
                      label='Overwrite PM Bank Info'
                      name='is_custom_bank_info'
                      initialValue={item.is_custom_bank_info}
                      valuePropName='checked'
                      extra={'If enabled, the bank info for Payment Request/ABA/Statements will use the following settings. Or else the bank info will use the settings in Organisation Details.'}
                    >
                      <Switch
                        defaultChecked={item.is_custom_bank_info || false}
                        onChange={e => setEnableBankInfo(e)}
                        checkedChildren={'Enable'}
                        unCheckedChildren={'Disable'}
                      />
                    </FormItem>
                  </Col>
                </Row>
                {isEnableBankInfo
                  ? <div>
                    <Row>
                      <Col lg={12}>
                        <FormItem
                          {...sideBySideFormItemLayout}
                          hasFeedback
                          label={`Account's Name`}
                          name='bank_acc_name'
                          initialValue={item.bank_acc_name}
                          rules={[
                            { min: 2, message: 'Account\'s Name must be between 2 and 26 characters' },
                            { max: 26, message: 'Account\'s Name must be between 2 and 26 characters' },
                            { required: true, message: 'Please enter Account\'s Name' },
                            { whitespace: true, message: 'Please enter Account\'s Name' }
                          ]}
                        >
                          <Input />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormItem
                          {...sideBySideFormItemLayout}
                          hasFeedback
                          label={`Remittance Name`}
                          name='bank_rmt_name'
                          initialValue={item.bank_rmt_name}
                          rules={[
                            { min: 2, message: 'Remittance Name must be between 2 and 16 characters' },
                            { max: 16, message: 'Remittance Name must be between 2 and 16 characters' },
                            { required: true, message: 'Please enter Remittance Name' },
                            { whitespace: true, message: 'Please enter Remittance Name' }
                          ]}
                        >
                          <Input />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormItem
                          {...sideBySideFormItemLayout}
                          hasFeedback
                          label='BSB'
                          name='bank_bsb'
                          initialValue={item.bank_bsb}
                          rules={[
                            { required: true, message: ' ' },
                            { validator: validateBSB }
                          ]}
                        >
                          <Input />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormItem
                          {...sideBySideFormItemLayout}
                          hasFeedback
                          label='Bank Code'
                          name='bank_code'
                          initialValue={item.bank_code}
                          rules={[
                            { whitespace: true, message: 'Please enter Bank Code' },
                            { validator: validateBankCode }
                          ]}
                        >
                           <Input maxLength='3' onChange={handleBankCodeChange} />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormItem
                          {...sideBySideFormItemLayout}
                          hasFeedback
                          label='DE User ID'
                          name='bank_de_user_id'
                          initialValue={item.bank_de_user_id}
                          rules={[
                            { whitespace: true, message: 'Please enter DE User ID' },
                            { validator: validateDEUserID }
                          ]}
                        >
                          <Input maxLength='6' />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormItem
                          {...sideBySideFormItemLayout}
                          hasFeedback
                          label={`Account Number`}
                          name='bank_acc_no'
                          initialValue={item.bank_acc_no}
                          rules={[
                            { required: true, message: ' ' },
                            { validator: validateBankAccNo }
                          ]}
                        >
                          <Input />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormItem
                          {...sideBySideFormItemLayout}
                          hasFeedback
                          label='Add Self Balancing Line'
                          name='has_self_bal_line'
                          initialValue={item.has_self_bal_line}
                          valuePropName='checked'
                        >
                          <Switch
                            defaultChecked={item.has_self_bal_line || false}
                            checkedChildren='Yes'
                            unCheckedChildren='No'
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </div>
                  : null}
              </Panel>

              <Panel title={`Entity Logo ${platformName ? `for ${platformName}` : ''}`}>
                <Row>
                  <Col lg={24}>
                    <FormItem
                      {...sideBySideFormItemLayout2}
                      label='Overwrite Entity Logo'
                      name='is_custom_logo'
                      initialValue={item.is_custom_logo}
                      valuePropName='checked'
                      extra={'If enabled, the uploaded logo will be used for header on PDF documents and statement, and Software Portal. Or else the logo for documents and Portal will get from the settings in Organisation Account Details.'}
                    >
                      <Switch
                        defaultChecked={item.is_custom_logo || false}
                        onChange={e => setEnableCustomLogo(e)}
                        checkedChildren={'Enable'}
                        unCheckedChildren={'Disable'}
                      />
                    </FormItem>
                  </Col>
                </Row>
                {isEnableCustomLogo
                  ? <Row>
                    {item.logo_url
                      ? <Col lg={12}>
                        Current Logo:
                        <div className='image-center'>
                          <img className='image-logo' src={item.logo_url} alt={item.name} />
                        </div>
                      </Col>
                      : null}
                    <Col lg={12}>
                      {item.logo_url ? 'Upload Logo:' : ''}
                      <div className='image-center'>
                        <Upload
                          apiUrl={apiUrl}
                          loading={isLoading}
                          readOnly={false}
                          showError={!!uploadErrorMsg}
                          options={{
                            method: 'POST',
                            data: { orgId: item.id },
                            name: 'file',
                            file: fileUploadItem,
                            onRemove: fileRemove,
                            onChange: fileChange,
                            beforeUpload: fileSet,
                            fileList: fileList,
                            multiple: false
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  : null}
              </Panel>

              <Panel title={`Email Templates ${platformName ? `for ${platformName}` : ''}`}>
                <Row>
                  <Col lg={24}>
                    <FormItem
                      {...formItemLayout}
                      hasFeedback
                      label='Remittance Email Title'
                      name='rmt_email_title'
                      initialValue={item.rmt_email_title}
                    >
                      <TextArea rows={2} placeholder={'Enter Rmt Email Title'} disabled />
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={24}>
                    <FormItem
                      {...formItemLayout}
                      hasFeedback
                      label='Remittance Email Template'
                      name='rmt_email_template'
                      initialValue={item.rmt_email_template}
                    >
                      <TextArea rows={6} placeholder={'Enter Rmt Email Template'} disabled />
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={24}>
                    <FormItem
                      {...formItemLayout}
                      hasFeedback
                      label='Statement Email Title'
                      name='statement_email_title'
                      initialValue={item.statement_email_title}
                    >
                      <TextArea rows={2} placeholder={'Enter Statement Email Title'} disabled />
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={24}>
                    <FormItem
                      {...formItemLayout}
                      hasFeedback
                      label='Statement Email Template'
                      name='statement_email_template'
                      initialValue={item.statement_email_template}
                    >
                      <TextArea rows={2} placeholder={'Enter Statement Email Template'} disabled />
                    </FormItem>
                  </Col>
                </Row>
              </Panel>

              {isSC
                ? <Panel title='Details for SC'>
                  <Row>
                    <Col lg={12}>
                      <FormItem
                        {...formItemLayout}
                        label='Funder Mode'
                        name='sc_multi_funder_settings'
                        initialValue={item.sc_multi_funder_settings}
                        rules={[
                          { required: true, message: 'Please select Funder Settings' },
                        ]}
                      >
                        <Select>
                          {SCSelectOptions.SC_FUNDER_TYPE.map(e => (
                            <Option key={`scfmcs-${e.value}`} value={e.value}>
                              <div>{e.name}</div>
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </Col>
                  </Row>
                </Panel>
                : null}
            </div>
            : <div>No details setting for selected software.</div>}
        </Form>
      </Page.ContentLoading>
    </Page.Body>
  )
}

export default OrgPlatformDetails
