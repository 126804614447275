import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Table, Pagination, Row, Col, Space } from 'antd'
import { CheckCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Loading, Page, Panel, Total } from '../../../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../../../constants'
import { platformModuleService } from '../../../../../../../services'
import { auth, validator } from '../../../../../../../util'
import { SysStore } from '../../../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20

const columns = (platformId) => ([
  {
    title: 'Module Name',
    dataIndex: 'name',
    key: 'name',
    width: 8
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 11
  },
  {
    width: 3,
    render: (item) => <Space size='middle'>
      { hasAccess(SysPermission.SOFTWARE.MODULE_PMS.READ)
        ? <Link to={`/softwares/listing/${platformId}/modules/${item.id}`} key={`modulekey${item.id}`}>
          <FormOutlined />
        </Link>
        : null }

    </Space>
  },
])

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function SoftwareModuleList (props) {
  const platformId = props.platformId
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState({ platform_id: props.platformId })
  const [isListLoading, setIsListLoading] = useState(false)
  const [sort, setSort] = useState({})

  useEffect(() => {
    fetchList({ currentPage: 1 })
  }, [])

  async function fetchList ({ currentPage = page }) {
    if (!hasAccess(SysPermission.SOFTWARE.MODULE_PMS.LIST)) return

    setIsLoading(true)
    const r = await platformModuleService.listByPage(currentPage, pageSize, filter, sort)
    if (r && r.list) {
      setIsLoading(false)
      setIsLoading(false)
      setList(r.list)
      setPage(currentPage)
      setTotal(r.total)
    }
  }

  return (
    <div>
      <Page.Header title='Modules & Permissions'>
        { hasAccess(SysPermission.SOFTWARE.MODULE_PMS.CREATE)
          ? <Link to={`/softwares/listing/${platformId}/modules/add`}>
            <Button shape='round' type='primary'>Add Module</Button>
          </Link>
          : null }

      </Page.Header>

      <Page.Content>
        <Table
          loading={isLoading}
          columns={columns(platformId)}
          dataSource={list}
          size='small'
          rowKey={(itm) => (`ptpms${itm.id}`)}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total,
            onChange: (e) => fetchList({currentPage: e})
          }}
        />
        <Total total={total} title={'module'} />
      </Page.Content>
    </div>
  )
}

export default SoftwareModuleList
