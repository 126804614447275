import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Alert, Button, Checkbox, Form, Icon, Input, notification, Pagination, Row, Col, Space, Skeleton, Switch, Spin, Table } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { Page, Panel, Total } from '../../components'
import { authService, loginService } from '../../services'
import { auth, formatter, localStorage, sessionStorage, validator } from '../../util'
import { AuthStore } from '../../stores'
import { build } from '../../config'

import 'antd/dist/antd.css'
import './styles.css'

const FormItem = Form.Item
const FormList = Form.List
const TextArea = Input.TextArea

const pageSize = 20
const ERROR_INVALID_LOGIN = 'Invalid Login.'
const ERROR_UNABLE_LOGIN = 'Unable to login. Please try again later.'
const ERROR_LOGOUT_SUCCESSFULLY = 'You have logged out successfully.'

const isEnterKey = (e) => {
  return e && e.key === 'Enter'
}

const isMouseClick = (e) => {
  return e && e.key === undefined && e.currentTarget && e.currentTarget.tagName.toLowerCase() === 'button'
}

function Login ({match, location}) {
  const [isUpdate, setIsUpdate] = useState(false)
  const [isResetPassword, setIsResetPassword] = useState(false)
  const [isTokenExpired, setIsTokenExpired] = useState(false)
  const [isTokenRevoked, setIsTokenRevoked] = useState(false)
  const [isUnauthorised, setIsUnauthorised] = useState(false)
  const [alert, setAlert] = useState({
    message: 'Incorrect username and/or password.',
    show: false,
    type: 'error'
  })
  const [item, setItem] = useState({})

  const [form] = Form.useForm()
  const history = useHistory()

  useEffect(() => {
    const redirected = localStorage.getItem('redirected') === 'true'

    if (!redirected) {
      const rememberMe = localStorage.getItem('rememberMe') === 'true'
      const tokenExpired = sessionStorage.getItem('tokenExpired') === 'true'
      const tokenRevoked = sessionStorage.getItem('tokenRevoked') === 'true'
      const unauthorized = sessionStorage.getItem('unauthorized') === 'true'

      if (tokenExpired || tokenRevoked || unauthorized || !rememberMe) {
        localStorage.clear()
        loginService.logout()
      }

      if (!(tokenExpired || tokenRevoked || unauthorized)) {
        sessionStorage.clear()
      } else {
        setIsTokenExpired(tokenExpired)
        setIsTokenRevoked(tokenRevoked)
        setIsUnauthorised(unauthorized)
      }

      localStorage.setItem('redirected', true)
    } else {
      setAlert({ ...alert, message: ERROR_LOGOUT_SUCCESSFULLY, show: true })

      setTimeout(() => {
        localStorage.setItem('redirected', false)
        setAlert({ ...alert, message: '', show: false })
      }, 7000)
    }
  }, [])

  async function onSubmit (e) {
    if (isEnterKey(e) || isMouseClick(e)) {
      sessionStorage.removeItem('tokenExpired')
      sessionStorage.removeItem('tokenRevoked')
      sessionStorage.removeItem('unauthorized')
      setAlert({ ...alert, show: false })
      setIsTokenExpired(false)
      setIsTokenRevoked(false)
      setIsUnauthorised(false)

      form.validateFields()
        .then(async (values) => {
          if (isUpdate) return

          setIsUpdate(true)

          const { username, password, new_password, remember_me } = values
          try {
            const r = await loginService.login({ username, password, new_password, remember_me })
            const { permissions = [], reset_password: rspw, token, user, platform = [], invalid = false, active_id } = r

            if (rspw) {
              setIsResetPassword(true)
            } else if (!invalid) {
              localStorage.setItem('redirected', false)
              localStorage.setItem('rememberMe', remember_me || false)
              auth.setCurrentToken(token)
              auth.setMode(active_id)
              auth.setPermission(permissions)
              auth.setCurrentUser(user)
              auth.setPlatform(platform)
              sessionStorage.removeItem('tokenExpired')
              sessionStorage.removeItem('tokenRevoked')
              sessionStorage.removeItem('unauthorized')
              history.replace('/')
            } else {
              let message = ''
              if (validator.isNotEmptyArray(r.errors)) {
                message = formatter.toErrorMessage(r.errors)
              } else {
                message = ERROR_INVALID_LOGIN
              }
              setAlert({ ...alert, message, show: true })
            }
          } catch (e) {
            const { response } = e
            let message = ''

            if (response) {
              const errorMsg = formatter.toErrorMessage(response)
              message = errorMsg || ERROR_UNABLE_LOGIN
            } else {
              message = ERROR_UNABLE_LOGIN
            }

            setAlert({ ...alert, message, show: true })
          }

          setIsUpdate(false)
        })
        .catch(errorInfo => {
          setIsUpdate(false)
        })
    }
  }

  async function onSubmitFailed ({ values, errorFields, outOfDate }) {
    notification.error({
      message: 'Save Module not successful',
      description: 'Module is unable to save successfully.'
    })

    setIsUpdate(false)
  }

  function hideAlert () {
    setAlert({ ...alert, show: false })
  }

  async function validateNewPassword (rule, value, callback) {
    if (value === null || value === undefined || value === '') {
      throw new Error('Please enter new password')
    } else if (validator.checkPassword(value)) {
      throw new Error('Please enter at least 1 number, 1 lowercase letter and 1 uppercase letter')
    } else if (value.length < 8) {
      throw new Error('Please enter at least 8 characters')
    }
  }

  async function validateConfirmPassword (rule, value, callback) {
    const newPassword = form.getFieldValue('new_password')
    if (value === null || value === undefined || value === '') {
      throw new Error('Please enter confirm password')
    } else if (newPassword !== value) {
      throw new Error('Your confirm password does not match new password')
    }
  }

  return (
    <div className='login'>
      <div className='login-box'>
        <Row>
          <Col xs={0} sm={0} lg={14}>
            <div className='login-left-side'>
              <img alt='' src={process.env.PUBLIC_URL + '/img/login-default.jpg'} className='login-pic' />
            </div>
          </Col>

          <Col sm={24} lg={10} className='box'>
            <div className='row-center'>
              <img className='logo' alt='Pumpkin IT' src={process.env.PUBLIC_URL + '/img/pumpkinIT-trans.png'} />
              <div>
                <div className='title'>Pumpkin Portal</div>
                <div style={{ fontSize: '11pt', color: '#e84e1c77', fontWeight: 'bold', textAlign: 'right', marginTop: '-8px', marginBottom: '-22px' }}>{build === 'prod' ? '' : build || 'unknown'}</div>
              </div>
            </div>
            <div className='center'>
              {isTokenExpired ? (
                <Alert
                  style={{width: '100%'}}
                  className='alert'
                  message='Your session has expired. Please sign in again.'
                  type='error'
                  showIcon
                />
              ) : null}

              {isTokenRevoked ? (
                <Alert
                  style={{width: '100%'}}
                  className='alert'
                  message='Your session is invalid/expired. Please sign in again.'
                  type='error'
                  showIcon
                />
              ) : null}

              {isUnauthorised ? (
                <Alert
                  style={{width: '100%'}}
                  className='alert'
                  message='You must sign in to use this application.'
                  type='error'
                  showIcon
                />
              ) : null}

              {alert.show ? (
                <Alert
                  style={{width: '100%'}}
                  className='alert'
                  message={alert.message}
                  type={alert.type}
                  showIcon
                />
              ) : null}

              <Form
                form={form}
                layout='vertical'
                name='login'
                onFinish={onSubmit}
                onFinishFailed={onSubmitFailed}
              >
                <div style={isResetPassword ? { display: 'none' } : {}}>
                  <FormItem
                    className='input-text'
                    hasFeedback
                    label=''
                    name='username'
                    rules={[
                      { required: true, message: 'Please enter your username' }
                    ]}
                  >
                    <Input
                      disabled={isUpdate}
                      onKeyPress={onSubmit}
                      prefix={<UserOutlined style={{ marginRight: '15px' }} />}
                      placeholder='Username (Email or Code)'
                    />
                  </FormItem>

                  <FormItem
                    className='input-text'
                    hasFeedback
                    label=''
                    name='password'
                    rules={[
                      { required: true, message: 'Please enter your password' }
                    ]}
                  >
                    <Input.Password
                      disabled={isUpdate}
                      onKeyPress={onSubmit}
                      prefix={<LockOutlined style={{ marginRight: '15px' }} />}
                      placeholder='Password'
                    />
                  </FormItem>

                  <FormItem
                    className='input-text'
                    style={{paddingLeft: '10px'}}
                    label=''
                    name='remember_me'
                    valuePropName='checked'
                  >
                    <Checkbox disabled={isUpdate}>Remember Me</Checkbox>
                  </FormItem>
                </div>

                {isResetPassword
                  ? <div>
                    <FormItem
                      className='input-text'
                      hasFeedback
                      label=''
                      name='new_password'
                      rules={[
                        { required: true, message: ' ' },
                        { validator: validateNewPassword }
                      ]}
                    >
                      <Input
                        disabled={isUpdate}
                        prefix={<LockOutlined style={{ marginRight: '15px' }} />}
                        placeholder='New Password'
                      />
                    </FormItem>
                    <FormItem
                      className='input-text'
                      hasFeedback
                      label=''
                      name='confirm_password'
                      rules={[
                        { required: true, message: ' ' },
                        { validator: validateConfirmPassword }
                      ]}
                    >
                      <Input
                        disabled={isUpdate}
                        prefix={<LockOutlined style={{ marginRight: '15px' }} />}
                        placeholder='Confirm Password'
                      />
                    </FormItem>

                    <div className='center'>
                      <Button
                        className='button'
                        shape='round'
                        type='primary'
                        loading={isUpdate}
                        onClick={onSubmit}
                      >
                        {isUpdate ? 'Update and Logging in...' : 'Confirm'}
                      </Button>
                    </div>
                  </div>
                  : <div className='center'>
                    <Button
                      className='button'
                      shape='round'
                      type='primary'
                      loading={isUpdate}
                      onClick={onSubmit}
                    >
                      {isUpdate ? 'Logging in...' : 'Login'}
                    </Button>

                    {!isUpdate
                      ? <div className='center' style={{ marginTop: '20px' }}>
                        <Link to='/forgot-password' onClick={hideAlert}>Forgot password</Link>
                      </div>
                      : null}
                  </div>}
              </Form>
            </div>
          </Col>
        </Row>
      </div>
      <div className='copyright'>
        <div className='footer'>
          Powered by <img src='/img/pumpkinIT-long.png' className='app-logo' /> Copyright © 2021 Pumpkin IT. All rights reserved.
        </div>
      </div>
    </div>
  )
}

export default Login
