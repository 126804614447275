import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Modal, Table, notification, Pagination, Row, Col, Select, Spin, Space } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Loading, Page, Panel, Total } from '../../../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../../../constants'
import { orgService } from '../../../../../../../services'
import { auth, validator } from '../../../../../../../util'

import 'antd/dist/antd.css'
import './styles.css'

const pageSize = 20
const Option = Select.Option
const FormItem = Form.Item

const formItemLayout = {
  labelCol: { sm: 4, md: 4, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const columns = (orgId) => ([
  {
    title: 'Software',
    dataIndex: 'platform_name',
    key: 'platform_name',
    width: 6
  },
  {
    title: 'Entity Name',
    dataIndex: 'entity_name',
    key: 'entity_name',
    width: 6
  },
  {
    title: 'Trade Name',
    dataIndex: 'trade_name',
    key: 'trade_name',
    width: 6
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    width: 1,
    render: (active) => active
      ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
      : <CloseCircleOutlined style={{color: '#ccc', fontSize: '14pt'}} />
  },
  {
    width: 1,
    render: (item) => <Space size='middle'>
      { hasAccess(SysPermission.ORGANISATIONS.SFT_DETAILS.READ)
        ? <Link to={`/organisations/listing/${orgId}/platform-specific/${item.platform_id}`} key={`orgps${item.id}`}>
          <FormOutlined />
        </Link>
        : null }
    </Space>
  },
])

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function OrgPlatformSpecificList (props) {
  const orgId = props.orgId
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState({ org_id: orgId })
  const [isListLoading, setIsListLoading] = useState(false)
  const [sort, setSort] = useState({})
  const [platformList, setPlatformList] = useState([])

  const [form] = Form.useForm()

  useEffect(() => {
    fetchList({ currentPage: 1 })
    fetchPlatformList()
  }, [])

  async function fetchList ({ currentPage = page }) {
    if (!hasAccess(SysPermission.ORGANISATIONS.SFT_MGMT.LIST)) return

    setIsLoading(true)
    const r = await orgService.listPlatformByPage(currentPage, pageSize, filter, sort)

    if (r && r.list) {
      setIsLoading(false)
      setList(r.list)
      setPage(currentPage)
      setTotal(r.total)
    }
  }

  async function fetchPlatformList () {
    const r = await orgService.listUnsignedPlatform(orgId)
    if (validator.isNotEmptyArray(r)) {
      setPlatformList(r)
    }
  }

  async function addSoftware () {
    form.validateFields()
      .then(async (values) => {
        setIsUpdate(true)

        const r = await orgService.addPlatform(orgId, values)
        if (r && r.id) {
          notification.success({
            message: 'Add Software successful',
            description: 'Software is added successfully.'
          })

          setShowModal(false)
          fetchList({ currentPage: 1 })
        } else {
          notification.error({
            message: 'Add Software not successful',
            description: 'Software is unable to add successfully.'
          })
        }

        setIsUpdate(false)
      })
      .catch(errorInfo => {
        notification.error({
          message: 'Add Software not successful',
          description: 'Software is unable to add successfully.'
        })

        setIsUpdate(false)
      })
  }

  async function addSoftwareFailed ({ values, errorFields, outOfDate }) {
    notification.error({
      message: 'Add Software not successful',
      description: 'Software is unable to add successfully.'
    })

    setIsUpdate(false)
  }

  return (
    <div>
      <Page.Header title='Software Specific Details'>
        { hasAccess(SysPermission.ORGANISATIONS.SFT_MGMT.CREATE)
          ? <Button shape='round' type='primary' onClick={() => setShowModal(true)}>Add Software</Button>
          : null }
      </Page.Header>

      <Page.Content>
        <Table
          loading={isLoading}
          columns={columns(orgId)}
          dataSource={list}
          size='small'
          rowKey={(itm) => (`pltdet${itm.id}`)}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total,
            onChange: (e) => fetchList({currentPage: e})
          }}
        />
        <Total total={total} title={'software'} suffix='' />
      </Page.Content>

      <Modal
        width={'60%'}
        title={<div className='modal-title'>Add Software User from Existing List</div>}
        visible={showModal}
        onOk={isUpdate ? null : addSoftware}
        onCancel={isUpdate ? null : () => setShowModal(false)}
        footer={[
          <Button key='back' shape='round' onClick={isUpdate ? null : () => setShowModal(false)}>
            Cancel
          </Button>,
          validator.isNotEmptyArray(platformList)
            ? <Button key='submit' shape='round' type='primary' loading={isUpdate} onClick={isUpdate ? null : addSoftware}>
              Confirm
            </Button>
            : undefined
        ]}
      >
        <Spin size='large' spinning={isUpdate}>
          <Form
            form={form}
            name='addSoftware'
            onFinish={addSoftware}
            onFinishFailed={addSoftwareFailed}

          >
            <FormItem
              {...formItemLayout}
              hasFeedback
              label='Software'
              name='platform_id'
              initialValue={undefined}
              rules={[
                { required: true, message: 'Please select software' }
              ]}
            >
              <Select>
                { platformList.map(e => (
                  <Option key={`orgpms${e.id}`} value={e.id}>
                    <div>
                      <div>{ e.name }</div>
                    </div>
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
}

export default OrgPlatformSpecificList
