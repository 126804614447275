import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Input, notification, Pagination, Row, Col, Space, Skeleton, Switch, Spin, Table } from 'antd'
import { CheckCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Page, Panel, Total } from '../../../../../../../components'
import { BreadcrumbSystem as Breadcrumb, SysPermission } from '../../../../../../../constants'
import { platformModuleService } from '../../../../../../../services'
import { auth, validator } from '../../../../../../../util'

import 'antd/dist/antd.css'
import './styles.css'

const FormItem = Form.Item
const FormList = Form.List
const TextArea = Input.TextArea

const pageSize = 20

/** Rule: Must define breadcrumb in every page **/
const breadcrumb = [
  Breadcrumb.HomeBase,
  Breadcrumb.SoftwareList,
  Breadcrumb.SoftwareEdit,
  Breadcrumb.SoftwareModuleEdit
]

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function SoftwareModuleEdit ({match}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [item, setItem] = useState({})

  const [form] = Form.useForm()
  const history = useHistory()
  const { params } = match
  const currentId = params ? params.mId : ''
  const platformId = params ? params.id : ''
  const isEdit = params && currentId !== 'add' && params.currentId !== 'new'

  useEffect(() => {
    if (isEdit && hasAccess(SysPermission.SOFTWARE.MODULE_PMS.READ)) {
      fetchItem()
    }
  }, [])

  async function fetchItem () {
    setIsLoading(true)
    const r = await platformModuleService.get(currentId)
    if (r && r.id) {
      setItem(r)
      setIsLoading(false)
    }
  }

  async function onSubmit () {
    form.validateFields()
      .then(async (values) => {
        setIsUpdate(true)
        let r = null

        if (isEdit) {
          let list = []
          if (validator.isNotEmptyArray(item.permissions)) {
            for (let i = 0; i < item.permissions.length; i++) {
              const p = item.permissions[i]
              list.push({
                id: p.id,
                name: values[`name${i}`],
                description: values[`description${i}`],
                active: values[`active${i}`] || false
              })
            }
          }

          let body = {
            permissions: list,
            name: values.name,
            description: values.description,
            active: values.active || false
          }
          r = await platformModuleService.save(currentId, body)
        } else {
          values.platform_id = platformId
          r = await platformModuleService.add(values)
        }

        if (r && r.id) {
          if (isEdit) {
            notification.success({
              message: 'Update Module successfully',
              description: 'Module is updated successfully.'
            })
          } else {
            notification.success({
              message: 'Create Module successfully',
              description: 'Module is created successfully.'
            })

            setTimeout(() => {
              const link = `/softwares/listing/${platformId}/modules/${r.id}`
              history.replace(link)
              window.location.replace(link)
            }, 1000)
          }
        } else {
          notification.error({
            message: 'Save Module not successful',
            description: 'Module is unable to save successfully.'
          })
        }

        setIsUpdate(false)
      })
      .catch(errorInfo => {
        notification.error({
          message: 'Save Module not successful',
          description: 'Module is unable to save successfully.'
        })

        setIsUpdate(false)
      })
  }

  async function onSubmitFailed ({ values, errorFields, outOfDate }) {
    notification.error({
      message: 'Save Module not successful',
      description: 'Module is unable to save successfully.'
    })

    setIsUpdate(false)
  }

  return (
    <Page.Body>
      <Page.Breadcrumb item={breadcrumb} />

      <Page.Header title={item.id ? `Edit Module & Permissions - ${item.name}` : currentId && isEdit ? 'Edit Modules & Permissions' : `Add Module`}>
        <Space>
          { ((isEdit && hasAccess(SysPermission.SOFTWARE.MODULE_PMS.UPDATE)) || (!isEdit && hasAccess(SysPermission.SOFTWARE.MODULE_PMS.CREATE)))
            ? <Button shape='round' type='primary' loading={isUpdate} onClick={onSubmit}>{ isUpdate ? 'Saving' : 'Save'}</Button>
            : null }
          <div onClick={history.goBack}>
            <Button shape='round' ghost type='primary'>Back</Button>
          </div>
        </Space>
      </Page.Header>

      <Page.ContentLoading isLoading={isLoading} isUpdate={isUpdate}>
        <Form
          form={form}
          layout={'vertical'}
          name='detail'
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Panel title='Details'>
            <Row gutter={12}>
              <Col lg={8}>
                <FormItem
                  hasFeedback
                  label='Name'
                  name='name'
                  initialValue={item.name}
                  rules={[
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter name' },
                    { whitespace: true, message: 'Please enter module name' }
                  ]}
                >
                  <Input placeholder={'Enter Module Name'} />
                </FormItem>
              </Col>
              <Col lg={14}>
                <FormItem
                  hasFeedback
                  label='Description'
                  name='description'
                  initialValue={item.description}
                  rules={[
                    { min: 2, message: 'Description must be at least 2 characters' },
                    { required: true, message: 'Please enter module description' },
                    { whitespace: true, message: 'Please enter module description' }
                  ]}
                >
                  <TextArea rows={1} autoSize placeholder={'Enter Module Description'} />
                </FormItem>
              </Col>
              <Col lg={2}>
                <FormItem
                  label='Active'
                  name='active'
                  initialValue={item.active}
                  valuePropName='checked'
                >
                  <Switch
                    defaultChecked={item.active}
                    checkedChildren={'Enable'}
                    unCheckedChildren={'Disable'}
                  />
                </FormItem>
              </Col>
            </Row>
          </Panel>

          { validator.isNotEmptyArray(item.permissions)
            ? <Panel title='Permissions'>
              { item.permissions.map((itm, index) => {
                return (
                  <Row gutter={12} key={`pms${itm.id}`}>
                    <Col lg={5}>
                      <FormItem
                        hasFeedback
                        label='Name'
                        name={`name${index}`}
                        initialValue={itm.name}
                        rules={[
                          { min: 2, message: 'Name must be between 2 and 128 characters' },
                          { max: 128, message: 'Name must be between 2 and 128 characters' },
                          { required: true, message: 'Please enter name' },
                          { whitespace: true, message: 'Please enter module name' }
                        ]}
                      >
                        <Input placeholder={'Enter Module Name'} />
                      </FormItem>
                    </Col>
                    <Col lg={9}>
                      <FormItem
                        hasFeedback
                        label='Description'
                        name={`description${index}`}
                        initialValue={itm.description}
                        rules={[
                          { min: 2, message: 'Description must be at least 2 characters' },
                          { required: true, message: 'Please enter module description' },
                          { whitespace: true, message: 'Please enter module description' }
                        ]}
                      >
                        <TextArea rows={1} autoSize placeholder={'Enter Module Description'} />
                      </FormItem>
                    </Col>
                    <Col lg={2}>
                      <FormItem
                        label='Active'
                        name={`active${index}`}
                        initialValue={itm.active}
                        valuePropName='checked'
                      >
                        <Switch
                          defaultChecked={itm.active}
                          checkedChildren={'Enable'}
                          unCheckedChildren={'Disable'}
                        />
                      </FormItem>
                    </Col>
                  </Row>
                )
              })}
            </Panel>
            : null }
        </Form>
      </Page.ContentLoading>
    </Page.Body>
  )
}

export default SoftwareModuleEdit
