import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Modal, notification, Table, Pagination, Row, Col, Select, Spin, Space } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { Loading, Page, Panel, Total } from '../../../../../../../components'
import { BreadcrumbSystem as Breadcrumb, MemberType, PlatformKey, OrgPermission } from '../../../../../../../constants'
import { memberService, platformAdminRoleService } from '../../../../../../../services'
import { auth, localStorage, validator } from '../../../../../../../util'
import { MemberStore } from '../../../../../../../stores'

import 'antd/dist/antd.css'
import './styles.css'

const Option = Select.Option
const FormItem = Form.Item
const pageSize = 20

const formItemLayout = {
  labelCol: { sm: 4, md: 4, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const columns = (platformId) => ([
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 6
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    width: 4
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 5
  },
  platformId === PlatformKey.keyPM
    ? {
      title: 'Role',
      dataIndex: 'org_pm_role_name',
      key: 'org_pm_role_name',
      width: 5
    }
    : platformId === PlatformKey.keySC
      ? {
        title: 'Role',
        dataIndex: 'org_sc_role_name',
        key: 'org_sc_role_name',
        width: 5
      }
      : { width: 0 },
  platformId === PlatformKey.keyPM
    ? {
      title: 'Active',
      dataIndex: 'org_pm_active',
      key: 'org_pm_active',
      width: 1,
      render: (active) => active
        ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
        : <CloseCircleOutlined style={{color: '#ccc', fontSize: '14pt'}} />
    }
    : platformId === PlatformKey.keySC
      ? {
        title: 'Active',
        dataIndex: 'org_sc_active',
        key: 'org_sc_active',
        width: 1,
        render: (active) => active
          ? <CheckCircleOutlined style={{color: '#4fbc85', fontSize: '14pt'}} />
          : <CloseCircleOutlined style={{color: '#ccc', fontSize: '14pt'}} />
      }
      : { width: 0 } ,
  {
    width: 1,
    render: (item) => <Space size='middle'>
      { hasAccess(OrgPermission.SOFTWARE.ADMIN_USER.READ)
        ? <Link to={`/software/detail/${platformId}/admin-users/${item.id}`} key={`memberkey${item.id}`}>
          <FormOutlined />
        </Link>
        : null }
    </Space>
  },
])

const hasAccess = (permission) => {
  return auth.hasAccess(permission)
}

function PlatformMemberList (props) {
  const platformId = parseInt(props.platformId)
  const filterCondition = platformId === PlatformKey.keyPM
    ? { org_pm_platform_id: platformId, org_pm_access_id: { condition: 'is not null' } }
    : platformId === PlatformKey.keySC
      ? { org_sc_platform_id: platformId, org_sc_access_id: { condition: 'is not null' } }
      : {}
  const [isLoading, setIsLoading] = useState(false)
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState(filterCondition)
  const [isMemberListLoading, setIsMemberListLoading] = useState(false)
  const [sort, setSort] = useState({ org_pm_name: 1, org_sc_name: 1, name: 1 })

  const [exMemberList, setExMemberList] = useState([])
  const [portalAdminOrgRoleList, setAdminOrgRoleList] = useState([])
  const [isUpdate, setIsUpdate] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    fetchList({ currentPage: 1 })
    fetchExMemberList()
    fetchUserRole()

    /** TODO: tab change url */
    // if (params.type) {
    //   const tab = TabList.find(e => e.path === params.type || e.path === `/${params.type}`)
    //   const cTab = tab && tab.tabId ? String(tab.tabId) : '1'
    //   setCurrentTab(cTab)
    // } else {
    //   history.replace(`/member/listing/${currentId}${TabList[0].path}`)
    // }
  }, [])

  async function fetchList ({ currentPage = page }) {
    if (!hasAccess(OrgPermission.SOFTWARE.ADMIN_USER.LIST)) return

    let showMode = auth.getMode()
    filter.org_pm_type_id = showMode

    setIsLoading(true)
    const r = await memberService.orglistByPage(currentPage, pageSize, filter, sort)
    if (r && r.list) {
      setIsLoading(false)
      setList(r.list)
      setPage(currentPage)
      setTotal(r.total)
    }
  }

  async function fetchExMemberList () {
    const r = await memberService.orgGetExMembers(MemberType.Platform, platformId)
    if (validator.isNotEmptyArray(r)) {
      setExMemberList(r)
    }
  }

  async function fetchUserRole () {
    let showMode = auth.getMode()
    const filter1 = { active: true, org_id: [0, showMode], platform_id: platformId }

    const portalAdminRole = await platformAdminRoleService.orgListByPage(1, 0, filter1)

    if (portalAdminRole && validator.isNotEmptyArray(portalAdminRole.list)) {
      const list = portalAdminRole.list.filter(e => e.org_id === 0 || e.org_id === parseInt(showMode))
      setAdminOrgRoleList(list)
    }
  }

  async function addUser () {
    form.validateFields()
      .then(async (values) => {
        setIsUpdate(true)

        values.platform_id = platformId
        values.type = MemberType.Platform

        const r = await memberService.orgAddExMembers(values)
        if (r && r.id) {
          notification.success({
            message: 'Add Existing Software User successful',
            description: 'Software User is added successfully.'
          })

          setShowModal(false)
          fetchList({ currentPage: 1 })
          fetchExMemberList()
        } else {
          notification.error({
            message: 'Add Existing Software User not successful',
            description: 'Software User is unable to add successfully.'
          })
        }

        setIsUpdate(false)
      })
      .catch(errorInfo => {
        notification.error({
          message: 'Add Existing Software User not successful',
          description: 'Software User is unable to add successfully.'
        })

        setIsUpdate(false)
      })
  }

  async function addUserFailed ({ values, errorFields, outOfDate }) {
    notification.error({
      message: 'Save Admin User not successful',
      description: 'Admin User is unable to save successfully.'
    })

    setIsUpdate(false)
  }

  return (
    <div>
      <Page.Header title='Software Users'>
        { hasAccess(OrgPermission.SOFTWARE.ADMIN_USER.UPDATE)
          ? <Button style={{marginRight: '15px'}} onClick={() => setShowModal(true)} shape='round' type='primary'>Add Existing User</Button>
          : null }
        { hasAccess(OrgPermission.SOFTWARE.ADMIN_USER.CREATE)
          ? <Link to={`/software/detail/${platformId}/admin-users/add`}>
            <Button shape='round' type='primary'>Add New User</Button>
          </Link>
          : null }

      </Page.Header>

      <Page.Content>
        <Table
          loading={isLoading}
          columns={columns(platformId)}
          dataSource={list}
          size='small'
          rowKey={(itm) => (`memitm${itm.id}`)}
          pagination={{
            hideOnSinglePage: true,
            pageSize,
            total,
            onChange: (e) => fetchList({currentPage: e})
          }}
        />
        <Total total={total} title={'user'} />

        <Modal
          width={'60%'}
          title={<div className='modal-title'>Add Software User from Existing List</div>}
          visible={showModal}
          onOk={isUpdate ? null : addUser}
          onCancel={isUpdate ? null : () => setShowModal(false)}
          footer={[
            <Button key='back' shape='round' onClick={isUpdate ? null : () => setShowModal(false)}>
              Cancel
            </Button>,
            <Button key='submit' shape='round' type='primary' loading={isUpdate} onClick={isUpdate ? null : addUser}>
              Confirm
            </Button>
          ]}
        >
          <Spin size='large' spinning={isUpdate}>
            <Form
              form={form}
              name='addUser'
              onFinish={addUser}
              onFinishFailed={addUserFailed}

            >
              <FormItem
                {...formItemLayout}
                hasFeedback
                label='User'
                name='id'
                initialValue={undefined}
                rules={[
                  { required: true, message: 'Please select user' }
                ]}
              >
                <Select>
                  { exMemberList.map(e => (
                    <Option key={`orgexm${e.id}`} value={e.id}>
                      <div>
                        <div>{ e.name }</div>
                      </div>
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                {...formItemLayout}
                hasFeedback
                label='Role'
                name='role_id'
                initialValue={undefined}
                rules={[
                  { required: true, message: 'Please select role' }
                ]}
              >
                <Select>
                  { portalAdminOrgRoleList.map(e => (
                    <Option key={`orglsr${e.id}`} value={e.id} disabled={parseInt(e.org_id) === 0}>
                      <div>
                        <div>{ e.name }</div>
                        <div className='sub-option'>{ e.description }</div>
                      </div>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Form>
          </Spin>
        </Modal>
      </Page.Content>
    </div>
  )
}

export default PlatformMemberList
